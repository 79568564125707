import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA4 from 'react-ga4'

const PageViewTracker = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA4.send('pageview', location.pathname)
  }, [location])
}

export default PageViewTracker
