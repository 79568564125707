import { Routes, Route, Navigate } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import LoginPage from '../Pages/Login'
import LoginOtp from '../Pages/LoginOtp'
import ResetPassword from '../Pages/ResetPassword'
import PostLoginContainer from '../Pages/PostLoginContainer'
import Home from '../Pages/Home'
import SignUp from '../Pages/SignUp'
import ActivateAccountOTP from '../Pages/ActivateAccountOtp'
import LoginWithPassword from '../Pages/LoginWithPassword'
import LoginContainer from '../Pages/LoginContainer'
import LoginWithOTP from '../Pages/LoginWithOTP'
import TermsAndConditions from '../Pages/ipo/TermsAndConditions'
import LoadingSkeleton from '../Components/LoadingSkeleton'
import { EMandate } from '../Pages/EMandate'
import { ThirdPartyAddFunds } from '../Pages/tpfunds/ThirdPartyFunds'
// import { TaxFiling } from '../Pages/Tax/TaxFiling'
import RetirementCalculator from '../Pages/Calculator/RetirementCalculatorNew'
import ErrorBoundary from '../Components/ErrorBoundary'
import { DematUser, PrivateRoute } from './PrivateRoute'
import MyPlan from '../Pages/MyPlan'
import { SSEProvider } from '../Provider/SSEProvider'

export default function RoutesConfig() {
  const FirstIdeasSection = lazy(() => import('../Pages/FirstIdeasSection'))
  const Plans = lazy(() => import('../Pages/Plans'))
  const ChildPlan = lazy(() => import('../Pages/Calculator/EducationCalculator'))
  // const RetirementPlan = lazy(() => import('../Pages/plan/RetirementPlan'))
  const WeddingPlan = lazy(() => import('../Pages/Calculator/WeddingCalculator'))
  const PurchasePlan = lazy(() => import('../Pages/Calculator/PurchaseCalculator'))
  const VehiclePlan = lazy(() => import('../Pages/Calculator/VehicleCalculator'))
  const VacationCalculator = lazy(() => import('../Pages/Calculator/VacationCalculator'))
  const PassionCalculator = lazy(() => import('../Pages/Calculator/PassionCalculator'))
  const JewelleryCalculator = lazy(() => import('../Pages/Calculator/JewelleryCalculator'))
  const EmergencyPlan = lazy(() => import('../Pages/Calculator/EmergencyCalculator'))
  const HomePurchaseCalculator = lazy(() => import('../Pages/Calculator/HomePurchaseCalculator'))
  const PostRetirementVacationPlan = lazy(() => import('../Pages/plan/PostRetirementVacationPlan'))
  // const Portfolio = lazy(() => import('../Pages/Portfolio'))
  const Holdings = lazy(() => import('../Pages/Portfolio/Holdings'))
  const Invest = lazy(() => import('../Pages/Portfolio/Invest'))
  // const Sips = lazy(() => import('../Pages/Sips'))
  const SipsNewScreen = lazy(() => import('../Pages/SipsNewScreen'))
  // const Activities = lazy(() => import('../Pages/Activities'))
  const Activities = lazy(() => import('../Pages/OrderHistory/LandingPage'))
  const Funds = lazy(() => import('../Pages/Funds'))
  const ThemeRisk = lazy(() => import('../Pages/ThemeRisk'))
  const ThemeRiskDetails = lazy(() => import('../Pages/ThemeRiskDetails'))
  // const ProfileOld = lazy(() => import('../Pages/Profile'))
  const Profile = lazy(() => import('../Pages/Profile/ProfileScreen'))
  const RiskAssessment = lazy(() => import('../Pages/RiskAssessment'))
  // const { EMandate } = lazy(() => import('../Pages/EMandate'))
  const ReferralPage = lazy(() => import('../Pages/ReferralPage'))
  const ReferralNewScreen = lazy(() => import('../Pages/ReferralNewScreen'))
  const EMandateMobile = lazy(() => import('../Pages/e-mandate-mobile/EMandateMobile'))
  const Policies = lazy(() => import('../Components/Information/Policies'))
  const Terms = lazy(() => import('../Components/Information/Terms'))
  const SupportPortalLogin = lazy(() => import('../Pages/SupportPortalLogin'))
  const EMandateSuccess = lazy(() => import('../Pages/EMandateSuccess'))
  const Insurance = lazy(() => import('../Pages/Insurance'))
  const PropertyPurchase = lazy(() => import('../Pages/plan/PropertyPurchase'))
  const IpoLandingPage = lazy(() => import('../Pages/ipo/IpoLandingPage'))
  const GoldBondsLandingPage = lazy(() => import('../Pages/GoldBonds/GoldBondsLandingPage'))
  const InvestmentsLandingPage = lazy(() => import('../Pages/MyInvestments/InvestmentsLandingPage'))
  const SupportPortal = lazy(() => import('../Pages/SupportPortal/SupportPortal'))

  return (
    <Routes>
      <Route path='/' element={<LoginContainer />}>
        <Route index element={<Navigate to='/login' />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/otp' element={<LoginOtp />} />
        <Route path='/sign-up' element={<SignUp />} />
        <Route path='/activate-account-otp' element={<ActivateAccountOTP />} />
        <Route path='/password' element={<LoginWithPassword />} />
        <Route path='/otp-login' element={<LoginWithOTP />} />
        <Route path='/reset-password' element={<ResetPassword />} />
      </Route>
      <Route path='/process/payment/:uid' element={<ThirdPartyAddFunds />} />
      <Route
        path='/dashboard'
        element={
          <PrivateRoute>
            <SSEProvider>
              <PostLoginContainer />
            </SSEProvider>
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
        <Route path='myplan' element={<MyPlan />} />
        <Route
          path='support'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <SupportPortal />
            </Suspense>
          }
        />
        <Route
          path='firstideas'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <FirstIdeasSection />
            </Suspense>
          }
        />
        <Route
          path='plans'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <Plans />
            </Suspense>
          }
        />
        <Route
          path='educationplan'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <ChildPlan />
            </Suspense>
          }
        />
        <Route
          path='retirementplan'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <RetirementCalculator />
            </Suspense>
          }
        />
        <Route
          path='purchaseplan'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <PurchasePlan />
            </Suspense>
          }
        />
        <Route
          path='vehicleplan'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <VehiclePlan />
            </Suspense>
          }
        />
        <Route
          path='weddingplan'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <WeddingPlan />
            </Suspense>
          }
        />
        <Route
          path='emergencyplan'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <EmergencyPlan />
            </Suspense>
          }
        />
        <Route
          path='vacationplan'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <VacationCalculator />
            </Suspense>
          }
        />
        <Route
          path='passionplan'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <PassionCalculator />
            </Suspense>
          }
        />
        <Route
          path='jewelleryplan'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <JewelleryCalculator />
            </Suspense>
          }
        />
        <Route
          path='homepurchase'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <HomePurchaseCalculator />
            </Suspense>
          }
        />
        <Route
          path='propertypurchase'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <PropertyPurchase />
            </Suspense>
          }
        />
        <Route
          path='postretirementvacationplan'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <PostRetirementVacationPlan />
            </Suspense>
          }
        />
        <Route
          path='portfolio'
          element={
            <ErrorBoundary>
              <Suspense fallback={<LoadingSkeleton />}>
                <InvestmentsLandingPage />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path='holdings'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <Holdings />
            </Suspense>
          }
        />
        <Route
          path='invest'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <Invest />
            </Suspense>
          }
        />
        {/* <Route
          path='sips'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <Sips />
            </Suspense>
          }
        /> */}
        <Route
          path='sips'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <SipsNewScreen />
            </Suspense>
          }
        />
        <Route
          path='activities'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <Activities />
            </Suspense>
          }
        />
        <Route
          path='funds'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <Funds />
            </Suspense>
          }
        />
        <Route
          path='theme'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <ThemeRisk />
            </Suspense>
          }
        />
        <Route
          path='ipo'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <IpoLandingPage />
            </Suspense>
          }
        />
        <Route
          path='goldbonds'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <GoldBondsLandingPage />
            </Suspense>
          }
        />
        <Route path='terms-and-conditions' element={<TermsAndConditions />} />
        <Route
          path='theme-details'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <ThemeRiskDetails />
            </Suspense>
          }
        />
        {/* <Route
          path='ProfileOld'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <ProfileOld />
            </Suspense>
          }
        /> */}
        <Route
          path='profile'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <DematUser>
                <Profile />
              </DematUser>
            </Suspense>
          }
        />
        <Route
          path='risk-assessment'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <RiskAssessment />
            </Suspense>
          }
        />
        <Route
          path='e-mandate'
          element={
            // <Suspense fallback={<LoadingSkeleton />}>
            <EMandate />
            // </Suspense>
          }
        />
        <Route
          path='refer'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <ReferralNewScreen />
            </Suspense>
          }
        />
        <Route
          path='insurance'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <Insurance />
            </Suspense>
          }
        />
        {/* <Route
          path='tax-filing'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <TaxFiling />
            </Suspense>
          }
        /> */}
      </Route>
      {/* <Route path='/status' element={<PostLoginContainer />}>
        <Route index path='e-mandate' element={<EMandateSuccess />} />
      </Route> */}
      <Route
        index
        path='status/e-mandate'
        element={
          <Suspense fallback={<LoadingSkeleton />}>
            <EMandateSuccess />
          </Suspense>
        }
      />
      <Route path='/redirect' element={<PostLoginContainer />}>
        <Route
          index
          path='freshdesk'
          element={
            <Suspense fallback={<LoadingSkeleton />}>
              <SupportPortalLogin />
            </Suspense>
          }
        />
      </Route>
      <Route
        path='sip/e-mandate'
        element={
          <Suspense fallback={<LoadingSkeleton />}>
            <EMandateMobile />
          </Suspense>
        }
      />
      <Route
        path='policies'
        element={
          <Suspense fallback={<LoadingSkeleton />}>
            <Policies />
          </Suspense>
        }
      />
      <Route
        path='terms'
        element={
          <Suspense fallback={<LoadingSkeleton />}>
            <Terms />
          </Suspense>
        }
      />

      <Route
        path='refer'
        element={
          <Suspense fallback={<LoadingSkeleton />}>
            <ReferralPage />
          </Suspense>
        }
      />
      <Route path='*' element={<Navigate replace to='/dashboard' />} />
    </Routes>
  )
}
