/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../Components/ui/tooltip'

import {
  Grid,
  Box,
  Typography,
  Link,
  Button,
  DialogTitle,
  Dialog,
  IconButton,
  DialogContent,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import PlansSelector from '../store/PlansStore'
import {
  // AccountDetailsSelector,
  AccountDetailsNewSelector,
  GetJourneyMessageSelector,
  UserDetails,
} from '../store/AccountDetailsStore'
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil'
import { useLocation, useNavigate } from 'react-router-dom'
import { isDematUser } from '../utils/AuthUtil'
import { toCurrency, indian__currency__rounder } from '../utils/CurrencyUtil'

import env from '../env/env'
import darkLogo from '../assets/DarkThemeFabLogo.png'
import badge from '../assets/images/badge.png'
import account_balance from '../assets/images/account_balance.png'
import Aadhar from '../assets/images/Aadhar.png'
import edit_document from '../assets/images/edit_document.png'
import signature from '../assets/images/signature.png'

import Quicko from '../assets/images/Quicko.svg'
import Quicko_Animation from '../assets/images/Quicko_Animation.gif'
import { useThemeMode } from '../Provider/ThemeContextProvider'
import ReactGA4 from 'react-ga4'
import clevertap from 'clevertap-web-sdk'

import {
  LifestyleSelector,
  WealthGrowOptions,
  AcceleratedWealthGrowOptions,
} from '../store/LifestyleStore'
import { InvestedPlansSelector } from '../store/InvestedPlansStore'
import { userDetailsState } from './LoginState'
// import taxIcon from '../assets/Icons/tax.svg'
import taxIcon from '../assets/images/Tax planning.svg'
import axios from 'axios'
import { get } from '../http/restservice'
import { ArrowDown, ArrowUp, ChevronRight, CircleAlert, MoveRight } from 'lucide-react'
import { InvestmentBoxes } from '../store/InvestmentStore'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../Components/ui/select'
import PlansCarousel from '../Components/Home/PlansCarousel'
import { HelpMeStartMobile, HelpMeStartWeb } from '../Components/Home/HelpMeStart'
import { useFormik } from 'formik'
import { Separator } from '../Components/ui/separator'
import { Progress } from '../Components/ui/progress'
import IconsSelector from '../store/IconsStore'
import updown from '../assets/Home/updown.svg'
import Loader from '../Components/Loader/Loader'
import { DrawerContext } from '../Provider/DrawerContext'
import { messageModalState } from '../store/Feedback'
import { SSEContext } from '../Provider/SSEProvider'
import KycNoInvestment from '../Components/Home/KycNoInvestment'

const Home = () => {
  const { marketStatus, message } = useContext(SSEContext)

  const theme = useTheme()
  const { darkMode } = useThemeMode()
  const plans = useRecoilValue(PlansSelector)
  const lifeStylePlans = useRecoilValue(LifestyleSelector)
  // const { state, contents: accountDetails } = useRecoilValueLoadable(AccountDetailsSelector)
  // const refreshSummary = useRecoilRefresher_UNSTABLE(AccountDetailsSelector)
  const { state, contents: accountDetails } = useRecoilValueLoadable(AccountDetailsNewSelector)
  const refreshSummary = useRecoilRefresher_UNSTABLE(AccountDetailsNewSelector)
  const navigate = useNavigate()
  const location = useLocation()
  const { state: stateUserDetails, contents: userBankDetails } = useRecoilValueLoadable(UserDetails)
  const journeyMessage = !isDematUser() ? useRecoilValue(GetJourneyMessageSelector) : null
  const { state: investedPlansState, contents: investedPlans } =
    useRecoilValueLoadable(InvestedPlansSelector)
  const refreshPlans = useRecoilRefresher_UNSTABLE(InvestedPlansSelector)
  const userDetails = useRecoilValue(userDetailsState)
  const [openQuickoModal, setOpenQuickoModal] = useState(false)
  const [openKYCModal, setOpenKYCModal] = useState(false)
  const [openKYCModalMobile, setOpenKYCModalMobile] = useState(false)
  const [timeoutVal, setTimeoutVal] = useState()
  const [isVerified, setIsVerified] = useState(false)
  const [url, setURL] = useState(env.QUICKO_URL)
  const [openMobileTooltip, setOpenMobileTooltip] = useState(false)
  const [contextCardData, setContextCardData] = useState([])
  const [displayContentData, setDisplayContentData] = useState([])
  const [contentCarouselData, setContentCarouselData] = useState([])
  const { contents: investments } = useRecoilValueLoadable(InvestmentBoxes)
  const [filterInvestment, setfilterInvestment] = useState('3y')
  const [openHelpMeStartDialog, setOpenHelpMeStartDialog] = useState(false)
  const [openHelpMeStartDrawer, setOpenHelpMeStartDrawer] = useState(false)
  const iconsList = useRecoilValue(IconsSelector)
  const { isDrawerOpen, setIsDrawerOpen } = useContext(DrawerContext)
  const setMessageModalState = useSetRecoilState(messageModalState)

  const filteredInvestments =
    investments.length > 0
      ? investments
          .filter((item) =>
            item.cagrDetails.some(
              (detail) => detail.code === filterInvestment && detail.cagr !== 'NA',
            ),
          )
          .map((item) => ({
            ...item,
            cagrDetails: item.cagrDetails.filter((detail) => detail.code === filterInvestment),
          }))
          .sort((a, b) => b.cagrDetails[0].cagr - a.cagrDetails[0].cagr)
          .slice(0, 5)
      : []

  useEffect(() => {
    refreshPlans()
    if (location?.state?.login) {
      setIsVerified(true)
    }
  }, [])

  useEffect(() => {
    const fetchContextCards = async () => {
      try {
        const res = await get(`${env.API_URL}customerservice/api/cards`)

        if (!res.isError) {
          setContextCardData(res)
        }
      } catch (error) {
        console.log('[CONEXT_CARD_ERROR]', error)
      }
    }

    const fetchDisplayCards = async () => {
      try {
        const res = await get(`${env.API_URL}customerservice/api/displayContent`)

        if (!res.isError) {
          setDisplayContentData(res)
        }
      } catch (error) {
        console.log('[DISPLAY_CONTENT_ERROR]', error)
      }
    }

    const fetchCarouselContent = async () => {
      try {
        const res = await get(`${env.API_URL}customerservice/api/carouselContent`)
        if (!res.isError) {
          setContentCarouselData(res)
        }
      } catch (error) {
        console.log('[CONERENT_CAROUSEL_ERROR]', error)
      }
    }

    fetchDisplayCards()
    fetchContextCards()
    fetchCarouselContent()
  }, [])

  useEffect(() => {
    if (userDetails?.email && userDetails?.phoneNumber) createURLWithOptions()
  }, [userDetails])

  useEffect(() => {
    ReactGA4.event({
      category: 'Login',
      action: 'Viewed Dashboard',
      value: userDetails.email,
    })

    clevertap.event.push('Page Viewed', {
      Page_name: 'Dashboard',
    })
    window.$zoho.salesiq.ready = function () {
      window.$zoho.salesiq.visitor.name(userDetails.firstName + ' ' + userDetails.lastName)
      window.$zoho.salesiq.visitor.email(userDetails.email)
    }
  }, [userDetails])

  const getInvestedPlanCount = (type) => {
    if (investedPlansState === 'hasValue' && investedPlans && investedPlans.length > 0) {
      const filteredPlans = investedPlans.filter((plan) => plan.planType === type)
      return filteredPlans.length + 1
    } else {
      return 1
    }
  }

  useEffect(() => refreshSummary(), [])

  useEffect(() => {
    clevertap.event.push('Page Viewed', {
      Page_name: 'Home Page',
    })
  }, [])

  const navigateToTheme = (id, name) => {
    console.log('GA event', userDetails.email)

    ReactGA4.event({
      category: 'Plan',
      action: 'Viewed ' + name + ' Plan',
      value: userDetails.email,
    })

    // Track the first event with CleverTap
    clevertap.event.push('Viewed Plan', {
      Plan_Name: name,
      Page_name: 'Dashboard',
    })

    // Track the second event with CleverTap
    clevertap.event.push('Clicked Plan', {
      Plan_Name: name,
      Page_name: 'Dashboard',
    })
    navigate('/dashboard/theme-details', { state: { selectedItem: { id } } })
  }

  const navigateToThemeDetails = (item) => {
    navigate('/dashboard/theme-details', {
      state: {
        selectedItem: item,
      },
    })
  }

  const createURLWithOptions = () => {
    const { email, phoneNumber } = userDetails
    const dataForQuick = {
      user: {
        email,
        mobile: {
          isd: '91',
          number: phoneNumber.replace('+91', ''),
        },
      },
      theme: {
        mode: 'dark',
        seed: '#4F9B7E',
      },
      intent: 'SAVE' | 'PAY' | 'FILE' | 'TRACK',
    }
    setURL(`${env.QUICKO_URL}?options=${btoa(JSON.stringify(dataForQuick))}`)
  }

  const getDisplayContentBox = (id) => {
    return displayContentData.length > 0 && displayContentData.filter((item) => item.idx == id)
  }

  const getCagrValue = (cagrDetails) => {
    let cagrValue = null
    let cagrCode = null

    for (let detail of cagrDetails) {
      if (detail.code === '3y' && detail.cagr !== 'NA') {
        cagrCode = '3y'
        cagrValue = detail.cagr
        return { cagrCode, cagrValue }
      }

      if (detail.code === '2y' && detail.cagr !== 'NA') {
        cagrCode = '2y'
        cagrValue = detail.cagr
        return { cagrCode, cagrValue }
      }
      if (detail.code === '1y' && detail.cagr !== 'NA') {
        cagrCode = '1y'
        cagrValue = detail.cagr
        return { cagrCode, cagrValue }
      }
    }
  }

  if (state === 'loading') {
    return <Loader />
  }

  return (
    <>
      {/* Mobile and tablet mode code */}

      <div className='lg:hidden'>
        {isDematUser() &&
          Object.keys(accountDetails).length > 0 &&
          (marketStatus === 'OPEN' ||
            marketStatus === 'MAINTENANCE_WINDOW' ||
            marketStatus === 'AMO_ORDERS') && (
            <p
              className={`text-xs font-medium my-4 flex gap-x-2 justify-center items-center ${
                marketStatus === 'OPEN' ? 'text-secondary-600' : 'text-warning-600'
              }`}
            >
              {marketStatus === 'OPEN' ? (
                <span className='relative flex h-3 w-3'>
                  <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-secondary-600'></span>
                  <span className='relative inline-flex rounded-full h-3 w-3 bg-secondary-600'></span>
                </span>
              ) : (
                <span className='h-3 w-3 border-[1.5px] border-dashed border-warning-500 rounded-full'></span>
              )}
              {message}
            </p>
          )}

        {isDematUser() &&
          Object.keys(accountDetails).length === 0 &&
          marketStatus !== 'DAILY_MAINTENANCE_WINDOW' && (
            <KycNoInvestment userDetails={userDetails} box={getDisplayContentBox(1)[0]} />
          )}

        {isDematUser() &&
          Object.keys(accountDetails).length > 0 &&
          state === 'hasValue' &&
          marketStatus !== 'DAILY_MAINTENANCE_WINDOW' && (
            <>
              <div className='bg-white p-5 rounded-[20px] border mx-4 mt-4'>
                <div className='h-auto flex flex-col gap-y-4 p-2'>
                  <div className='flex flex-col justify-center items-center gap-0'>
                    <h1 className='text-center text-[#41558D] kerning-[48px] mb-1'>
                      <span className='text-3xl font-extrabold'> &#8377;</span>{' '}
                      <span className='text-4xl font-OrelegaOne'>
                        {Object.keys(accountDetails).length == 0
                          ? 0
                          : toCurrency(accountDetails.currentInvestment).split('.')[0]}
                      </span>
                      {Object.keys(accountDetails).length > 0 && (
                        <span className='text-2xl font-OrelegaOne'>
                          .{accountDetails.currentInvestment.split('.')[1]}
                        </span>
                      )}
                    </h1>
                    <div className='flex justify-center items-center gap-x-3 text-sm'>
                      <span className='flex gap-x-2 items-center'>
                        <p className='text-black-300 font-medium text-[14px] kerning-[20px]'>
                          Today
                        </p>

                        <span
                          className={`${
                            toCurrency(accountDetails.todaysChange) > 0 ||
                            !toCurrency(accountDetails.todaysChange)
                              ? 'text-secondary-600'
                              : 'text-error-600'
                          } font-semibold text-black-500 text-[16px] kerning-[24px]`}
                        >
                          {Object.keys(accountDetails).length == 0 ||
                          accountDetails.todaysChange === 'NaN'
                            ? 0
                            : toCurrency(accountDetails.todaysChange)}{' '}
                          %
                        </span>
                      </span>
                      {Object.keys(accountDetails).length > 0 &&
                        indian__currency__rounder(accountDetails.currentReturns) !== 0 && (
                          <span
                            className={`${
                              toCurrency(accountDetails.todaysChange) > 0
                                ? 'bg-secondary-600'
                                : 'bg-error-600'
                            } rounded-[50%] h-5 w-5 flex justify-center items-center text-white`}
                          >
                            {toCurrency(accountDetails.todaysChange) > 0 ? (
                              <ArrowUp className='h-4 w-3' />
                            ) : (
                              <ArrowDown className='h-4 w-3' />
                            )}
                          </span>
                        )}

                      <TooltipProvider delayDuration={0}>
                        <Tooltip open={openMobileTooltip} onOpenChange={setOpenMobileTooltip}>
                          <TooltipTrigger onClick={() => setOpenMobileTooltip(!openMobileTooltip)}>
                            <CircleAlert className='h-5 w-5 text-white rotate-180' fill='#727272' />
                          </TooltipTrigger>
                          <TooltipContent className='bg-black-500 max-w-[275px] border-0'>
                            <p className='text-white'>
                              Investments could take up to 2 days to reflect in your account
                            </p>
                            <TooltipArrow />
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  </div>
                  <div className='flex justify-between'>
                    <div className='flex flex-col justify-center items-center w-[50%]'>
                      <p className='text-black-500 font-semibold text-l'>
                        &#8377;{' '}
                        {Object.keys(accountDetails).length == 0
                          ? 0
                          : toCurrency(accountDetails.totalInvestment).split('.')[0]}
                      </p>
                      <p className='text-[#727272] font-medium text-[14px] kerning-[20px]'>
                        Invested
                      </p>
                    </div>

                    <div className='flex flex-col justify-center items-center w-[50%]'>
                      <p className='text-black-500 font-semibold text-l'>
                        &#8377;{' '}
                        {Object.keys(accountDetails).length == 0
                          ? 0
                          : toCurrency(accountDetails.currentReturns).split('.')[0] === '-0'
                          ? 0
                          : toCurrency(accountDetails.currentReturns).split('.')[0]}
                      </p>
                      <p className='flex justify-center items-center gap-x-2 text-[#727272] font-medium text-sm'>
                        Returns{' '}
                        {Object.keys(accountDetails).length > 0 &&
                          indian__currency__rounder(accountDetails.overallChange) !== 0 && (
                            <span
                              className={`${
                                indian__currency__rounder(accountDetails.overallChange) > 0
                                  ? 'bg-secondary-600'
                                  : 'bg-error-600'
                              } rounded-[50%] h-5 w-5 flex justify-center items-center`}
                            >
                              {indian__currency__rounder(accountDetails.overallChange) > 0 ? (
                                <ArrowUp className='h-4 w-3 text-white' />
                              ) : (
                                <ArrowDown className='h-4 w-3 text-white' />
                              )}
                            </span>
                          )}
                        <span
                          className={`font-semibold ${
                            toCurrency(accountDetails.overallChange) > 0 ||
                            !toCurrency(accountDetails.overallChange)
                              ? 'text-secondary-600'
                              : 'text-error-600'
                          }`}
                        >
                          {!toCurrency(accountDetails.overallChange) ||
                          accountDetails.overallChange === 'NaN'
                            ? 0
                            : toCurrency(accountDetails.overallChange)}{' '}
                          %
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

        {!isDematUser() && (
          <>
            <Box
              sx={{
                fontWeight: '500',
                padding: '20px 16px',
                height: 'calc(100% - 32px)',
                background: 'linear-gradient(95.63deg, #6A85D2 -5.73%, #213773 105.89%)',
              }}
              className='border-y'
            >
              <Box className='flex flex-1 justify-between items-center'>
                <Box>
                  <Typography
                    component='h3'
                    fontWeight={600}
                    sx={{ fontSize: '18px', lineHeight: '26px', letterSpacing: '-0.72px' }}
                    color={'#ffffff'}
                  >
                    Complete your KYC
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    component='h3'
                    fontWeight={400}
                    sx={{ fontSize: '14px', fontWeight: '600' }}
                    color={'#ffffff'}
                  >
                    75%
                    <span className='font-[400] ml-1'>completed</span>
                  </Typography>
                </Box>
              </Box>

              <Box className='relative mt-[24px]'>
                <Box className='flex absolute z-[1] justify-around w-[100%] top-[-3px]'>
                  <Box>
                    <img src='/Home/small_check.svg' />
                  </Box>
                  <Box>
                    <img src='/Home/exclamation.svg' />
                  </Box>
                </Box>
                <Progress value={75} className='w-[100%]' />
              </Box>

              <Typography>
                <Typography
                  component='h4'
                  fontWeight={400}
                  sx={{ my: 1, fontSize: '14px', color: '#ECEEF4', marginTop: '24px' }}
                >
                  Half way through the journey to financial freedom!
                  <br />
                  Complete your KYC to begin investing.
                </Typography>
                <Link
                  component='h4'
                  fontWeight={700}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setOpenKYCModalMobile(true)
                  }}
                >
                  <Button
                    variant='contained'
                    sx={{
                      borderRadius: '12px',
                      background: 'white',
                      color: '#41558D',
                      fontSize: '14px',
                      fontWeight: '500',
                      fontFamily: 'DM Sans',
                      textTransform: 'none',
                      padding: '10px 16px 10px 24px',
                      marginTop: '24px',
                      boxShadow: 'none',
                      justifyContent: 'space-between',
                      columnGap: '4px',
                      width: '100%',
                      '&:hover': {
                        backgroundColor: '#f6f6f6',
                        boxShadow: 'none',
                      },
                      '&:focus': {
                        backgroundColor: '#f6f6f6',
                        boxShadow: 'none',
                      },
                    }}
                    onClick={() => {
                      clevertap.event.push('Clicked Button', {
                        Action: 'KYC',
                        Page_name: 'Dashboard',
                      })
                      setMessageModalState({
                        message: 'Your KYC is incomplete.',
                        linkMessage:
                          'Click here to complete your KYC and to enable hassle free, one-click investing.',
                        severity: 'info',
                      })
                    }}
                  >
                    Resume
                    <span className='material-symbols-rounded icon-400 text-[20px]'>
                      arrow_forward
                    </span>
                  </Button>
                </Link>
              </Typography>
            </Box>

            <div className='flex flex-col gap-4  w-full justify-between rounded-3xl px-4 mt-3'>
              {[...contextCardData]
                .filter((eachItem) => eachItem.role === 'nondemat')
                .map((eachItem) => (
                  <>
                    <div
                      className='flex justify-evenly w-full border gap-2 items-center rounded-[20px] py-8 px-4 min-w-[30%] max-h-44 cursor-pointer shadow-[0_6px_16px_0_rgba(65,85,141,0.08)]'
                      key={eachItem.primary_text}
                      onClick={() => {
                        navigate(eachItem.link)
                        clevertap.event.push('Clicked Home Card', {
                          Action: eachItem.primary_text,
                          Page_name: 'Dashboard',
                        })
                      }}
                      style={{ backgroundColor: eachItem.background_color }}
                    >
                      <div className='relative'>
                        <img src={eachItem.icon} alt={eachItem.primary_text} />
                        {eachItem?.tag && (
                          <p className='absolute top-[-10px] left-[50%] bg-[#74a766] px-[6px] py-[2px] rounded-md text-white uppercase translate-x-[-50%] text-[12px] kerning-[16px] font-semibold'>
                            {eachItem.tag}
                          </p>
                        )}
                      </div>
                      <div className='flex flex-col justify-center items-center gap-y-1'>
                        <p className='text-lg m-0 p-0 font-semibold -tracking-[0.64px] text-black-500'>
                          {eachItem.primary_text === 'Others' ? 'IPO' : eachItem.primary_text}
                        </p>
                        <p className='p-0 m-0 text-black-400 text-[14px] kerning-[20px] text-center w-[190px]'>
                          {eachItem.secondary_text}
                        </p>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </>
        )}

        {isDematUser() && (
          <Grid md={8} className='mt-4'>
            <div>
              <div className='flex gap-4  w-full justify-between rounded-3xl px-4'>
                {[...contextCardData].splice(0, 2).map((eachItem) => (
                  <React.Fragment key={eachItem.primary_text}>
                    {isDrawerOpen && eachItem.primary_text === 'Help me start' && (
                      <HelpMeStartMobile
                        open={isDrawerOpen}
                        setOpen={setIsDrawerOpen}
                        phoneNumber={userDetails.phoneNumber}
                      />
                    )}
                    <div
                      className='flex flex-col w-full border gap-2 items-center rounded-[20px] py-8 px-4 min-w-[30%] max-h-44 cursor-pointer shadow-[0_6px_16px_0_rgba(65,85,141,0.08)]'
                      onClick={() => {
                        if (eachItem.primary_text === 'Help me start') {
                          setIsDrawerOpen(!isDrawerOpen)
                        } else {
                          navigate(eachItem.link)
                        }

                        clevertap.event.push('Clicked Home Card', {
                          Action: eachItem.primary_text,
                          Page_name: 'Dashboard',
                        })
                      }}
                      style={{ backgroundColor: eachItem.background_color }}
                    >
                      <div className='relative'>
                        <img src={eachItem.icon} alt={eachItem.primary_text} />
                        {eachItem?.tag && (
                          <p className='absolute top-[-10px] left-[50%] bg-[#74a766] px-[6px] py-[2px] rounded-md text-white uppercase translate-x-[-50%] text-[12px] kerning-[16px] font-semibold'>
                            {eachItem.tag}
                          </p>
                        )}
                      </div>
                      <div className='flex flex-col justify-center items-center gap-y-1'>
                        <p className='text-lg m-0 p-0 font-semibold -tracking-[0.64px] text-black-500'>
                          {eachItem.primary_text}
                        </p>
                        <p className='p-0 m-0 text-black-400 text-[14px] kerning-[20px] text-center'>
                          {eachItem.secondary_text}
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
              <div className='grid grid-cols-2 gap-3 rounded-3xl mt-4 px-4 '>
                {[...contextCardData].splice(2, 5).map((eachItem) => (
                  <div
                    className='flex gap-3 border bg-white items-center rounded-[20px] px-4 py-6 min-w-[30%] max-h-20  cursor-pointer shadow-[0_6px_16px_0_rgba(65,85,141,0.08)]'
                    key={eachItem.primary_text}
                    onClick={() => {
                      navigate(eachItem.link),
                        clevertap.event.push('Clicked Home Card', {
                          Action: eachItem.primary_text,
                          Page_name: 'Dashboard',
                        })
                    }}
                    style={{ backgroundColor: eachItem.background_color }}
                  >
                    <img
                      src={
                        eachItem.primary_text === 'Insurance'
                          ? '/Home/insurance.svg'
                          : eachItem.icon
                      }
                      alt={eachItem.primary_text}
                    />
                    <p className='text-lg m-0 p-0 -tracking-[0.64px] font-semibold text-black-500'>
                      {eachItem.primary_text}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
        )}

        <Grid
          item
          style={{
            cursor: 'pointer',
          }}
          my={2}
          mx={2}
        >
          {displayContentData.length > 0 &&
            getDisplayContentBox(2).map((el) => (
              <div
                key={el.idx}
                onClick={() =>
                  navigate(el.link, {
                    state: {
                      selectedItem: { id: el.id },
                    },
                  })
                }
                className='w-full h-full cursor-pointer'
              >
                <img src={el.data} alt={el.alt} className='w-full' />
              </div>
            ))}
        </Grid>

        <div className='h-auto w-full bg-white py-5 px-4 my-4 border'>
          <div className='flex justify-between items-center'>
            <h1 className='font-semibold text-[20px] kerning-[28px] -tracking-[0.72px] text-black-500'>
              Popular Investment Options
            </h1>

            <button
              className='flex items-center border-b-2 border-dashed border-primary-500 cursor-pointer'
              onClick={() =>
                setfilterInvestment(
                  filterInvestment === '1y' ? '2y' : filterInvestment === '2y' ? '3y' : '1y',
                )
              }
            >
              {filterInvestment}
              <img src={updown} alt='updown' className='h-5' />
            </button>
          </div>

          <div className='flex flex-col gap-y-6 mt-5'>
            {filteredInvestments.length > 0 ? (
              filteredInvestments.map((item, index) => (
                <React.Fragment key={item.id}>
                  <div
                    className={`flex gap-x-4 ${index !== filterInvestment.length && 'pb-4'}`}
                    onClick={() => navigateToThemeDetails(item)}
                  >
                    <div className='rounded-lg'>
                      <img
                        className='w-[54px] h-[54px] lg:w-[72px] lg:h-[72px] rounded-[8px] min-w-[54px] lg:min-w-[72px]'
                        src={iconsList['light' + item.id]}
                        alt={item.name}
                      />
                    </div>
                    <div className='w-full'>
                      <div className='flex justify-between items-start mb-1'>
                        <h1 className='font-semibold text-[18px] kerning-[26px] -tracking-[0.64px] text-black-500'>
                          {item.name}
                        </h1>

                        <ChevronRight className='h-6 w-6 text-[#727272]' />
                      </div>

                      <div className='flex items-start pr-[32px] gap-x-[24px]'>
                        <span className='w-[96px]'>
                          <p className='text-black-300 text-[14px] kerning-[20px] font-medium'>
                            Start at
                          </p>
                          <p className='font-semibold text-l text-black-500'>
                            &#8377; {item.min_investment}
                          </p>
                        </span>
                        <span>
                          <p className='text-black-300 font-medium text-[14px] kerning-[20px]'>
                            {getCagrValue(item?.cagrDetails)?.cagrCode} Returns
                          </p>

                          <p className='font-semibold text-black-500'>
                            {toCurrency(getCagrValue(item?.cagrDetails)?.cagrValue)}%
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>

                  <Separator />
                </React.Fragment>
              ))
            ) : (
              <p className='text-center'>No investment options available</p>
            )}
          </div>

          <div
            className='w-full flex justify-between items-center mt-5'
            onClick={() => navigate('/dashboard/theme')}
          >
            <button className='text-primary-500 font-semibold -tracking-[0.64px] text-[18px] kerning-[26px]'>
              View all {investments.length} funds
            </button>

            <ChevronRight className='text-primary-500' />
          </div>
        </div>

        <Grid md={12} mt={3}>
          <div className='flex flex-col px-4 py-5 gap-y-5 border bg-white'>
            <h3 className='font-semibold text-[20px] kerning-[28px] -tracking-[0.72px] text-black-500'>
              Understand your Money
            </h3>
            <PlansCarousel contentCarouselData={contentCarouselData} />
          </div>
        </Grid>
      </div>

      {/* Desktop mode code */}
      <div className='hidden lg:flex gap-x-6'>
        <div
          className={`flex flex-col gap-y-4 ${
            displayContentData.length > 0 && getDisplayContentBox(1).length > 0
              ? 'w-8/12'
              : 'w-full'
          }`}
        >
          {isDematUser() &&
            Object.keys(accountDetails).length === 0 &&
            marketStatus !== 'DAILY_MAINTENANCE_WINDOW' && (
              <KycNoInvestment userDetails={userDetails} box={getDisplayContentBox(1)[0]} />
            )}

          {marketStatus === 'DAILY_MAINTENANCE_WINDOW' && (
            <div className='text-warning-600 text-sm bg-warning-50 py-3 px-6 flex gap-x-2 justify-center items-center rounded-2xl border border-warning-100'>
              <span className='h-3 w-3 border-[1.5px] border-dashed border-warning-500 rounded-full'></span>{' '}
              {message}
            </div>
          )}

          {/* AT A Glance section */}
          {isDematUser() &&
            Object.keys(accountDetails).length > 0 &&
            state === 'hasValue' &&
            marketStatus !== 'DAILY_MAINTENANCE_WINDOW' && (
              <>
                <div className='bg-white p-7 rounded-[32px] border h-auto w-full'>
                  <div className='flex justify-between mb-3'>
                    <h1 className='text-primary-300 font-medium text-sm mb-3'>AT A GLANCE</h1>
                    {(marketStatus === 'OPEN' ||
                      marketStatus === 'MAINTENANCE_WINDOW' ||
                      marketStatus === 'AMO_ORDERS') && (
                      <p
                        className={`text-sm font-medium flex gap-x-2 items-center ${
                          marketStatus === 'OPEN' ? 'text-secondary-600' : 'text-warning-600'
                        }`}
                      >
                        {message}

                        {marketStatus === 'OPEN' ? (
                          <span className='relative flex h-3 w-3'>
                            <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-secondary-600'></span>
                            <span className='relative inline-flex rounded-full h-3 w-3 bg-secondary-600'></span>
                          </span>
                        ) : (
                          <span className='h-3 w-3 border-[1.5px] border-dashed border-warning-500 rounded-full'></span>
                        )}
                      </p>
                    )}
                  </div>
                  <div className='flex flex-wrap justify-between items-end w-full'>
                    <div>
                      <h1 className='text-2xl text-primary-500'>
                        <span className='text-[1.6rem] font-extrabold'>&#8377;</span>{' '}
                        <span className='font-OrelegaOne text-[2rem] tracking-[4%]'>
                          {(Object.keys(accountDetails).length == 0
                            ? 0
                            : toCurrency(accountDetails.currentInvestment).split('.')[0]) || 0}
                        </span>
                        {Object.keys(accountDetails).length > 0 && (
                          <span className='text-2xl font-OrelegaOne'>
                            .{accountDetails.currentInvestment.split('.')[1]}
                          </span>
                        )}
                      </h1>
                      <p className='text-sm flex justify-center items-center gap-x-2 font-medium text-black-300'>
                        Current Value
                        <TooltipProvider delayDuration={300}>
                          <Tooltip>
                            <TooltipTrigger>
                              <CircleAlert
                                className='h-5 w-5 text-white rotate-180'
                                fill='#727272'
                              />
                            </TooltipTrigger>
                            <TooltipContent className='bg-black-500 max-w-[275px]'>
                              <p className='text-white'>
                                Investments could take up to 2 days to reflect in your account
                              </p>
                              <TooltipArrow />
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </p>
                    </div>
                    <div>
                      <h1 className='font-semibold leading-6 text-xl'>
                        &#8377;{' '}
                        {Object.keys(accountDetails).length == 0
                          ? 0
                          : toCurrency(accountDetails.totalInvestment).split('.')[0] || 0}
                      </h1>
                      <p className='text-sm font-medium text-black-300'>Invested Amount</p>
                    </div>

                    <div>
                      <h1 className='font-semibold leading-6 text-xl'>
                        &#8377;{' '}
                        {Object.keys(accountDetails).length == 0
                          ? 0
                          : toCurrency(accountDetails.currentReturns).split('.')[0] == '-0'
                          ? 0
                          : toCurrency(accountDetails.currentReturns).split('.')[0]}
                      </h1>
                      <div className='flex gap-x-2 justify-center items-center'>
                        <p className='text-sm font-medium text-black-300'>Returns</p>

                        {Object.keys(accountDetails).length > 0 &&
                          toCurrency(accountDetails.currentReturns) !== 0 && (
                            <span
                              className={`${
                                accountDetails.currentReturns > 0
                                  ? 'bg-secondary-600'
                                  : 'bg-error-600'
                              } rounded-[50%] h-5 w-5 flex justify-center items-center text-white`}
                            >
                              {accountDetails.currentReturns > 0 ? (
                                <ArrowUp className='h-4 w-3' />
                              ) : (
                                <ArrowDown className='h-4 w-3' />
                              )}
                            </span>
                          )}

                        <span
                          className={`font-semibold ${
                            toCurrency(accountDetails.overallChange) > 0 ||
                            !toCurrency(accountDetails.overallChange)
                              ? 'text-secondary-600'
                              : 'text-error-600'
                          }`}
                        >
                          {!toCurrency(accountDetails.overallChange) ||
                          accountDetails.overallChange === 'NaN'
                            ? 0
                            : toCurrency(accountDetails.overallChange)}{' '}
                          %
                        </span>
                      </div>
                    </div>
                    <div>
                      <h1 className='font-semibold leading-6 text-xl'>
                        {toCurrency(
                          accountDetails.todaysChange === 'NaN' ? 0 : accountDetails.todaysChange,
                        ) || 0}{' '}
                        %
                      </h1>
                      <div className='flex gap-x-2 justify-center items-center'>
                        <p className='text-sm font-medium text-black-300'>Today</p>
                        {Object.keys(accountDetails).length > 0 &&
                          toCurrency(accountDetails.todaysChange) !== 0 && (
                            <span
                              className={`${
                                toCurrency(accountDetails.todaysChange) > 0
                                  ? 'bg-secondary-600'
                                  : 'bg-error-600'
                              } rounded-[50%] h-5 w-5 flex justify-center items-center text-white`}
                            >
                              {toCurrency(accountDetails.todaysChange) > 0 ? (
                                <ArrowUp className='h-4 w-3' />
                              ) : (
                                <ArrowDown className='h-4 w-3' />
                              )}
                            </span>
                          )}
                        {/* <p>{toCurrency(accountDetails.overallChange)}%</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

          {/* Non-KYC use Glance section */}
          {!isDematUser() && (
            <>
              <Grid container md={12}>
                <Grid item sx={{ width: '100%' }}>
                  <Box
                    className='border'
                    sx={{
                      fontWeight: '500',
                      // background: theme.palette.secondary.main,
                      padding: '32px',
                      borderRadius: '24px',
                      textAlign: 'left',
                      background: 'linear-gradient(95.63deg, #6A85D2 -5.73%, #213773 105.89%)',
                      color: '#FFFFFF',
                    }}
                  >
                    <Box className='flex flex-1'>
                      <Box className='flex flex-1 gap-1'>
                        <Typography
                          component='h3'
                          fontWeight={600}
                          letterSpacing='-0.96px'
                          sx={{ mb: '24px', color: '#ffffff', fontSize: '24px' }}
                        >
                          Complete your KYC
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          component='p'
                          fontWeight={600}
                          sx={{ mb: '24px', color: '#ffffff', fontSize: '16px' }}
                        >
                          75%
                          <span className='font-[400] ml-1'>completed</span>
                        </Typography>
                      </Box>
                    </Box>
                    <Box className='relative'>
                      <Box className='flex absolute z-[1] justify-around w-[100%] top-[-3px]'>
                        <Box>
                          <img src='/Home/small_check.svg' />
                        </Box>
                        <Box>
                          <img src='/Home/exclamation.svg' />
                        </Box>
                      </Box>
                      <Progress value={75} className='w-[100%]' />
                    </Box>
                    <div className='flex flex-1 mt-[24px] justify-between items-center'>
                      <Box>
                        <Typography
                          component='h4'
                          fontWeight={400}
                          sx={{ color: '#ECEEF4', fontSize: '14px' }}
                        >
                          Half way through the journey to financial freedom!
                          <br />
                          Complete your KYC to begin investing.
                        </Typography>
                      </Box>
                      <Box>
                        <Link
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            setOpenKYCModal(true)
                          }}
                        >
                          <Button
                            onClick={() => {
                              clevertap.event.push('Clicked Resume Button', {
                                Action: 'Clicked Resume',
                                Page_name: 'Dashboard',
                              })
                              setMessageModalState({
                                message: 'Your KYC is incomplete.',
                                linkMessage:
                                  'Click here to complete your KYC and to enable hassle free, one-click investing.',
                                severity: 'info',
                              })
                            }}
                            variant='contained'
                            sx={{
                              borderRadius: '8px',
                              background: 'white',
                              color: '#41558D',
                              fontSize: '14px',
                              fontWeight: '500',
                              textTransform: 'none',
                              fontFamily: 'DM Sans',
                              boxShadow: 'none',
                              padding: '10px 16px 10px 24px',
                              columnGap: '4px',
                              '&:hover': {
                                backgroundColor: '#f6f6f6',
                                boxShadow: 'none',
                              },
                              '&:focus': {
                                backgroundColor: '#f6f6f6',
                                boxShadow: 'none',
                              },
                            }}
                          >
                            Complete KYC{' '}
                            <span className='material-symbols-rounded icon-400 text-[20px]'>
                              arrow_forward
                            </span>
                          </Button>
                        </Link>
                      </Box>
                    </div>
                  </Box>
                </Grid>
              </Grid>

              <div className='flex flex-col lg:flex-row gap-4 w-full justify-between rounded-3xl mt-6'>
                {[...contextCardData]
                  .filter((eachItem) => eachItem.role === 'nondemat')
                  .map((eachItem) => (
                    <>
                      <div
                        className='flex justify-evenly w-full border gap-2 items-center rounded-[20px] py-8 px-4 min-w-[30%] max-h-44 cursor-pointer shadow-[0_6px_16px_0_rgba(65,85,141,0.08)]'
                        key={eachItem.primary_text}
                        onClick={() => {
                          eachItem.primary_text === 'Help me start'
                            ? setOpenHelpMeStartDrawer(true)
                            : navigate(eachItem.link),
                            clevertap.event.push('Clicked Card', {
                              Action: eachItem.primary_text,
                            })
                        }}
                        style={{ backgroundColor: eachItem.background_color }}
                      >
                        <div className='relative'>
                          <img
                            src={
                              eachItem.primary_text === 'Tax Filing'
                                ? eachItem.icon
                                : '/Home/ipo_card_image.svg'
                            }
                            alt={eachItem.primary_text}
                          />
                          {eachItem?.tag && (
                            <p className='absolute top-[-10px] left-[50%] bg-[#74a766] px-[6px] py-[2px] rounded-md text-white uppercase translate-x-[-50%] text-[12px] kerning-[16px] font-semibold'>
                              {eachItem.tag}
                            </p>
                          )}
                        </div>
                        <div className='flex flex-col justify-center items-center gap-y-1'>
                          <p className='text-lg m-0 p-0 font-semibold -tracking-[0.64px] text-black-500'>
                            {eachItem.primary_text === 'Others' ? 'IPO' : eachItem.primary_text}
                          </p>
                          <p className='p-0 m-0 text-black-400 text-[14px] kerning-[20px] text-center w-[190px]'>
                            {eachItem.secondary_text}
                          </p>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </>
          )}

          {/* Display cards section */}
          {isDematUser() && (
            <div className='flex flex-col gap-y-4'>
              <div className='flex gap-4 w-full justify-between rounded-3xl'>
                {[...contextCardData].splice(0, 3).map((eachItem) => (
                  <React.Fragment key={eachItem.primary_text}>
                    {openHelpMeStartDialog && eachItem.primary_text === 'Help me start' && (
                      <HelpMeStartWeb
                        open={openHelpMeStartDialog}
                        setOpen={setOpenHelpMeStartDialog}
                        phoneNumber={userDetails.phoneNumber}
                      />
                    )}
                    <div
                      className='flex flex-col flex-1 border gap-2 items-center rounded-3xl py-8 px-4 min-w-[30%] cursor-pointer shadow-[0_6px_16px_0_rgba(65,85,141,0.08)] hover:shadow-[0_8px_24px_0_rgba(65,85,141,0.12)] transition-shadow'
                      onClick={() => {
                        eachItem.primary_text === 'Help me start'
                          ? setOpenHelpMeStartDialog(!openHelpMeStartDialog)
                          : navigate(eachItem.link),
                          clevertap.event.push('Clicked Home Card', {
                            Action: eachItem.primary_text,
                            Page_name: 'Dashboard',
                          })
                      }}
                      style={{ backgroundColor: eachItem.background_color }}
                    >
                      <div className='relative'>
                        <img
                          src={eachItem.icon}
                          alt={eachItem.primary_text}
                          className='h-[52px] w-[52px]'
                        />
                        {eachItem?.tag && (
                          <p className='absolute top-[-10px] left-[50%] bg-[#74a766] px-2 text-xs p-[2px] font-semibold rounded-md text-white uppercase translate-x-[-50%]'>
                            {eachItem.tag}
                          </p>
                        )}
                      </div>
                      <div className='flex flex-col gap-y-1'>
                        <p className='text-xl m-0 p-0 text-black-500 -tracking-[0.8px] font-semibold text-center'>
                          {eachItem.primary_text}
                        </p>
                        <p className='text-[14px] p-0 m-0 text-black-400 leading-[20px] text-center'>
                          {eachItem.secondary_text}
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
              <div className='flex w-full rounded-3xl gap-4 justify-between'>
                {[...contextCardData].splice(3, 5).map((eachItem) => (
                  <div
                    className='flex flex-1 justify-center gap-2 border bg-white items-center rounded-3xl p-6 min-w-[30%] max-h-20 cursor-pointer shadow-[0_6px_16px_0_rgba(65,85,141,0.08)] hover:shadow-[0_8px_24px_0_rgba(65,85,141,0.12)] transition-shadow'
                    key={eachItem.primary_text}
                    onClick={() => {
                      navigate(eachItem.link),
                        clevertap.event.push('Clicked Home Card', {
                          Action: eachItem.primary_text,
                          Page_name: 'Dashboard',
                        })
                    }}
                    style={{ backgroundColor: eachItem.background_color }}
                  >
                    <img
                      src={eachItem.icon}
                      alt={eachItem.primary_text}
                      className='h-[32px] w-[32px]'
                    />
                    <p className='text-xl m-0 p-0 text-black-500 font-semibold -tracking-[0.8px]'>
                      {eachItem.primary_text}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Discover top funds display contentx box */}

          {displayContentData.length > 0 &&
            getDisplayContentBox(3).map((el) => (
              <div
                key={el.idx}
                onClick={() =>
                  navigate(el.link, {
                    state: {
                      selectedItem: { id: el.id },
                    },
                  })
                }
                className='w-full h-full ml-2 cursor-pointer my-2'
              >
                <img src={el.data} alt={el.alt} className='w-full h-full' />
              </div>
            ))}

          {/* Popular investment option section */}
          <div className='h-auto w-full bg-white p-7 border border-[#D3DBEC] rounded-3xl'>
            <div className='flex justify-between items-center'>
              <h1 className='text-2xl font-semibold -tracking-[0.96px] text-black-500'>
                Popular Investment Options
              </h1>

              <button
                className='flex items-center border-b-2 border-dashed border-primary-500 cursor-pointer'
                onClick={() => {
                  setfilterInvestment(
                    filterInvestment === '1y' ? '2y' : filterInvestment === '2y' ? '3y' : '1y',
                  )

                  clevertap.event.push('Clicked Filter', {
                    Page_name: 'Dashboard',
                  })
                }}
              >
                {filterInvestment}
                <img src={updown} alt='updown' className='h-5' />
              </button>
            </div>

            <div className='flex flex-col gap-y-[24px] mt-7'>
              {filteredInvestments.length > 0 ? (
                filteredInvestments.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <div
                      className={`flex flex-1 gap-x-7 ${
                        index !== filterInvestment.length && 'pb-[24px]'
                      }`}
                    >
                      <div className='rounded-lg'>
                        <img
                          className='w-[54px] h-[54px] lg:w-[72px] lg:h-[72px] rounded-[8px] min-w-[54px] lg:min-w-[72px]'
                          src={iconsList['light' + item.id]}
                          alt={item.name}
                        />
                      </div>
                      <div className='w-full flex flex-wrap gap-4 justify-between items-center'>
                        <div>
                          <h1 className='font-semibold text-xl text-black-500 -tracking-[0.8px]'>
                            {item.name}
                          </h1>
                          <div className='flex gap-12 mt-[8px]'>
                            <span className='w-[128px]'>
                              <p className='text-black-300 font-medium text-[14px] kerning-[20px]'>
                                Start at
                              </p>
                              <p className='font-semibold text-black-500'>
                                &#8377; {item.min_investment}
                              </p>
                            </span>
                            <span>
                              <p className='text-black-300 font-medium text-[14px] kerning-[20px]'>
                                {getCagrValue(item?.cagrDetails)?.cagrCode} Returns
                              </p>

                              <p className='font-semibold text-black-500'>
                                {toCurrency(getCagrValue(item?.cagrDetails)?.cagrValue)}%
                              </p>
                            </span>
                          </div>
                        </div>

                        <button
                          className='text-primary-500 border border-[#D3DBEC] font-medium rounded-[8px] py-[10px] px-[24px] shadow-[0_4px_4px_0_rgba(65,85,141,0.04)] hover:shadow-[0_4px_6px_0_rgba(65,85,141,0.08)] transition-shadow'
                          onClick={() => {
                            navigateToThemeDetails(item)

                            clevertap.event.push('Clicked Plan', {
                              Plan_Name: item,
                              Page_name: 'Dashboard',
                            })
                          }}
                        >
                          Learn more
                        </button>
                      </div>
                    </div>
                    {index - 1 !== filterInvestment.length && <Separator />}
                  </React.Fragment>
                ))
              ) : (
                <p className='text-center'>No investment options available</p>
              )}
            </div>

            <div className='w-full flex justify-center items-center p-2 border-y-2 border-dashed border-[#C4CADC] mt-6'>
              <button
                className='text-primary-500 flex font-semibold'
                onClick={() => {
                  navigate('/dashboard/theme')
                }}
              >
                View all {investments.length} funds
                <MoveRight className='ml-2' />
              </button>
            </div>
          </div>

          {/* Understand your money carousel */}
          <div className='p-8 border rounded-3xl bg-white'>
            <h3 className='text-2xl font-semibold -tracking-[0.8px] mb-8 px-2'>
              Understand your Money
            </h3>
            <PlansCarousel contentCarouselData={contentCarouselData} />
          </div>
        </div>

        {/* Right side ad section */}
        <div
          className={`${
            displayContentData.length > 0 && getDisplayContentBox(1).length > 0
              ? 'w-4/12'
              : 'hidden'
          }`}
        >
          {displayContentData.length > 0 &&
            getDisplayContentBox(1).map((el) => (
              <div
                key={el.idx}
                onClick={() =>
                  navigate(el.link, {
                    state: {
                      selectedItem: { id: el.id },
                    },
                  })
                }
                className='w-full h-full max-h-[176px] cursor-pointer'
              >
                <img src={el.data} alt={el.alt} className='w-full h-auto' />
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default Home
