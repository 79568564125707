import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

// A custom theme for this app
const darkTheme = createTheme({
  font: {
    fontFamily: 'DM Sans',
  },
  palette: {
    type: 'dark',
    background: {
      default: '#000',
    },
    color: {
      default: '#fff',
    },
    primary: {
      main: '#4F9B7E',
      text: '#525271',
      textColor: '#A7A7B2',
    },
    secondary: {
      main: '#242424',
      text: '#fff',
    },
    tertiary: {
      main: '#3F3F3F',
      text: '#fff',
    },
    error: {
      main: red.A400,
    },
    action: {
      disabledBackground: '#4F9B7E',
      disabled: '#525271',
    },
    typography: {
      allVariants: {
        color: '#fff',
      },
    },
  },
  components: {
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            color: '#fff',
            fontFamily: 'DM Sans',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          input: {
            '&:-webkit-autofill': {
              '-webkit-text-fill-color': '#fff',
              fontFamily: 'DM Sans',
            },
            '&.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
              WebkitTextFillColor: 'rgba(255, 255, 255, 0.60)',
              fontFamily: 'DM Sans',
            },
          },
          '&.MuiSelect-root': {
            backgroundColor: '#000',
            color: '#fff',
            fontFamily: 'DM Sans',
          },
          color: '#fff',
          borderColor: '#4C4C4C',
        },
        notchedOutline: {
          borderColor: '#4C4C4C',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&.MuiSelect-root': {
            backgroundColor: '#000',
            fontFamily: 'DM Sans',
            color: '#fff',
            '&.Mui-disabled': {
              color: 'rgba(255, 255, 255, 0.38)',
            },
          },
          '&.Mui-disabled': {
            color: 'rgba(255, 255, 255, 0.38) !important',
          },
          '&:before': {
            borderColor: '#4C4C4C',
          },
          '&:hover&:before': {
            borderColor: '#4C4C4C',
          },
          color: '#fff',
          borderBottomColor: '#4C4C4C',
        },
        input: {
          color: '#fff',
          height: '30px',
          fontFamily: 'DM Sans',
          // fontSize: '20px',
          borderBottomColor: '#4C4C4C',
          '&.Mui-disabled': {
            color: 'rgba(255, 255, 255, 0.38)',
            WebkitTextFillColor: 'rgba(255, 255, 255, 0.38)',
          },
          '&:-webkit-autofill': {
            '-webkit-text-fill-color': '#fff',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: '#fff',
          borderBottomColor: '#4C4C4C',
          '&:before': {
            borderColor: '#4C4C4C',
          },
          '&:hover&:before': {
            borderColor: '#4C4C4C',
          },
        },
        input: {
          color: '#fff',
          borderBottomColor: '#4C4C4C',
          '&:-webkit-autofill': {
            '-webkit-text-fill-color': '#fff',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#fff',
          fontSize: '17px',
          fontFamily: 'DM Sans',

          '&.Mui-disabled': {
            color: 'rgba(255, 255, 255, 0.38)',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-track': {
            backgroundColor: '#fff !important',
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: '#000',
          },
          '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: '#4F9B7E !important',
          },
          '& .Mui-checked .MuiSwitch-thumb': {
            backgroundColor: '#fff',
          },
          '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track': {
            opacity: 0.12,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 'none',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.MuiSelect-icon': {
            color: '#fff !important',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.Mui-disabled.MuiTab-root': {
            color: 'rgba(255, 255, 255, 0.38) !important',
          },
        },
      },
    },
    // mui theme for responsiveness
  },
})

const lightTheme = createTheme({
  palette: {
    type: 'light',
    background: {
      default: '#F4F7FE',
      primary: '#F4F7FE',
    },
    border: {
      primary: '1px solid #D3DBEC',
    },
    borderRadius: {
      lg: '33px',
    },

    color: {
      default: '#000',
      upGreen: '#74A766',
      downRed: '#E85D5D',
    },
    primary: {
      main: '#41558D',
      text: '#525271',
      textColor: '#242424',
    },
    secondary: {
      main: '#fff',
      text: '#000',
    },
    tertiary: {
      main: '#d1d2d2',
      text: '#000',
    },
    error: {
      main: red.A400,
    },
    action: {
      disabledBackground: '#808db3',
      disabled: '#FFFFFF',
    },
    typography: {
      allVariants: {
        color: '#000',
      },
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.MuiSelect-icon': {
            color: '#000 !important',
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            color: '#000',
            fontFamily: 'DM Sans',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          input: {
            '&:-webkit-autofill': {
              '-webkit-text-fill-color': '#000',
            },
            '&.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
              WebkitTextFillColor: 'rgba(0, 0, 0, 0.60)',
            },
          },
          '&.MuiSelect-root': {
            backgroundColor: '#fff',
            color: '#000',
          },
          color: '#000',
          borderColor: '#333',
        },
        notchedOutline: {
          borderColor: '#333',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&.MuiSelect-root': {
            backgroundColor: '#fff',
            color: '#000',
            fontFamily: 'DM Sans',
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.38)',
            },
          },
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.38) !important',
          },
          '&:before': {
            borderColor: '#333',
          },
          '&:hover&:before': {
            borderColor: '#333',
          },
          color: '#000',
          borderBottomColor: '#333',
        },
        input: {
          color: '#000',
          height: '30px',
          // fontSize: '20px',
          borderBottomColor: '#333',
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.38)',
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)',
          },
          '&:-webkit-autofill': {
            '-webkit-text-fill-color': '#000',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: '#000',
          borderBottomColor: '#333',
          '&:before': {
            borderColor: '#333',
          },
          '&:hover&:before': {
            borderColor: '#333',
          },
        },
        input: {
          color: '#000',
          borderBottomColor: '#333',
          '&:-webkit-autofill': {
            '-webkit-text-fill-color': '#000',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#000',
          fontSize: '17px',
          fontFamily: 'DM Sans',

          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.38)',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#333',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-track': {
            backgroundColor: '#000 !important',
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: '#fff',
          },
          '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: '#4F9B7E !important',
          },
          '& .Mui-checked .MuiSwitch-thumb': {
            backgroundColor: '#000',
          },
          '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track': {
            opacity: 0.12,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 'none',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.Mui-disabled.MuiTab-root': {
            color: 'rgba(0, 0, 0, 0.38) !important',
          },
        },
      },
    },
    // mui theme for responsiveness
  },
})

export { lightTheme, darkTheme }
