import React, { useEffect, useState } from 'react'
import { Alert, Box } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { post } from '../http/restservice'
import LoadingButton from '@mui/lab/LoadingButton'
import ReactGA4 from 'react-ga4'
import clevertap from 'clevertap-web-sdk'
import vector from '../assets/Auth/Vector.svg'
import vector1 from '../assets/Auth/Vector1.svg'

const LoginPage = () => {
  const navigate = useNavigate()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [partnerUser, setPartnerUser] = useState(false)
  const [severity, setSeverity] = useState('error')

  const handleChange = (e) => {
    setPhoneNumber(e.target.value.replace(/[^0-9]/g, ''))
  }

  useEffect(() => {
    const userMobile = localStorage.getItem('access_token')

    if (userMobile) {
      if (window.utmData && window.utmData['$marketing_title']) {
        if (window.utmData['$marketing_title'] === 'Insurance') {
          navigate('/dashboard/insurance')
        } else {
          let navState = null
          if (window.utmData.navigation_state) {
            navState = JSON.parse(window.utmData.navigation_state)
            console.log(navState)
          }
          if (window.utmData.site_url) {
            navigate(window.utmData.site_url, { state: navState })
          } else {
            navigate('/dashboard')
          }
        }
      } else {
        navigate('/dashboard')
      }
    } else {
      ReactGA4.event({
        category: 'Login',
        action: 'Login Viewed',
      })
      clevertap.event.push('Login Viewed')
    }
    if (location) {
      setMessage(location.state?.message)
      setSeverity(location.state?.severity)
    }
  }, [])

  useEffect(() => {
    clevertap.event.push('Page Viewed', {
      Page_name: 'Login Page',
    })

    document.getElementById('component-outlined').blur()
  }, [])

  const validateLogin = async (event) => {
    ReactGA4.event({
      category: 'Login',
      action: 'Login phone Entered',
    })
    clevertap.event.push('Login phone Entered')

    clevertap.event.push('Login phone Entered', {
      Page_name: 'Login',
      Phone_number: phoneNumber,
    })
    event.preventDefault()
    const payload = {
      phoneNumber,
    }
    try {
      const response = await post('/customerservice/api/customer/validate', payload)
      setLoading(false)
      if (response.isError) {
        return
      }
      if (response) {
        if (response.isPartnerUser) {
          setMessage(response.message)
          setPartnerUser(true)
          // window.open(response.redirectUrl, '_blank')
          return
        } else if (response.isUserActive !== undefined && !response.isUserActive) {
          navigate('/activate-account-otp', {
            state: {
              phoneNumber,
              message: 'Account is not activated. please activate the account and try log in',
            },
          })
        } else if (!response.isDematActive) {
          navigate('/otp', {
            state: {
              phoneNumber,
            },
          })
        } else if (response.isDematActive) {
          navigate('/password', {
            state: {
              phoneNumber,
            },
          })
        }
      } else {
        // setMessage('No account found for this phone number. Please sign up and continue')
        navigate('/sign-up', {
          state: {
            phoneNumber,
            // message: 'No account found for this phone number. Please sign up and continue',
          },
        })
      }
    } catch (e) {
      setLoading(false)
      return
    }
  }

  return (
    <div className='lg:border lg:bg-white lg:rounded-3xl lg:min-w-[432px] lg:mx-auto lg:py-12 lg:px-[72px] flex flex-col justify-center items-center lg:relative'>
      <div className='flex flex-col gap-y-1 mb-6'>
        <h1 className='font-DMSans text-primary-900 text-center text-[28px] lg:text-[36px] kerning-[36px] -tracking-[1.12px] font-bold'>
          Login
        </h1>
        <p className='font-DMSans text-center text-black-400 font-medium text-[14px] kerning-[20px] -tracking-[0.56px]'>
          Welcome back!
        </p>
      </div>
      <div className='w-full'>
        <Grid item xs={12}>
          {!partnerUser && message && <Alert severity={severity}>{message}</Alert>}
          {partnerUser && message && (
            <Alert severity={severity}>
              This account already exists on our partner platform. Please continue on{' '}
              <a href='https://wealthelite.in/'> RedVision’s wealth lite platform. </a>
            </Alert>
          )}
        </Grid>
        <Grid item>
          <form onSubmit={validateLogin} className='flex flex-col font-DMSans h-min'>
            <label
              htmlFor='component-outlined'
              className='required font-medium text-[12px] kerning-[16px] text-black-500 mb-1'
            >
              Mobile number
            </label>
            <input
              type='number'
              id='component-outlined'
              placeholder='Enter your number'
              required
              maxLength='10'
              inputMode='numeric'
              value={phoneNumber}
              onChange={handleChange}
              onWheel={() => document.activeElement.blur()}
              onBlur={() => {
                setPartnerUser(false)
              }}
              className='flex w-full py-2 pl-3 pr-4 rounded-[8px] border border-[#D3dBEC] bg-white font-DMSans  focus:border-primary-300 shadow-[0_1.5px_4px_0_rgba(65,85,141,0.08)] outline-none transition-all'
            />

            <LoadingButton
              variant='contained'
              loading={loading}
              // loadingPosition='end'
              sx={{
                mt: '24px',
                py: '12px',
                px: '24px',
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: '500',
                fontFamily: 'DM Sans',
                textTransform: 'none',
                borderRadius: { xs: '12px', md: '8px' },
                boxShadow: 'none',
                '&:hover': { backgroundColor: '#3b4d80', boxShadow: 'none' },
                '&:active': { backgroundColor: '#3b4d80', boxShadow: 'none' },
              }}
              className='login-btn'
              fullWidth
              disabled={!(phoneNumber?.length === 10) || partnerUser}
              onClick={(event) => {
                setLoading(true)
                validateLogin(event)
              }}
            >
              Continue
            </LoadingButton>
          </form>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Box className='flex lg:flex-col gap-x-2 justify-center items-center mt-6'>
            <p className='font-medium text-[14px] kerning-[20px] text-black-400'>
              Don&apos;t have an account?
            </p>
            <button
              onClick={() => {
                clevertap.event.push('Clicked Button', {
                  Action: 'Sign Up',
                  Page_name: 'Login',
                })
                navigate('/sign-up', {
                  state: {
                    phoneNumber,
                  },
                })
              }}
            >
              <span className='border-b-2 border-primary-500 border-dashed text-primary-500 text-[14px] kerning-[20px] font-semibold'>
                Get started for free!
              </span>
            </button>
          </Box>
        </Grid>
      </div>

      <img
        className='hidden lg:block absolute top-[5%] left-[-30%] -z-[1]'
        src={vector}
        alt='Vector 1'
      />

      <img
        className='hidden lg:block absolute bottom-[-15%] right-[-12%] -z-[1]'
        src={vector1}
        alt='Vector 2'
      />
    </div>
  )
}

export default LoginPage
