/* eslint-disable*/

import { Alert, Button, Grid, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { loginWithDematOTP, resendOtp, getUserDetails } from '../utils/AuthUtil'
import LoadingButton from '@mui/lab/LoadingButton'
import ReactGA4 from 'react-ga4'
import clevertap from 'clevertap-web-sdk'
import { InputOTP, InputOTPGroup, InputOTPSlot } from '../Components/ui/input-otp'
import { REGEXP_ONLY_DIGITS } from 'input-otp'
import vector from '../assets/Auth/Vector.svg'
import vector1 from '../assets/Auth/Vector1.svg'
import LoginLock from '../Components/Auth/LoginLock'

const LoginWithOTP = () => {
  const [errorMessage, setErrorMessage] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()

  const [userId, setUserId] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loading, setLoading] = useState(false)
  const [counter, setCounter] = useState(45)
  const [firstName, setFirstName] = useState('')
  const [otp, setOtp] = useState(null)
  const [error, setError] = useState(null)
  const [isLoginSuccessfull, setIsLoginSuccessfull] = useState(false)

  useEffect(() => {
    setUserId(location.state?.uid)
    setPhoneNumber(location.state?.phoneNumber)
    setFirstName(location.state?.firstName)
  }, [])

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  const handleChange = (value) => {
    setOtp(value)
  }

  const resendOTP = async () => {
    console.log('calling resend otp')
    setOtp(null)
    setErrorMessage(null)
    try {
      const payload = {
        uid: userId,
        requestCode: '115',
      }

      const resendOtpResponse = await resendOtp(payload)
      setLoading(false)
      console.log(resendOtpResponse)
      if (resendOtpResponse.isError) {
        if (resendOtpResponse.code === 'Unauthorized') {
          setErrorMessage(resendOtpResponse.message.message)
        } else {
          setErrorMessage('Unable to process your request.Please try again')
        }
        return
      }
      setCounter(45)
    } catch (e) {
      setLoading(false)
      return
    }
  }

  const otpLogin = async (event) => {
    event.preventDefault()

    try {
      setErrorMessage(null)
      const payload = {
        uid: userId,
        otp: otp,
        phoneNumber,
      }
      console.log(payload)
      const response = await loginWithDematOTP(payload)
      console.log('Login OTP Entered')
      ReactGA4.event({
        category: 'Login',
        action: 'Login OTP Entered',
      })
      clevertap.event.push('Login OTP Entered')
      setLoading(false)
      console.log(response)

      if (response.code === 'Success') {
        setIsLoginSuccessfull(true)
      }

      if (response.isError) {
        if (response.code === 'Unauthorized') {
          setErrorMessage('You entered an incorrect OTP!')
        } else {
          setErrorMessage('Unable to process your request.Please try again')
        }
        ReactGA4.event({
          category: 'Login',
          action: 'OTP Failure',
        })
        clevertap.event.push('OTP Failure')
        return
      }
      localStorage.setItem('uid', userId)
      const userDetails = getUserDetails()
      console.log('GA event', userDetails)
      console.log('Login Complete')
      // Send logged in user details to GA4 and clevertap
      ReactGA4.event({
        category: 'Login',
        action: 'Login Complete',
        value: userDetails.email,
      })
      ReactGA4.set({ userId: userDetails.email })
      clevertap.onUserLogin.push({
        Site: {
          Name: userDetails.firstName + ' ' + userDetails.lastName,
          Identity: userDetails.uid,
          Email: userDetails.email,
          Phone: userDetails.phoneNumber,
          'MSG-email': false, // Enable email notifications
          'MSG-push': false, // Enable push notifications
          'MSG-sms': false, // Enable sms notifications
          'MSG-whatsapp': false, // Enable whatsapp notifications
        },
      })
      clevertap.event.push('Login Complete')
      if (window.utmData && window.utmData['$marketing_title']) {
        if (window.utmData['$marketing_title'] === 'Insurance') {
          navigate('/dashboard/insurance')
        } else {
          let navState = null
          if (window.utmData.navigation_state) {
            navState = JSON.parse(window.utmData.navigation_state)
            console.log(navState)
          }
          if (window.utmData.site_url) {
            setIsLoginSuccessfull(false)
            navigate(window.utmData.site_url, { state: navState })
          } else {
            setTimeout(() => {
              setIsLoginSuccessfull(false)
              navigate('/dashboard', { state: { login: true } })
            }, 1000)
          }
        }
      } else {
        setTimeout(() => {
          setIsLoginSuccessfull(false)
          navigate('/dashboard', { state: { login: true } })
        }, 1000)
      }
    } catch (e) {
      setIsLoginSuccessfull(false)
      setLoading(false)
      return
    }
  }

  if (isLoginSuccessfull) {
    return <LoginLock firstName={firstName} />
  }

  return (
    <div className='lg:border lg:bg-white lg:rounded-3xl lg:min-w-[432px] lg:mx-auto lg:py-12 lg:px-[72px] flex flex-col justify-center items-center lg:relative'>
      <div className='flex flex-col gap-y-1 mb-6'>
        <h1 className='font-DMSans text-primary-900 text-center text-[28px] kerning-[36px] -tracking-[1.12px] font-semibold'>
          Login
        </h1>
        <p className='font-DMSans text-center text-black-400 font-medium text-[14px] kerning-[20px] -tracking-[0.56px] flex items-center justify-center gap-x-1'>
          <svg
            width='14'
            height='14'
            viewBox='0 0 12 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1.99996 14.614C1.63329 14.614 1.3194 14.4835 1.05829 14.2223C0.797182 13.9612 0.666626 13.6473 0.666626 13.2807V6.61401C0.666626 6.24735 0.797182 5.93346 1.05829 5.67235C1.3194 5.41124 1.63329 5.28068 1.99996 5.28068H2.66663V3.94735C2.66663 3.02512 2.99163 2.23901 3.64163 1.58901C4.29163 0.939014 5.07774 0.614014 5.99996 0.614014C6.92218 0.614014 7.70829 0.939014 8.35829 1.58901C9.00829 2.23901 9.33329 3.02512 9.33329 3.94735V5.28068H9.99996C10.3666 5.28068 10.6805 5.41124 10.9416 5.67235C11.2027 5.93346 11.3333 6.24735 11.3333 6.61401V13.2807C11.3333 13.6473 11.2027 13.9612 10.9416 14.2223C10.6805 14.4835 10.3666 14.614 9.99996 14.614H1.99996ZM5.99996 11.2807C6.36663 11.2807 6.68052 11.1501 6.94163 10.889C7.20274 10.6279 7.33329 10.314 7.33329 9.94735C7.33329 9.58068 7.20274 9.26679 6.94163 9.00568C6.68052 8.74457 6.36663 8.61401 5.99996 8.61401C5.63329 8.61401 5.3194 8.74457 5.05829 9.00568C4.79718 9.26679 4.66663 9.58068 4.66663 9.94735C4.66663 10.314 4.79718 10.6279 5.05829 10.889C5.3194 11.1501 5.63329 11.2807 5.99996 11.2807ZM3.99996 5.28068H7.99996V3.94735C7.99996 3.39179 7.80552 2.91957 7.41663 2.53068C7.02774 2.14179 6.55551 1.94735 5.99996 1.94735C5.4444 1.94735 4.97218 2.14179 4.58329 2.53068C4.1944 2.91957 3.99996 3.39179 3.99996 3.94735V5.28068Z'
              fill='#575757'
            />
          </svg>
          Two-factor authentication enabled
        </p>
      </div>
      <div className='flex flex-col justify-center items-center'>
        <p className='flex flex-row whitespace-nowrap font-DMSans text-[14px] kerning-[20px] -tracking-[0.56px] font-medium text-black-400'>
          Enter the OTP sent to
        </p>
        <div
          className='flex justify-center items-center gap-x-1 border-primary-500 border-dashed w-min border-b-[2px] mb-6 cursor-pointer'
          onClick={() => navigate('/login')}
        >
          <p className='text-primary-500 font-semibold font-DMSans whitespace-nowrap text-decoration-none'>
            +91 {phoneNumber}
          </p>
          <span className='material-symbols-rounded icon-filled-400 text-primary-500 text-[16px]'>
            stylus
          </span>
        </div>
      </div>
      <div className='mb-12'>
        {counter == 0 ? (
          <button
            onClick={() => {
              clevertap.event.push('Clicked Button', {
                Action: 'Resend OTP',
                Page_name: 'Demat Login',
              })
              setLoading(true)
              resendOTP()
            }}
            className='flex flex-row items-center justify-center cursor-pointer whitespace-nowrap font-DMSans text-[14px] kerning-[20px] font-semibold text-primary-500 gap-x-1 border-b-[2px] border-dashed border-primary-500'
          >
            <span className='material-symbols-rounded icon-filled-400 text-[20px] text-primary-500'>
              cached
            </span>
            Resend OTP
          </button>
        ) : (
          <div className='flex flex-row justify-center items-center gap-x-1 border-b-[2px] border-transparent'>
            <span className='material-symbols-rounded icon-filled-400 text-[20px] text-black-400'>
              cached
            </span>
            <p className='flex flex-row whitespace-nowrap font-DMSans text-[14px] kerning-[20px] font-medium text-black-400'>
              {' '}
              Resend OTP in <p className='font-semibold text-black-500'>&nbsp;{counter} sec</p>
            </p>
          </div>
        )}
      </div>

      <Grid item xs={12}>
        <form onSubmit={otpLogin}>
          <InputOTP
            maxLength={6}
            pattern={REGEXP_ONLY_DIGITS}
            value={otp}
            onChange={(value) => handleChange(value)}
          >
            <InputOTPGroup>
              <InputOTPSlot
                className={`${
                  errorMessage && 'border-error-600 text-error-600 font-medium'
                } border-[#D3DBEC] text-[16px] kerning-[24px] py-3 px-4 !h-[48px]`}
                index={0}
              />
              <InputOTPSlot
                className={`${
                  errorMessage && 'border-error-600 text-error-600 font-medium'
                } border-[#D3DBEC] text-[16px] kerning-[24px] py-3 px-4 !h-[48px]`}
                index={1}
              />
              <InputOTPSlot
                className={`${
                  errorMessage && 'border-error-600 text-error-600 font-medium'
                } border-[#D3DBEC] text-[16px] kerning-[24px] py-3 px-4 !h-[48px]`}
                index={2}
              />
              <InputOTPSlot
                className={`${
                  errorMessage && 'border-error-600 text-error-600 font-medium'
                } border-[#D3DBEC] text-[16px] kerning-[24px] py-3 px-4 !h-[48px]`}
                index={3}
              />
              <InputOTPSlot
                className={`${
                  errorMessage && 'border-error-600 text-error-600 font-medium'
                } border-[#D3DBEC] text-[16px] kerning-[24px] py-3 px-4 !h-[48px]`}
                index={4}
              />
              <InputOTPSlot
                className={`${
                  errorMessage && 'border-error-600 text-error-600 font-medium'
                } border-[#D3DBEC] text-[16px] kerning-[24px] py-3 px-4 !h-[48px]`}
                index={5}
              />
            </InputOTPGroup>
          </InputOTP>

          <div className='text-center text-xs text-error-600 font-medium mt-2'>
            {errorMessage && <>{errorMessage}</>}
          </div>

          <LoadingButton
            variant='contained'
            loading={loading}
            sx={{
              mt: '24px',
              py: '12px',
              px: '24px',
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: '500',
              fontFamily: 'DM Sans',
              textTransform: 'none',
              borderRadius: { xs: '12px', md: '8px' },
              boxShadow: 'none',
              '&:hover': { backgroundColor: '#3b4d80', boxShadow: 'none' },
              '&:active': { backgroundColor: '#3b4d80', boxShadow: 'none' },
            }}
            fullWidth
            disabled={otp === null || otp.length != 6}
            onClick={(event) => {
              setLoading(true)
              otpLogin(event)
            }}
          >
            Verify OTP
          </LoadingButton>
        </form>
      </Grid>
      {/* <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Button
          sx={{
            textTransform: 'none',
          }}
          onClick={() => {
            navigate('/password', {
              state: {
                phoneNumber,
              },
            })
          }}
        >
          Back
        </Button>
      </Grid> */}

      <img
        className='hidden lg:block absolute top-[5%] left-[-20%] -z-[1]'
        src={vector}
        alt='Vector 1'
      />

      <img
        className='hidden lg:block absolute bottom-[10%] right-[-15%] -z-[1]'
        src={vector1}
        alt='Vector 2'
      />
    </div>
  )
}

export default LoginWithOTP
