const { Navigate } = require('react-router-dom')
const { isDematUser, getUserDetails } = require('../utils/AuthUtil')

const DematUser = ({ children }) => {
  return isDematUser() ? children : <Navigate to='/dashboard' />
}

const PrivateRoute = ({ children }) => {
  const userDetails = getUserDetails()
  return userDetails ? children : <Navigate to='/login' />
}

export { PrivateRoute, DematUser }
