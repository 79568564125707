/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Button, InputAdornment, TextField } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { activateAccount as activateUser, getUserDetails } from '../utils/AuthUtil'
import LoadingButton from '@mui/lab/LoadingButton'
import ReactGA4 from 'react-ga4'
import clevertap from 'clevertap-web-sdk'
import { InputOTP, InputOTPGroup, InputOTPSlot } from '../Components/ui/input-otp'
import { REGEXP_ONLY_DIGITS } from 'input-otp'
import vector from '../assets/Auth/Vector.svg'
import vector1 from '../assets/Auth/Vector1.svg'

const ActivateAccountOTP = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [errorMessage, setErrorMessage] = useState(null)
  const [otp, setOtp] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (location) {
      setPhoneNumber(
        typeof location.state?.phoneNumber === 'number'
          ? location.state?.phoneNumber.toString()
          : location.state?.phoneNumber,
      )
      setMessage(location.state?.message)
    }
  }, [])

  useEffect(() => {
    clevertap.event.push('Page Viewed', {
      Page_name: 'ActivateAaccountOTP Page',
    })
  }, [])

  const handleChange = (value) => {
    setOtp(value)
  }

  const activateAccount = async (event) => {
    event.preventDefault()
    setErrorMessage(null)
    const payload = {
      phoneNumber,
      otp,
    }
    try {
      const response = await activateUser(payload)
      ReactGA4.event({
        category: 'Login',
        action: 'Login OTP Entered',
      })
      clevertap.event.push('Login OTP Entered')
      setLoading(false)

      console.log(response)
      if (response.isError) {
        if (response.code === 'Unauthorized') {
          setErrorMessage('Enter Valid OTP.')
        } else {
          setErrorMessage('You entered an incorrect OTP!')
        }
        ReactGA4.event({
          category: 'Login',
          action: 'Login OTP Failure',
        })
        clevertap.event.push('Login OTP Failure')
        clevertap.event.push('Sign Up OTP - Invalid OTP')
        console.log(fbq, 'is fbq')
        fbq('track', 'Sign Up OTP - Invalid OTP')
        ReactGA4.event({
          category: 'View',
          action: 'Sign Up OTP - Invalid OTP',
          value: 'Sign Up OTP - Invalid OTP',
        })
        var custom_data = {
          Custom_Event_Property_Key1: 'Sign Up OTP - Invalid OTP',
        }

        branch.logEvent('Sign Up OTP - Invalid OTP', custom_data, function (err) {
          console.log(err)
        })

        return
      }

      const userDetails = getUserDetails()
      console.log('GA event', userDetails.email)
      ReactGA4.event({
        category: 'Login',
        action: 'Login Complete',
        value: userDetails.email,
      })

      clevertap.event.push('Login Complete')
      clevertap.event.push('Sign Up OTP - First Login')
      fbq('track', 'Sign Up OTP - First Login')
      ReactGA4.event({
        category: 'View',
        action: 'Sign Up OTP - First Login',
        value: 'Sign Up OTP - First Login',
      })
      // eslint-disable-next-line no-redeclare
      var custom_data = {
        Custom_Event_Property_Key1: 'Sign Up OTP - First Login',
      }

      branch.logEvent('Sign Up OTP - First Login', custom_data, function (err) {
        console.log(err)
      })

      if (window.utmData && window.utmData['$marketing_title']) {
        if (window.utmData['$marketing_title'] === 'Insurance') {
          navigate('/dashboard/insurance')
        } else {
          let navState = null
          if (window.utmData.navigation_state) {
            navState = JSON.parse(window.utmData.navigation_state)
            console.log(navState)
          }
          if (window.utmData.site_url) {
            navigate(window.utmData.site_url, { state: navState })
          } else {
            navigate('/dashboard', { state: { login: true } })
          }
        }
      } else {
        navigate('/dashboard', { state: { login: true } })
      }
    } catch (e) {
      setLoading(false)
      return
    }
  }

  return (
    <div className='lg:border lg:bg-white lg:rounded-3xl lg:min-w-[432px] lg:mx-auto lg:py-12 lg:px-[72px] flex flex-col justify-center items-center lg:relative'>
      {message && <span>{message}</span>}
      <div className='mb-6'>
        <h1 className='text-primary-900 text-center text-[32px] font-bold'>Sign Up</h1>
        <p className='text-center text-black-400'>Get started in few easy steps</p>
      </div>

      <div className='w-full mb-12 flex flex-col justify-center items-center'>
        <p className='text-center text-sm text-black-400 font-semibold'>Enter the OTP sent to</p>
        <span
          className='text-center text-sm text-primary-500 font-semibold border-b-2 border-dashed border-primary-500 flex justify-center items-center gap-x-2'
          onClick={() => navigate('/login')}
        >
          +91-{phoneNumber}
          <span className='material-symbols-rounded'>stylus</span>
        </span>
      </div>

      <Grid item xs={12} sx={{ mb: 2 }}>
        <form onSubmit={activateAccount}>
          <InputOTP
            maxLength={6}
            pattern={REGEXP_ONLY_DIGITS}
            value={otp}
            onChange={(value) => handleChange(value)}
          >
            <InputOTPGroup>
              <InputOTPSlot className={`${errorMessage && 'border-red-500'}`} index={0} />
              <InputOTPSlot className={`${errorMessage && 'border-red-500'}`} index={1} />
              <InputOTPSlot className={`${errorMessage && 'border-red-500'}`} index={2} />
              <InputOTPSlot className={`${errorMessage && 'border-red-500'}`} index={3} />
              <InputOTPSlot className={`${errorMessage && 'border-red-500'}`} index={4} />
              <InputOTPSlot className={`${errorMessage && 'border-red-500'}`} index={5} />
            </InputOTPGroup>
          </InputOTP>

          <div className='text-center text-xs text-red-600 font-medium mt-2'>
            {errorMessage && <>{errorMessage}</>}
          </div>
          {/* <div>
            <TextField
              required
              id='component-outlined'
              type='text'
              onChange={(e) => setOtp(e.target.value)}
              fullWidth
              value={otp}
              inputProps={{
                maxLength: 6,
                inputMode: 'numeric',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <div
                      aria-label='toggle password visibility'
                      onClick={() => {
                        clevertap.event.push('Sign Up OTP - Resend OTP Clicked')
                        fbq('track', 'Sign Up OTP - Resend OTP Clicked')  
                        ReactGA4.event({
                          category: 'View',
                          action: 'Sign Up OTP - Resend OTP Clicked',
                          value: 'Sign Up OTP - Resend OTP Clicked',
                        })
                        var custom_data = {
                          Custom_Event_Property_Key1: 'Sign Up OTP - Resend OTP Clicked',
                        }

                        branch.logEvent(
                          'Sign Up OTP - Resend OTP Clicked',
                          custom_data,
                          function (err) {
                            console.log(err)
                          },
                        )
                      }}
                      onMouseDown={() => {}}
                      className='resend-otp cursor-pointer'
                    >
                      Resend OTP
                    </div>
                  </InputAdornment>
                ),
              }}
              variant='standard'
              onBlur={() => {
                clevertap.event.push('Sign Up OTP - OTP Entered')
                fbq('track', 'Sign Up OTP - OTP Entered')
                ReactGA4.event({
                  category: 'View',
                  action: 'Sign Up OTP - OTP Entered',
                  value: 'Sign Up OTP - OTP Entered',
                })
                var custom_data = {
                  Custom_Event_Property_Key1: 'Sign Up OTP - OTP Entered',
                }

                branch.logEvent('Sign Up OTP - OTP Entered', custom_data, function (err) {
                  console.log(err)
                })
              }}
            />
          </div> */}
          <LoadingButton
            variant='contained'
            loading={loading}
            // loadingPosition='end'
            sx={{
              py: '10px',
              textTransform: 'none',
              borderRadius: '9px',
              marginTop: '48px',
            }}
            disabled={!(otp?.length === 6)}
            fullWidth
            onClick={(event) => {
              setLoading(true)
              activateAccount(event)
            }}
          >
            Verify OTP
          </LoadingButton>
        </form>
      </Grid>

      <img
        className='hidden lg:block absolute top-[5%] left-[-20%] -z-[1]'
        src={vector}
        alt='Vector 1'
      />

      <img
        className='hidden lg:block absolute bottom-[10%] right-[-15%] -z-[1]'
        src={vector1}
        alt='Vector 2'
      />
    </div>
  )
}

export default ActivateAccountOTP
