import { useNavigate } from 'react-router-dom'
import { cards } from '../../constants/kycNoInvestmentCards'
import useMediaQuery from '../../hooks/useMediaQuery'

const KycNoInvestment = ({ userDetails, box }) => {
  const navigate = useNavigate()
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  return (
    <>
      {isDesktop ? (
        <>
          <div className='mb-4'>
            <h1 className='capitalize text-2xl text-black-500 font-semibold'>
              Welcome, {userDetails?.firstName} {userDetails?.lastName}
            </h1>
            <p className='font-medium text-black-400'>Start by exploring top investment options</p>
          </div>
          <div className='flex justify-between items-center px-6'>
            {cards.map((card) => (
              <div
                key={card.id}
                className='flex flex-col gap-y-1 justify-center items-center cursor-pointer'
                onClick={() => navigate(card.link)}
              >
                <div className='border rounded-full p-4 flex justify-center items-center bg-primary-50 relative'>
                  <img src={card.icon} alt={card.title} className='h-7 w-7' />
                  {card.tag === 'NEW' && (
                    <span className='h-3 w-3 rounded-full bg-secondary-600 absolute top-[2px] right-[2px]' />
                  )}
                </div>
                <p className='text-sm font-medium text-primary-500'>{card.title}</p>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className=' bg-primary-600 px-4 pt-5 pb-10'>
            <h1 className='capitalize text-2xl text-white font-semibold'>
              Welcome, {userDetails?.firstName} {userDetails?.lastName}
            </h1>
            <p className='font-medium text-primary-50'>Start by exploring top investment options</p>
          </div>

          <div
            onClick={() =>
              navigate(box?.link, {
                state: {
                  selectedItem: { id: box?.id },
                },
              })
            }
            className='w-full h-full cursor-pointer -mt-5 rounded-t-[24px] p-4 bg-[#F4F7FE]'
          >
            <img src={box?.data} alt={box?.alt} className='w-full' />
          </div>

          <div className='flex justify-between items-center px-6 mt-4 bg-[#F4F7FE]'>
            {cards.slice(0, 4).map((card) => (
              <div
                key={card.id}
                className='flex flex-col gap-y-1 justify-center items-center cursor-pointer'
                onClick={() => navigate(card.link)}
              >
                <div className='border rounded-full p-4 flex justify-center items-center bg-primary-50 relative'>
                  <img src={card.icon} alt={card.title} className='h-7 w-7' />
                  {card.tag === 'NEW' && (
                    <span className='h-3 w-3 rounded-full bg-secondary-600 absolute top-[2px] right-[2px]' />
                  )}
                </div>
                <p className='text-sm font-medium text-primary-500'>{card.title}</p>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default KycNoInvestment
