/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { Grid, Box, Typography, Menu, MenuItem, IconButton, useTheme, Switch } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import '../stylesheet.scss'
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil'
import MenuIcon from '@mui/icons-material/Menu'
import { useThemeMode } from '../Provider/ThemeContextProvider'
import { userDetailsState } from '../Pages/LoginState'
import { getUserDetails, isDematUser, removeToken } from '../utils/AuthUtil'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import reallogo from '../assets/Icons/reallogo.png'
import darkLogo from '../assets/DarkThemeFabLogo.png'
import env from '../env/env'
import { NotificationsSelector } from '../store/AccountDetailsStore'
import { Notifications } from './Notifications'
import { patch, post } from '../http/restservice'
import { navbarData, navbarDataNonDemat, navbarTitles, navTitles } from '../constants/navbar'
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from './ui/sheet'
import { Button } from './ui/button'
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarTrigger } from './ui/menubar'
import KYC from '../assets/Navbar/kyc.svg'
import avatar from '../assets/Navbar/Group 146.png'
import goals from '../assets/Navbar/goals.svg'
import useMediaQuery from '../hooks/useMediaQuery'
import { DrawerContext } from '../Provider/DrawerContext'
import { messageModalState } from '../store/Feedback'
import useLocalStorage from '../hooks/useLocalStorage'
import { NavbarContext } from '../Provider/ShowNavbar'

const Header = ({ handleOpenNav }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const location = useLocation()
  const { darkMode, toggleDarkMode } = useThemeMode()
  const [userDetails, setUserDetails] = useRecoilState(userDetailsState)
  const theme = useTheme()
  const { state, contents: notifications } = useRecoilValueLoadable(NotificationsSelector)
  const refreshNotifications = useRecoilRefresher_UNSTABLE(NotificationsSelector)
  const [notificationsVar, setNotificationsVar] = useState([])
  const [openMore, setOpenMore] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const { isDrawerOpen } = useContext(DrawerContext)
  const { setDisplayNavbar } = useContext(NavbarContext)
  const setMessageModalState = useSetRecoilState(messageModalState)
  const [showNavbar, setShowNavbar] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)

  useEffect(() => {
    setDisplayNavbar(showNavbar)
  }, [showNavbar])

  const handleScroll = () => {
    const currentScrollY = window.scrollY

    if (currentScrollY > lastScrollY && currentScrollY > 50) {
      setShowNavbar(false)
    } else {
      setShowNavbar(true)
    }

    setLastScrollY(currentScrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollY])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleLogout = () => {
    removeToken()
    navigate('/login')
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (state === 'hasValue' && notifications?.length) setNotificationsVar(notifications)
  }, [state, notifications])

  // const openHelpWidget = () => {
  //   console.log('open')
  //   const contact_form_token = localStorage.getItem('contact_form_token')
  //   if (!contact_form_token || (contact_form_token && isTokenExpired(contact_form_token))) {
  //     console.log('get contact form token')
  //     getContactFormJwtToken()
  //   } else {
  //     window.authenticate(contact_form_token)
  //   }
  //   window.openContactForm()
  // }

  // const getContactFormJwtToken = async () => {
  //   const payload = {
  //     name: userDetails?.firstName + ' ' + userDetails?.lastName,
  //     email: userDetails?.email,
  //     exp: Math.round(Date.now() / 1000) + 300,
  //   }
  //   try {
  //     const response = await post('customerservice/api/customer/getContactFormToken', payload)
  //     if (response.isError) {
  //       return
  //     }
  //     if (response) {
  //       localStorage.setItem('contact_form_token', response.token)
  //       window.authenticate(response.token)
  //     }
  //   } catch (e) {
  //     console.error('error', e)
  //   }
  // }

  useEffect(() => {
    const access_token = localStorage.getItem('access_token')
    if (!access_token) {
      navigate('/login')
      return
    }
    setUserDetails(getUserDetails())
    window.initContactForm()
    refreshNotifications()
  }, [])

  const logout = () => {
    removeToken()
    navigate('/login')
  }

  const refer = () => {
    navigate('/dashboard/refer')
  }

  const profileHandler = () => {
    handleClose()
    navigate('/dashboard/profile')
  }

  const handleMarkAsRead = async (notificationId) => {
    console.log(notificationId)
    try {
      const response = await patch(`customerservice/api/notification/${notificationId}/markAsRead`)
      if (!response.isError) {
        setNotificationsVar((prevState) => {
          return prevState.filter((notification) => notification.notificationId !== notificationId)
        })
      }
      console.log(response)
      // refreshNotifications()
    } catch (e) {
      console.log(e)
    }
  }

  const findNavTitle = navTitles.filter(
    (el) => el.linkPath === location.pathname || el.highlightUrls.includes(location.pathname),
  )

  return (
    <>
      <div className='sticky top-0 lg:hidden z-10 py-5 px-4 flex justify-between lg:justify-end items-center bg-white lg:bg-[#f4f7fe] lg:border-b-[0] border-b-[1px] border-b-[#D3DBEC] mb-0'>
        <div className='block lg:hidden'>
          <img
            src='/logo.svg'
            alt=''
            onClick={() => {
              clevertap.event.push('Clicked Button', {
                Action: 'Logo',
                Page_name: location.pathname,
              })
              navigate('/dashboard')
            }}
            className='cursor-pointer'
          />
        </div>

        <div className='flex lg:hidden justify-center items-center gap-x-4'>
          {!isDesktop && (
            <span
              id='web-inbox-placeholder'
              className={`material-symbols-rounded text-black-400 w-6 h-6`}
            >
              notifications
            </span>
          )}

          <div>
            <Menubar>
              <MenubarMenu className='bg-transparent'>
                <MenubarTrigger>
                  <Avatar className='cursor-pointer'>
                    <AvatarImage src={avatar} alt='User Image' />
                    <AvatarFallback>User</AvatarFallback>
                  </Avatar>
                </MenubarTrigger>
                <MenubarContent className='rounded-[10px] flex flex-col gap-y-1'>
                  {isDematUser() && (
                    <Link to='/dashboard/profile' className='no-underline'>
                      <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                        Profile
                      </MenubarItem>
                    </Link>
                  )}

                  {isDematUser() && (
                    <Link to='/dashboard/myplan' className='no-underline'>
                      <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                        My Plan
                      </MenubarItem>
                    </Link>
                  )}

                  <a
                    className='no-underline'
                    href='https://backoffice.fabits.app/shrdbms/userlogin.ss'
                    target='_blank'
                  >
                    <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                      Back Office
                    </MenubarItem>
                  </a>

                  {/* <a className='no-underline' href='https://fabits.com/pricing' target='_blank'>
                    <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                      Pricing
                    </MenubarItem>
                  </a> */}

                  <Link className='no-underline' to='/dashboard/support'>
                    <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                      Support Portal
                    </MenubarItem>
                  </Link>

                  <Link className='no-underline' to='/dashboard/refer'>
                    <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer bg-[#E7EDFC]'>
                      Refer & Rewards
                    </MenubarItem>
                  </Link>

                  <MenubarItem
                    className='py-2 pl-3 text-sm cursor-pointer bg-error-50 text-error-500'
                    onClick={handleLogout}
                  >
                    Logout
                  </MenubarItem>
                </MenubarContent>
              </MenubarMenu>
            </Menubar>
          </div>
        </div>
      </div>

      {isDematUser() &&
        !isDrawerOpen &&
        location.pathname !== '/dashboard/theme-details' &&
        location.pathname !== '/dashboard/holdings' && (
          <div
            className={`transition-transform duration-300 ease-in-out ${
              showNavbar ? 'translate-y-0' : 'translate-y-[150%]'
            } w-full fixed bottom-0 left-0 right-0 bg-white h-20 flex lg:hidden justify-between gap-x-2 px-2 border-t border-[#D3DBEC] z-[1000]`}
            style={{
              boxShadow: '0px -4px 24px 0px rgba(65, 85, 141, 0.16)',
            }}
          >
            {navbarData.slice(0, 4).map((item, index) => (
              <div
                key={item.id}
                className='flex flex-col justify-center w-full items-center mt-3 mb-4 relative gap-y-1 cursor-pointer'
                onClick={() => {
                  clevertap.event.push('Clicked Button', {
                    Action: item.link,
                    Page_name: location.pathname,
                  })
                  navigate(item.link)
                }}
              >
                {index !== 2 && (
                  <span
                    className={`material-symbols-rounded ${
                      location.pathname === item.link &&
                      !openMore &&
                      'material-symbols-rounded icon-filled-400 bg-[#ECEEF4] px-5 py-1 rounded-[40px] flex justify-center items-center'
                    } 

               ${
                 openMore &&
                 item.title === 'More' &&
                 'material-symbols-rounded icon-filled-400 bg-[#ECEEF4] px-5 py-1 rounded-[40px] flex justify-center items-center'
               } 
              
              
              text-primary-500 py-1 px-5`}
                  >
                    {item.icon}
                  </span>
                )}

                {index === 2 && (
                  <div className={`text-primary-500 flex flex-col items-center justify-end h-full`}>
                    <div className='flex bg-primary-500 absolute -top-[28px] rounded-full p-1.5 shadow-[0px_4px_16px_0px_rgba(65,85,141,0.16)]'>
                      <img
                        src={goals}
                        className='stroke-primary-50 w-[42px] h-[42px] min-w-[42px]'
                      />
                    </div>
                    <p
                      className={`font-semibold text-xs -mt-4 ${
                        location.pathname === item.link ? 'text-primary-500' : 'text-primary-400'
                      }`}
                    >
                      {item.title}
                    </p>
                  </div>
                )}

                {index !== 2 && (
                  <p
                    className={`font-semibold text-xs ${
                      location.pathname === item.link ? 'text-primary-500' : 'text-primary-400'
                    }`}
                  >
                    {item.title}
                  </p>
                )}
              </div>
            ))}

            <div
              className='flex flex-col justify-center w-full items-center mt-3 mb-4 relative gap-y-1 cursor-pointer'
              onClick={() => setOpenMore(!openMore)}
            >
              <span
                className={`material-symbols-rounded ${
                  location.pathname === 'shanti' &&
                  !openMore &&
                  'material-symbols-rounded icon-filled-400 bg-[#ECEEF4] px-5 py-1 rounded-[40px] flex justify-center items-center'
                } 

               ${
                 openMore &&
                 navbarData[4].title === 'More' &&
                 'material-symbols-rounded icon-filled-400 bg-[#ECEEF4] px-5 py-1 rounded-[40px] flex justify-center items-center'
               } text-primary-500 py-1 px-5`}
              >
                {navbarData[4].icon}
              </span>

              <Sheet open={openMore}>
                <SheetContent side='bottom' className='rounded-t-[24px] h-auto bottom-[80px]'>
                  <SheetHeader className='mb-6'>
                    <SheetTitle className='font-DMSans text-[20px] kerning-[28px] -tracking-[0.8px] text-black-500 font-semibold'>
                      More Investment Options
                    </SheetTitle>
                  </SheetHeader>
                  <div className='grid grid-cols-3'>
                    {navbarData.slice(5, 11).map((item) => (
                      <div
                        key={item.id}
                        className='flex flex-col justify-center items-center relative min-h-28 min-w-27 gap-y-2'
                        onClick={() => navigate(item.link)}
                      >
                        <img src={item.icon} alt={item.title} />
                        <p
                          className={`font-DMSans font-semibold text-l text-black-500 -tracking-[0.64px]`}
                        >
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </SheetContent>
              </Sheet>

              <p
                className={`font-semibold text-xs ${
                  location.pathname === navbarData[5].link ? 'text-primary-500' : 'text-primary-400'
                }`}
              >
                {navbarData[4].title}
              </p>
            </div>
          </div>
        )}

      {!isDematUser() && location.pathname !== '/dashboard/theme-details' && (
        <div
          className={`transition-transform duration-300 ease-in-out ${
            showNavbar ? 'translate-y-0' : 'translate-y-[150%]'
          } w-screen fixed bottom-0 left-0 bg-white h-20 flex lg:hidden justify-between gap-x-2 px-2 border-t border-[#D3DBEC] z-[1000] shadow-[0px_-4px_24px_0px_rgba(65,85,141,0.12)]`}
        >
          {navbarDataNonDemat.slice(0, 5).map((item, index) => (
            <div
              key={item.id}
              className='flex flex-col justify-center items-center mt-3 mb-4 relative gap-y-1'
              onClick={() =>
                index === 2
                  ? setMessageModalState({
                      message: 'Your KYC is incomplete.',
                      linkMessage:
                        'Click here to complete your KYC and to enable hassle free, one-click investing.',
                      severity: 'info',
                    })
                  : navigate(item.link)
              }
            >
              {index != 2 && (
                <span
                  className={`material-symbols-rounded ${
                    location.pathname === item.link &&
                    'material-symbols-rounded icon-filled-400 bg-[#ECEEF4] px-5 py-1 rounded-[40px] flex justify-center items-center'
                  } text-primary-500 py-1 px-5`}
                >
                  {item.icon}
                </span>
              )}

              {index === 2 && (
                <div className={`text-primary-500 flex flex-col items-center justify-end h-full`}>
                  <div className='flex bg-primary-500 absolute -top-[28px] rounded-full p-1.5 shadow-[0px_4px_16px_0px_rgba(65,85,141,0.16)]'>
                    <img src={KYC} className='stroke-primary-50 w-[42px] h-[42px] min-w-[42px]' />
                  </div>
                  <p
                    className={`font-semibold text-xs -mt-4 ${
                      location.pathname === item.link ? 'text-primary-500' : 'text-primary-400'
                    }`}
                  >
                    {item.title}
                  </p>
                </div>
              )}

              {index != 2 && (
                <p
                  className={`font-semibold text-xs ${
                    location.pathname === item.link ? 'text-primary-500' : 'text-primary-400'
                  }`}
                >
                  {item.title}
                </p>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default Header
