import { createContext, useState, useContext } from 'react'

const ThemeContext = createContext()

const ThemeCtxProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false)

  const toggleDarkMode = () => {
    setDarkMode(!darkMode)
  }

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>{children}</ThemeContext.Provider>
  )
}

// export { ThemeCtxProvider, ThemeContext }

const useThemeMode = () => {
  return useContext(ThemeContext)
}

export { useThemeMode, ThemeCtxProvider }
