/* eslint-disable */
import { useEffect, useState } from 'react'
import { get, post } from '../../http/restservice'
import { useParams } from 'react-router-dom'

const {
  Box,
  Container,
  Stack,
  Typography,
  CircularProgress,
  Alert,
  Button,
} = require('@mui/material')

export const ThirdPartyAddFunds = () => {
  const { uid } = useParams()
  const [page, setPage] = useState(1)
  const [orderId, setOrderId] = useState(null)
  const [tpRequestDetails, setTpRequestDetails] = useState(null)

  const getOrders = async () => {
    console.log(uid)
    const response = await get('/customerservice/api/customer/redVision/addFundsData/' + uid)
    console.log(response)
    if (response.isError) {
      setPage(-1)
    } else {
      setOrderId(response.orderResponse)
      setTpRequestDetails(response.redVisionData)
      // setPage(2)
    }
  }

  const verifyPayment = async (payload) => {
    payload.accessToken = tpRequestDetails.accessToken
    payload.uid = tpRequestDetails.uid
    const response = await post('customerservice/api/customer/verifyPayment/tp', payload, null, {})
    if (response.isError) {
      console.log(response)
      // setMessage('Failed to Add funds. Please contact support')
      setPage(-1)
      return
    } else {
      // setSuccessMessage(response.message)
      window.location.href = tpRequestDetails.redirect_url + '?success=true'
      console.log(response)
    }
  }

  useEffect(() => {
    if (orderId) {
      var options = {
        key: orderId.key_id,
        amount: orderId.data.amount,
        currency: 'INR',
        name: tpRequestDetails.accHolder_name,
        order_id: orderId.data.id,
        // 'vpa-upi': formik.values.upiId,
        handler: function (response) {
          const paymentResponse = {
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          }
          setOrderId(null)
          // setMessage(null)
          verifyPayment(paymentResponse)
        },
        notes: orderId.data.notes,
        prefill: {
          name: tpRequestDetails.accHolder_name,
          contact: tpRequestDetails.mobileNumber,
          email: tpRequestDetails.email,
          method: tpRequestDetails.paymentType,
        },
        theme: {
          color: '#4F997E',
        },
        image: 'https://s3.ap-south-1.amazonaws.com/fabits.com/favicon.jpg',
        readonly: {
          contact: true,
          email: true,
        },
        modal: {
          ondismiss: function () {
            if (confirm('Are you sure, you want to close the payment form?')) {
              console.log('Checkout form closed by the user')
              history.back()
            } else {
              console.log('Complete the Payment')
            }
          },
        },
      }
      // eslint-disable-next-line
      let rzp1 = new Razorpay(options)
      rzp1.on('payment.failed', function (response) {
        console.log(response.error.code)
        console.log(response.error.description)
        console.log(response.error.source)
        console.log(response.error.step)
        console.log(response.error.reason)
        console.log(response.error.metadata.order_id)
        console.log(response.error.metadata.payment_id)
        // setMessage(response.error.description)
        setMessageModalState({
          message: response.error.description,
          severity: 'error',
        })
      })
      if (rzp1) {
        rzp1.open()
      }
    }
  }, [orderId])

  useEffect(() => {
    if (uid) {
      getOrders()
    } else {
      setPage(-1)
    }
  }, [])

  useEffect(() => {
    clevertap.event.push('Page Viewed', {
      Page_name: 'ThirdPartyAddFunds Page',
    })
  }, [])

  return (
    <Container maxWidth={'md'}>
      <Stack
        justifyContent={'center'}
        alignContent={'center'}
        alignItems={'center'}
        minHeight={'100vh'}
      >
        {page === 1 && (
          <>
            {' '}
            <CircularProgress sx={{ m: 3 }} />
            <Typography variant='h5' sx={{ mb: 1 }}>
              We are processing your payment.
            </Typography>
            <Typography variant='h5'>Please do not refresh or click back.</Typography>
          </>
        )}
        {page === -1 && (
          <>
            <Alert severity='error'>Unable to process the payment now .</Alert>
            <Button onClick={() => history.back()} sx={{ m: 3 }} variant='contained'>
              {' '}
              Back
            </Button>
          </>
        )}
      </Stack>
    </Container>
  )
}
