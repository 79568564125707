import { atom, selector } from 'recoil'
import { get as getData } from '../http/restservice'

const InvestedPlansState = atom({
  key: 'InvestedPlansState',
  default: [],
})

const InvestedPlansSelector = selector({
  key: 'InvestedPlansSelector',
  get: async () => {
    const response = await getData('/planservice/api/plan')
    if (response.isError) {
      return []
    }
    return response
  },
})

export { InvestedPlansSelector, InvestedPlansState }
