/* eslint-disable no-undef */
import { Alert, Grid, Checkbox } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { post } from '../http/restservice'
import LoadingButton from '@mui/lab/LoadingButton'
import clevertap from 'clevertap-web-sdk'
import ReactGA4 from 'react-ga4'
import vector from '../assets/Auth/Vector.svg'
import vector1 from '../assets/Auth/Vector1.svg'

const SignUp = () => {
  const navigate = useNavigate()

  const location = useLocation()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [partnerUser, setPartnerUser] = useState(false)

  useEffect(() => {
    setPhoneNumber(location.state?.phoneNumber)
    fbq('track', 'Sign Up - Page Viewed')
    clevertap.event.push('Sign Up - Page Viewed')
    ReactGA4.event({
      category: 'View',
      action: 'Sign Up - Page Viewed',
      value: 'Sign Up - Page Viewed',
    })
    var custom_data = {
      Custom_Event_Property_Key1: 'Sign Up - Page Viewed',
    }

    branch.logEvent('Sign Up - Page Viewed', custom_data, function (err) {
      console.log(err)
    })
    setMessage(location.state?.message)
  }, [])

  const validationSchema = yup.object({
    firstName: yup.string('First Name').required('First Name is required'),
    lastName: yup.string('Last Name').required('Last Name is required'),
    email: yup.string(),
    phoneNumber: yup
      .string('Phone')
      .min(10, 'Enter Valid Phone number')
      .max(10, 'Enter Valid Phone number')
      .required('Phone number is required'),
    companyName: yup.string(),
    termsConditions: yup.bool().oneOf([true]).required(),
  })

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber,
      companyName: '',
      termsConditions: true,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: () => {},
  })

  const signUp = async () => {
    try {
      clevertap.profile.push({
        Site: {
          Name: formik.values.firstName + ' ' + formik.values.lastName,
          Identity: formik.values.phoneNumber,
          'MSG-email': true, // Enable email notifications
          'MSG-push': true, // Enable push notifications
          'MSG-sms': true, // Enable sms notifications
          'MSG-whatsapp': true, // Enable whatsapp notifications
        },
      })
      let signUpRequest = formik.values
      signUpRequest.email = signUpRequest.phoneNumber + '@fabits.com'
      const response = await post('/customerservice/api/customer/signup', signUpRequest)
      setLoading(false)
      if (response.isError) {
        setMessage(response.response)
        return
      } else if (response.isPartnerUser) {
        setMessage(response.message)
        setPartnerUser(true)
        window.open(response.redirectUrl, '_blank')
        return
      }
      setPhoneNumber(formik.values.phoneNumber)
      navigate('/activate-account-otp', {
        state: {
          phoneNumber: formik.values.phoneNumber,
          message: '',
        },
      })
    } catch (e) {
      setLoading(false)
      return
    }
  }

  return (
    <div className='lg:border lg:bg-white lg:rounded-3xl lg:min-w-[432px] lg:mx-auto lg:py-12 lg:px-[72px] flex flex-col justify-center items-center lg:relative'>
      <div className='flex flex-col gap-y-1 mb-6'>
        <h1 className='font-DMSans text-primary-900 text-center text-[28px] kerning-[36px] -tracking-[1.12px] font-semibold'>
          Sign Up
        </h1>
        <p className='font-DMSans text-center text-black-400 font-medium text-[14px] kerning-[20px] -tracking-[0.56px] flex items-center justify-center gap-x-1'>
          Get started in few easy steps
        </p>
      </div>
      <div className='w-full'>
        <Grid>
          {message && (
            <Alert
              severity='error'
              className='!flex !items-center !font-DMSans !bg-error-50 !py-1 !rounded-[12px] !text-error-600 !font-medium !gap-x-0 !mb-6'
            >
              {message}
            </Alert>
          )}
        </Grid>
        <form onSubmit={formik.handleSubmit} className='flex flex-col gap-y-6'>
          <div className='flex gap-x-4'>
            <div className='flex flex-col'>
              <label
                htmlFor='firstName'
                className='font-DMSans required font-medium text-[12px] kerning-[16px] text-black-500 mb-1'
              >
                First name
              </label>
              <input
                type='text'
                id='firstName'
                placeholder='eg. John'
                required
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={(e) => {
                  formik.handleBlur(e)
                  clevertap.event.push('Sign Up - First Name Entered')
                  fbq('track', 'Sign Up - First Name Entered')
                  ReactGA4.event({
                    category: 'View',
                    action: 'Sign Up - First Name Entered',
                    value: 'Sign Up - First Name Entered',
                  })
                  var custom_data = {
                    Custom_Event_Property_Key1: 'Sign Up - First Name Entered',
                  }

                  branch.logEvent('Sign Up - First Name Entered', custom_data, function (err) {
                    console.log(err)
                  })
                }}
                className='flex w-full py-2 pl-3 pr-4 rounded-[8px] border border-[#D3dBEC] bg-white font-DMSans  focus:border-primary-300 shadow-[0_1.5px_4px_0_rgba(65,85,141,0.08)] outline-none transition-all'
              />
              {/* <TextField
                id='firstName'
                size='small'
                placeholder='eg. Jon'
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={(e) => {
                  formik.handleBlur(e)
                  clevertap.event.push('Sign Up - First Name Entered')
                  fbq('track', 'Sign Up - First Name Entered')
                  ReactGA4.event({
                    category: 'View',
                    action: 'Sign Up - First Name Entered',
                    value: 'Sign Up - First Name Entered',
                  })
                  var custom_data = {
                    Custom_Event_Property_Key1: 'Sign Up - First Name Entered',
                  }

                  branch.logEvent('Sign Up - First Name Entered', custom_data, function (err) {
                    console.log(err)
                  })
                }}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              /> */}
            </div>

            <div className='flex flex-col'>
              <label
                htmlFor='lastName'
                className='font-DMSans required font-medium text-[12px] kerning-[16px] text-black-500 mb-1'
              >
                Last name
              </label>
              <input
                type='text'
                id='lastName'
                placeholder='eg. Doe'
                required
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={(e) => {
                  formik.handleBlur(e)
                  clevertap.event.push('Sign Up - Last Name Entered')
                  fbq('track', 'Sign Up - Last Name Entered')
                  ReactGA4.event({
                    category: 'View',
                    action: 'Sign Up - Last Name Entered',
                    value: 'Sign Up - Last Name Entered',
                  })
                  var custom_data = {
                    Custom_Event_Property_Key1: 'Sign Up - Last Name Entered',
                  }

                  branch.logEvent('Sign Up - Last Name Entered', custom_data, function (err) {
                    console.log(err)
                  })
                }}
                className='flex w-full py-2 pl-3 pr-4 rounded-[8px] border border-[#D3dBEC] bg-white font-DMSans  focus:border-primary-300 shadow-[0_1.5px_4px_0_rgba(65,85,141,0.08)] outline-none transition-all'
              />
              {/* <TextField
                id='lastName'
                size='small'
                placeholder='eg. Snow'
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={(e) => {
                  formik.handleBlur(e)
                  clevertap.event.push('Sign Up - Last Name Entered')
                  fbq('track', 'Sign Up - Last Name Entered')
                  ReactGA4.event({
                    category: 'View',
                    action: 'Sign Up - Last Name Entered',
                    value: 'Sign Up - Last Name Entered',
                  })
                  var custom_data = {
                    Custom_Event_Property_Key1: 'Sign Up - Last Name Entered',
                  }

                  branch.logEvent('Sign Up - Last Name Entered', custom_data, function (err) {
                    console.log(err)
                  })
                }}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
              /> */}
            </div>
          </div>
          {/*<Grid>
            <TextField
              id='email'
              label='Email'
            size='small'

           
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            
            />
            </Grid>*/}
          <div className='flex flex-col'>
            <label
              htmlFor='phoneNumber'
              className='font-DMSans required font-medium text-[12px] kerning-[16px] text-black-500 mb-1'
            >
              Mobile number
            </label>
            <input
              type='number'
              id='phoneNumber'
              placeholder='+91'
              required
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onWheel={() => document.activeElement.blur()}
              onBlur={(e) => {
                setPartnerUser(false)
                formik.handleBlur(e)
                clevertap.event.push('Sign Up - Phone number Entered')
                fbq('track', 'Sign Up - Phone number Entered')
                ReactGA4.event({
                  category: 'View',
                  action: 'Sign Up - Phone number Entered',
                  value: 'Sign Up - Phone number Entered',
                })
                var custom_data = {
                  Custom_Event_Property_Key1: 'Sign Up - Phone number Entered',
                }

                branch.logEvent('Sign Up - Phone number Entered', custom_data, function (err) {
                  console.log(err)
                })
              }}
              className='flex w-full py-2 pl-3 pr-4 rounded-[8px] border border-[#D3dBEC] bg-white font-DMSans  focus:border-primary-300 shadow-[0_1.5px_4px_0_rgba(65,85,141,0.08)] outline-none transition-all'
            />
            {/* <TextField
              id='phoneNumber'
              type='number'
              size='small'
              placeholder='eg. 9988776655'
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={(e) => {
                setPartnerUser(false)
                formik.handleBlur(e)
                clevertap.event.push('Sign Up - Phone number Entered')
                fbq('track', 'Sign Up - Phone number Entered')
                ReactGA4.event({
                  category: 'View',
                  action: 'Sign Up - Phone number Entered',
                  value: 'Sign Up - Phone number Entered',
                })
                var custom_data = {
                  Custom_Event_Property_Key1: 'Sign Up - Phone number Entered',
                }

                branch.logEvent('Sign Up - Phone number Entered', custom_data, function (err) {
                  console.log(err)
                })
              }}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            /> */}
          </div>

          <div className='flex items-center gap-x-2'>
            <Checkbox
              checked={formik.values.termsConditions}
              onChange={(e) => {
                formik.setFieldValue('termsConditions', e.target.checked)
                clevertap.event.push('Sign Up - T&C Clicked')
                fbq('track', 'Sign Up - T&C Clicked')
                ReactGA4.event({
                  category: 'View',
                  action: 'Sign Up - T&C Clicked',
                  value: 'Sign Up - T&C Clicked',
                })
                var custom_data = {
                  Custom_Event_Property_Key1: 'Sign Up - T&C Clicked',
                }

                branch.logEvent('Sign Up - T&C Clicked', custom_data, function (err) {
                  console.log(err)
                })
              }}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                textTransform: 'none',
                display: 'none',
                m: 0,
                p: 0,
              }}
            />
            <p className='font-DMSans text-[14px] kerning-[20px] font-medium text-black-500'>
              By signing up, you agree to receive updates from Fabits.
            </p>
          </div>

          <div className='w-full'>
            <LoadingButton
              variant='contained'
              type='submit'
              loading={loading}
              // loadingPosition='end'
              sx={{
                py: '12px',
                px: '24px',
                width: '100%',
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: '500',
                fontFamily: 'DM Sans',
                textTransform: 'none',
                borderRadius: { xs: '12px', md: '8px' },
                boxShadow: 'none',
                '&:hover': { backgroundColor: '#3b4d80', boxShadow: 'none' },
                '&:active': { backgroundColor: '#3b4d80', boxShadow: 'none' },
              }}
              disabled={!(formik.dirty && formik.isValid) || partnerUser}
              onClick={() => {
                setLoading(true)
                signUp()
                clevertap.event.push('Sign Up - OTP Initiated')
                fbq('track', 'Sign Up - OTP Initiated')
                ReactGA4.event({
                  category: 'View',
                  action: 'Sign Up - OTP Initiated',
                  value: 'Sign Up - OTP Initiated',
                })
                var custom_data = {
                  Custom_Event_Property_Key1: 'Sign Up - OTP Initiated',
                }

                branch.logEvent('Sign Up - OTP Initiated', custom_data, function (err) {
                  console.log(err)
                })
              }}
            >
              Continue
            </LoadingButton>
          </div>
        </form>

        <div className='flex lg:flex-col gap-x-2 justify-center items-center mt-6'>
          <p className='font-DMSans font-medium text-[14px] kerning-[20px] text-black-400'>
            Already have an account?
          </p>
          <button
            onClick={() => {
              navigate('/login')
            }}
          >
            <span className='font-DMSans border-b-2 border-primary-500 border-dashed text-primary-500 text-[14px] kerning-[20px] font-semibold'>
              Login instead
            </span>
          </button>
        </div>
      </div>

      <img
        className='hidden lg:block absolute top-[5%] left-[-20%] -z-[1]'
        src={vector}
        alt='Vector 1'
      />

      <img
        className='hidden lg:block absolute bottom-[10%] right-[-15%] -z-[1]'
        src={vector1}
        alt='Vector 2'
      />
    </div>
  )
}

export default SignUp
