/* eslint-disable */

import { atom, selector } from 'recoil'

// import Property from '../assets/DarkIcons/property.svg'
// import HomePurchase from '../assets/DarkIcons/homepurchase.svg'
// import Electronics from '../assets/Icons/electronics.svg'
// import Passion from '../assets/DarkIcons/passionfund.svg'
// import Car from '../assets/DarkIcons/car.svg'
// import Jewellery from '../assets/DarkIcons/jewellery.svg'

// import Anytime from '../assets/DarkIcons/Anytime.svg'
// import Divident from '../assets/DarkIcons/Divident.svg'
import Balanced from '../assets/DarkIcons/Balanced.svg'
// import Better from '../assets/DarkIcons/Better.svg'
// import Retirement from '../assets/DarkIcons/retirement.svg'

// import NewToInsurace from '../assets/images/New-to-insurance.png'
// import NewToInsuraceActive from '../assets/images/New-to-insurance_active.png'

// import PropertyLight from '../assets/LightIcons/property.svg'
// import HomePurchaseLight from '../assets/LightIcons/homepurchase.svg'

// import PassionLight from '../assets/LightIcons/passionfund.svg'
// import CarLight from '../assets/LightIcons/car.svg'
// import JewelleryLight from '../assets/LightIcons/jewellery.svg'

// import AnytimeLight from '../assets/LightIcons/Anytime.svg'
// import DividentLight from '../assets/LightIcons/Divident.svg'
import BalancedLight from '../assets/LightIcons/Balanced.svg'
// import BetterLight from '../assets/LightIcons/Better.svg'
// import RetirementLight from '../assets/LightIcons/retirement.svg'

// import Gold from '../assets/Icons/Gold.svg'
// import LowCost from '../assets/Icons/LowCost.svg'

// import Signature from '../assets/DarkIcons/Signature.svg'
// import Momentum from '../assets/DarkIcons/Momentum.svg'

// import SignatureLight from '../assets/LightIcons/Signature.svg'
// import MomentumLight from '../assets/LightIcons/Momentum.svg'

import Property from '../assets/images/Property.svg'
import HomePurchase from '../assets/images/House.svg'
import Retreat from '../assets/images/Retreat.svg'
import Passion from '../assets/images/Passion.svg'
import Vehicle from '../assets/images/Vehicle.svg'
import Jewellery from '../assets/images/Jewellery.svg'
import Divident from '../assets/images/Bliss Fund.svg'
import Signature from '../assets/images/Signature Portfolio.svg'
import Momentum from '../assets/images/Momentum Stocks Portfolio.svg'
import Liquidity from '../assets/images/Liquidity & Emergency Fund.svg'
import AnyTimeInvestment from '../assets/images/Purchase Fund.svg'
import SafetyFirst from '../assets/images/Safety-first Fund.svg'

const LifestyleState = atom({
  key: 'LifestyleState',
  default: [
    {
      iconName: 'fa-solid fa-person-cane',
      planName: 'Property',
      description:
        'The funds required to purchase property are large, investing towards this goal early on is the smart thing to do.',
      redirectPath: '/dashboard/propertypurchase',
      img: Property,
      lightImg: Property,
      type: 'PROPERTY',
    },
    {
      iconName: 'fa-solid fa-user-graduate',
      planName: 'Home',
      description:
        'A dream home is a universal goal. Investing towards it means you won’t have to rely on taking a large loan.',
      redirectPath: '/dashboard/homepurchase',
      img: HomePurchase,
      lightImg: HomePurchase,
      type: 'HOME',
    },
    {
      iconName: 'fa-solid fa-house-user',
      planName: 'Retreat',
      description:
        'Putting away a gadget purchase because it’s expensive? Invest towards it instead',
      redirectPath: '/dashboard/postretirementvacationplan',
      img: Retreat,
      lightImg: Retreat,
      type: 'POSTRETIREMENTVACATION',
    },
    {
      iconName: 'fa-solid fa-house-user',
      planName: 'Passion',
      description: 'Dreams need fuel to make them a reality. Invest towards your passion project.',
      redirectPath: '/dashboard/passionplan',
      img: Passion,
      lightImg: Passion,
      type: 'PASSION',
    },
    {
      iconName: 'fa-solid fa-house-user',
      planName: 'Vehicle',
      description:
        'The perfect ride does exist, and you need not take a loan to acquire it. Invest towards a fund to purchase it.',
      redirectPath: '/dashboard/vehicleplan',
      img: Vehicle,
      lightImg: Vehicle,
      type: 'VEHICLE',
    },
    {
      iconName: 'fa-solid fa-heart',
      planName: 'Jewellery',
      description:
        'Why dip into savings to buy gold, when you can start investing towards a fund to purchase gold.',
      redirectPath: '/dashboard/jewelleryplan',
      img: Jewellery,
      lightImg: Jewellery,
      type: 'JEWELLERY',
    },
  ],
})

// const WealthGrowOptions = atom({
//   key: 'WealthGrowOptions',
//   default: [
//     {
//       planName: 'Gold Fund',
//       description:
//         'Why dip into savings to buy gold, when you can start investing towards a fund to purchase gold.',
//       redirectPath: '/dashboard/purchaseplan',
//       img: Balanced,
//       lightImg: BalancedLight,
//       basketId: 47,
//     },
//     {
//       planName: 'Better than FD',
//       description: 'Dreams need fuel to make them a reality. Invest towards your passion project.',
//       redirectPath: '/dashboard/vacationplan',
//       img: Better,
//       lightImg: BetterLight,
//       basketId: 30,
//     },
//     {
//       planName: 'Health Insurance',
//       description: 'Choose Fabits Protection and ensure optimum insurace coverage for your family.',
//       redirectPath: '/dashboard/insurance',
//       img: NewToInsuraceActive,
//       lightImg: NewToInsurace,
//       basketId: null,
//     },
//   ],
// })

const WealthGrowOptions = atom({
  key: 'WealthGrowOptions',
  default: [
    {
      planName: 'Anytime Investment',
      description:
        'The funds required to purchase property are large, investing towards this goal early on is the smart thing to do.',
      redirectPath: '/dashboard/retirementplan',
      img: AnyTimeInvestment,
      lightImg: AnyTimeInvestment,
      basketId: 29,
    },
    {
      planName: 'Low Investment Fund',
      description:
        'A dream home is a universal goal. Investing towards it means you won’t have to rely on taking a large loan.',
      redirectPath: '/dashboard/educationplan',
      img: Liquidity,
      lightImg: Liquidity,

      basketId: 28,
    },
    {
      planName: 'Gold Fund',
      description:
        'Why dip into savings to buy gold, when you can start investing towards a fund to purchase gold.',
      redirectPath: '/dashboard/purchaseplan',
      img: Balanced,
      lightImg: BalancedLight,
      basketId: 47,
    },
    {
      planName: 'Better than FD',
      description: 'Dreams need fuel to make them a reality. Invest towards your passion project.',
      redirectPath: '/dashboard/vacationplan',
      img: SafetyFirst,
      lightImg: SafetyFirst,
      basketId: 30,
    },
  ],
})

const AcceleratedWealthGrowOptions = atom({
  key: 'AcceleratedWealthGrowOptions',
  default: [
    {
      planName: 'Momentum Fund',
      description:
        'The funds required to purchase property are large, investing towards this goal early on is the smart thing to do.',
      redirectPath: '/dashboard/retirementplan',
      img: Momentum,
      lightImg: Momentum,
      basketId: 1,
    },
    {
      planName: 'Balanced Fund',
      description:
        'A dream home is a universal goal. Investing towards it means you won’t have to rely on taking a large loan.',
      redirectPath: '/dashboard/educationplan',
      img: Divident,
      lightImg: Divident,
      basketId: 5,
    },
    {
      planName: 'Signature Fund',
      description:
        'Putting away a gadget purchase because it’s expensive? Invest towards it instead',
      redirectPath: '/dashboard/purchaseplan',
      img: Signature,
      lightImg: Signature,
      basketId: 2,
    },
  ],
})

const LifestyleSelector = selector({
  key: 'LifestyleSelector',
  get: ({ get }) => get(LifestyleState),
})

export { LifestyleSelector, WealthGrowOptions, AcceleratedWealthGrowOptions }
