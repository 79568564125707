import React from 'react'
import Logo from '../assets/Icons/reallogo.png'
import X from '../assets/Icons/x.svg'
import LinkedIn from '../assets/Icons/linkedin.svg'
import Youtube from '../assets/Icons/youtube.svg'
import Instagram from '../assets/Icons/instagram.svg'

const footerContents = {
  name: '',
  icon: Logo,
  favIcons: [
    {
      url: X,
      link: '',
    },
    {
      url: LinkedIn,
      link: '',
    },
    {
      url: Youtube,
      link: '',
    },
    {
      url: Instagram,
      link: '',
    },
  ],
  address:
    'Fabits (Shareway Securities Ltd.) | 294/1, 1st Floor, 7th Cross Rd, | Domlur 1st Stage, | Bengaluru, Karnataka - 560071',
  info: 'SEBI Reg. No.: INZ000208134 | Segments: NSE CM - FO | CDSL Depository Participant: IN-DP-610-2021 | CIN NO: U67120TN2008PLC066952 | GST NO: 29AALCS7597J1ZA',
  navLinks: [
    {
      title: 'Company',
      links: [
        {
          label: 'About',
          url: '/about',
        },
        {
          label: 'About',
          url: '/about',
        },
        {
          label: 'About',
          url: '/about',
        },
        {
          label: 'About',
          url: '/about',
        },

        {
          label: 'About',
          url: '/about',
        },
      ],
    },
    {
      title: 'Company 2',
      links: [
        {
          label: 'About',
          url: '/about',
        },
        {
          label: 'About',
          url: '/about',
        },
        {
          label: 'About',
          url: '/about',
        },
        {
          label: 'About',
          url: '/about',
        },

        {
          label: 'About',
          url: '/about',
        },
      ],
    },
    {
      title: 'Company 3',
      links: [
        {
          label: 'About',
          url: '/about',
        },
        {
          label: 'About',
          url: '/about',
        },
        {
          label: 'About',
          url: '/about',
        },
        {
          label: 'About',
          url: '/about',
        },

        {
          label: 'About',
          url: '/about',
        },
      ],
    },
    {
      title: 'Company 4',
      links: [
        {
          label: 'About',
          url: '/about',
        },
        {
          label: 'About',
          url: '/about',
        },
        {
          label: 'About',
          url: '/about',
        },
        {
          label: 'About',
          url: '/about',
        },

        {
          label: 'About',
          url: '/about',
        },
      ],
    },
    {
      title: 'Company 5',
      links: [
        {
          label: 'About',
          url: '/about',
        },
        {
          label: 'About',
          url: '/about',
        },
        {
          label: 'About',
          url: '/about',
        },
        {
          label: 'About',
          url: '/about',
        },

        {
          label: 'About',
          url: '/about',
        },
      ],
    },
  ],
}
let flag = true
const Footer = () => {
  if (flag) {
    return <></>
  }
  return (
    <div className='footer-container'>
      <div className='footer-header'>
        <div className='footer-item'>
          <img className='footer-logo' src={footerContents.icon} alt='image' />
          <h2>{footerContents.name}</h2>
          {footerContents.address.split('|').map((each) => (
            <ul key={each}>{each}</ul>
          ))}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              marginTop: '60px',
              padding: '8px',
            }}
          >
            {footerContents.favIcons.map((fab) => (
              <img height={24} key={fab} src={fab} alt='icon' />
            ))}
          </div>
        </div>
        <div className='footer-item'>
          {footerContents.info.split('|').map((eachItem) => {
            return <ul key={eachItem}>{eachItem}</ul>
          })}
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className='nav-link-container'>
        {footerContents.navLinks.map((navItem) => {
          return (
            <div key={navItem}>
              <b>{navItem.title}</b>
              {navItem.links.map((eachItem) => {
                return <ul key={eachItem.label}>{eachItem.label}</ul>
              })}
            </div>
          )
        })}
      </div>
      <br />
      <br />

      <div className='dashed-line' />

      <article>
        SHAREWAY SECURITIES LIMITED (FABITS) Member of NSE – SEBI Registration number: INZ000208134
        CDSL: Depository services through SHAREWAY SECURITIES LIMITED. – SEBI Registration number:
        IN-DP-610-2021. Registered Address: old no 46 new no 6, Gilli flower, flat, 2nd floor, 23rd
        street, Anna Nagar East, Chennai 600102. Corporate Address: 294/1, 7th Cross, Domlur Layout
        above Union Bank, Bangalore - 560071. For any complaints pertaining to securities broking
        please write to rathi@fabits.com . Please ensure you carefully read the Risk Disclosure
        Document as prescribed by SEBI SHAREWAY SECURITIES LIMITED (FABITS) Member of NSE – SEBI
        Registration number: INZ000208134 CDSL: Depository services through SHAREWAY SECURITIES
        LIMITED. – SEBI Registration number: IN-DP-610-2021. Registered Address: old no 46 new no 6,
        Gilli flower, flat, 2nd floor, 23rd street, Anna Nagar East, Chennai 600102. Corporate
        Address: 294/1, 7th Cross, Domlur Layout above Union Bank, Bangalore - 560071. For any
        complaints pertaining to securities broking please write to rathi@fabits.com . Please ensure
        you carefully read the Risk Disclosure Document as prescribed by SEBI Procedure to file a
        complaint on SEBI SCORES : Register on SCORES portal. Mandatory details for filing
        complaints on SCORES: Name, PAN, Address, Mobile Number, E-mail ID. Benefits: Effective
        Communication, Speedy redressal of the grievances Investments in securities market are
        subject to market risks; read all the related documents carefully before investing.
        Attention investors: 1) Stock brokers can accept securities as margins from clients only by
        way of pledge in the depository system w.e.f September 01, 2020. 2) Update your e-mail and
        phone number with your stock broker / depository participant and receive OTP directly from
        depository on your e-mail and/or mobile number to create pledge. 3) Check your securities /
        MF / bonds in the consolidated account statement issued by NSDL/CDSL every month. """Prevent
        unauthorised transactions in your account. Update your mobile numbers/email IDs with your
        stock brokers. Receive information of your transactions directly from Exchange on your
        mobile/email at the end of the day. Issued in the interest of investors. KYC is one time
        exercise while dealing in securities markets - once KYC is done through a SEBI registered
        intermediary (broker, DP, Mutual Fund etc.), you need not undergo the same process again
        when you approach another intermediary."" Dear Investor, if you are subscribing to an IPO,
        there is no need to issue a cheque. Please write the Bank account number and sign the IPO
        application form to authorize your bank to make payment in case of allotment. In case of non
        allotment the funds will remain in your bank account. As a business we don't give stock
        tips, and have not authorized anyone to trade on behalf of others. If you find anyone
        claiming to be part of Fabits and offering such services, please call us. E-voting facility
        for Shareholders Common Online Dispute Resolution Portal
      </article>
    </div>
  )
}

export default Footer
