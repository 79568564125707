import vector from '../../assets/Auth/Vector.svg'
import vector1 from '../../assets/Auth/Vector1.svg'

const LoginLock = ({ firstName }) => {
  return (
    <div className='h-full min-h-[30vh] lg:border lg:bg-white lg:rounded-3xl lg:min-w-[432px] lg:mx-auto lg:py-12 lg:px-[72px] flex flex-col justify-center items-center lg:relative'>
      <h1 className='font-DMSans text-primary-900 text-center text-[28px] kerning-[36px] -tracking-[1.12px] font-semibold'>
        Login successful
      </h1>
      <svg
        width='32'
        height='43'
        viewBox='0 0 32 43'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4 42.9395C2.9 42.9395 1.95833 42.5478 1.175 41.7645C0.391667 40.9811 0 40.0395 0 38.9395V18.9395C0 17.8395 0.391667 16.8978 1.175 16.1145C1.95833 15.3311 2.9 14.9395 4 14.9395H22V10.9395C22 9.27279 21.4167 7.85612 20.25 6.68945C19.0833 5.52279 17.6667 4.93945 16 4.93945C14.6 4.93945 13.375 5.36445 12.325 6.21445C11.275 7.06445 10.5667 8.12279 10.2 9.38945C10.0667 9.85612 9.79167 10.2311 9.375 10.5145C8.95833 10.7978 8.5 10.9395 8 10.9395C7.43333 10.9395 6.95833 10.7561 6.575 10.3895C6.19167 10.0228 6.05 9.58945 6.15 9.08945C6.51667 6.82279 7.625 4.89779 9.475 3.31445C11.325 1.73112 13.5 0.939453 16 0.939453C18.7667 0.939453 21.125 1.91445 23.075 3.86445C25.025 5.81445 26 8.17279 26 10.9395V14.9395H28C29.1 14.9395 30.0417 15.3311 30.825 16.1145C31.6083 16.8978 32 17.8395 32 18.9395V38.9395C32 40.0395 31.6083 40.9811 30.825 41.7645C30.0417 42.5478 29.1 42.9395 28 42.9395H4ZM16 32.9395C17.1 32.9395 18.0417 32.5478 18.825 31.7645C19.6083 30.9811 20 30.0395 20 28.9395C20 27.8395 19.6083 26.8978 18.825 26.1145C18.0417 25.3311 17.1 24.9395 16 24.9395C14.9 24.9395 13.9583 25.3311 13.175 26.1145C12.3917 26.8978 12 27.8395 12 28.9395C12 30.0395 12.3917 30.9811 13.175 31.7645C13.9583 32.5478 14.9 32.9395 16 32.9395Z'
          fill='#41558D'
        />
      </svg>

      <p className='font-DMSans text-center text-black-400 font-medium text-[14px] kerning-[20px] -tracking-[0.56px]'>
        Welcome, {firstName}!
      </p>

      <img
        className='hidden lg:block absolute top-[5%] left-[-20%] -z-[1]'
        src={vector}
        alt='Vector 1'
      />

      <img
        className='hidden lg:block absolute bottom-[10%] right-[-15%] -z-[1]'
        src={vector1}
        alt='Vector 2'
      />
    </div>
  )
}

export default LoginLock
