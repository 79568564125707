import React from 'react'
import { Typography } from '@mui/material'

const PasswordStrength = ({ password }) => {
  const requirements = [
    {
      label: '8-20 Characters',
      condition: password.length >= 8 && password.length <= 20,
    },
    {
      label: 'At least one capital letter',
      condition: /[A-Z]/.test(password),
    },
    {
      label: 'At least one special character',
      condition: /[@$#!%*?&]/.test(password),
    },
    {
      label: 'At least one number',
      condition: /\d/.test(password),
    },
    {
      label: 'No space',
      condition: /^\S+$/.test(password),
    },
  ]

  return (
    <div className='border rounded-2xl p-4 bg-[#F4F7FE]'>
      <Typography component='div' sx={{ mb: 2 }}>
        Password must include:
      </Typography>
      {requirements.map((req) => (
        <Typography component='div' key={req.label} sx={{ fontSize: '12px' }}>
          <i
            className={req.condition ? 'fa-solid fa-check' : 'fa-solid fa-xmark'}
            style={{ color: req.condition ? '#7DC42B' : '#ff1744', marginRight: '5px' }}
          ></i>{' '}
          {req.label}
        </Typography>
      ))}
    </div>
  )
}

export default PasswordStrength
