// NavbarContext.js
import React, { createContext, useState } from 'react'

export const NavbarContext = createContext()

export const NavbarProvider = ({ children }) => {
  const [displayNavbar, setDisplayNavbar] = useState(false)

  return (
    <NavbarContext.Provider value={{ displayNavbar, setDisplayNavbar }}>
      {children}
    </NavbarContext.Provider>
  )
}
