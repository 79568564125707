/* eslint-disable */
import React, { createContext, useEffect, useState } from 'react'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { getConfig } from '../http/restservice'
import env from '../env/env'

export const SSEContext = createContext()

const messages = {
  OPEN: 'Market is live',
  CLOSED: '',
  MAINTENANCE_WINDOW: 'Orders are paused',
  DAILY_MAINTENANCE_WINDOW:
    'App is currently under maintenance. You can continue placing orders after 07:00 AM.',
  SPECIAL_TRADING_SESSION: 'SPECIAL_TRADING_SESSION',
  AMO_ORDERS: 'Market is offline',
}

export const SSEProvider = ({ children }) => {
  const [marketStatus, setMarketStatus] = useState(null)
  const [message, setMessage] = useState('')

  useEffect(() => {
    let eventSource

    // Fetch the config and set up the SSE connection
    getConfig()
      .then((resp) => {
        const headers = resp.headers

        eventSource = new EventSourcePolyfill(`${env.API_URL}customerservice/api/marketStatus`, {
          headers: {
            'Content-Type': 'text/event-stream',
            'Cache-Control': 'no-cache',
            Connection: 'keep-alive',
            // 'X-Accel-Buffering': 'no',
            ...headers,
          },
          heartbeatTimeout: 100000,
        })

        eventSource.onopen = () => {
          console.log('SSE connection opened')
        }

        eventSource.onmessage = (event) => {
          try {
            const parsedData = JSON.parse(event.data)

            setMarketStatus(parsedData)
            setMessage(messages[parsedData] || '')
          } catch (error) {
            console.log('🚀 ~ SSE Event onmessage ~ error:', error)
          }
        }

        eventSource.onerror = (error) => {
          console.error('SSE Error:', error)
        }
      })
      .catch((err) => {
        console.error('Error fetching config for SSE:', err)
      })

    // Clean up the event source on component unmount
    return () => {
      if (eventSource) {
        eventSource.close()
      }
    }
  }, [])

  return <SSEContext.Provider value={{ marketStatus, message }}>{children}</SSEContext.Provider>
}
