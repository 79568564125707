import React, { useEffect, useState } from 'react'
import { Alert } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { loginWithOTP, getUserDetails } from '../utils/AuthUtil'
import LoadingButton from '@mui/lab/LoadingButton'
import ReactGA4 from 'react-ga4'
import clevertap from 'clevertap-web-sdk'
import { InputOTP, InputOTPGroup, InputOTPSlot } from '../Components/ui/input-otp'
import { REGEXP_ONLY_DIGITS } from 'input-otp'

const LoginOtp = () => {
  const navigate = useNavigate()

  const location = useLocation()
  const [otpEntered, setOtpEntered] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (location) {
      setPhoneNumber(location.state?.phoneNumber)
    }
  }, [])

  const otpLogin = async (event) => {
    event.preventDefault()
    setErrorMessage(null)
    const payload = {
      phoneNumber,
      otp: otpEntered,
    }
    try {
      const response = await loginWithOTP(payload)
      ReactGA4.event({
        category: 'Login',
        action: 'Login OTP Entered',
      })
      clevertap.event.push('Login OTP Entered')
      setLoading(false)
      if (response.isError) {
        if (response.code === 'Unauthorized') {
          setErrorMessage('Enter Valid OTP.')
        } else {
          setErrorMessage('Unable to process your request.Please try again')
        }
        ReactGA4.event({
          category: 'Login',
          action: 'OTP Failure',
        })
        clevertap.event.push('OTP Failure')
        return
      }
      const userDetails = getUserDetails()
      console.log('GA event', userDetails.email)
      // Send logged in user details to GA4 and clevertap
      ReactGA4.event({
        category: 'Login',
        action: 'Login Complete',
        value: userDetails.email,
      })
      ReactGA4.set({ userId: userDetails.email })
      clevertap.onUserLogin.push({
        Site: {
          Name: userDetails.firstName + ' ' + userDetails.lastName,
          Identity: userDetails.uid,
          Email: userDetails.email,
          Phone: userDetails.phoneNumber,
        },
      })
      clevertap.event.push('Login Complete')
      if (window.utmData && window.utmData['$marketing_title']) {
        if (window.utmData['$marketing_title'] === 'Insurance') {
          navigate('/dashboard/insurance')
        } else {
          let navState = null
          if (window.utmData.navigation_state) {
            navState = JSON.parse(window.utmData.navigation_state)
            console.log(navState)
          }
          if (window.utmData.site_url) {
            navigate(window.utmData.site_url, { state: navState })
          } else {
            navigate('/dashboard', { state: { login: true } })
          }
        }
      } else {
        navigate('/dashboard', { state: { login: true } })
      }
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <div className='lg:border lg:bg-white lg:rounded-3xl lg:min-w-[432px] lg:mx-auto lg:py-12 lg:px-[72px] flex flex-col justify-center items-center lg:relative'>
      <div className='flex flex-col gap-y-1 mb-6'>
        <h1 className='font-DMSans text-primary-900 text-center text-[28px] kerning-[36px] -tracking-[1.12px] font-semibold'>
          Login
        </h1>
        <p className='font-DMSans text-center text-black-400 font-medium text-[14px] kerning-[20px] -tracking-[0.56px] flex items-center justify-center gap-x-1'>
          Welcome back!
        </p>
      </div>

      <div className='w-full mb-6 flex flex-col justify-center items-center'>
        <p className='flex flex-row whitespace-nowrap font-DMSans text-[14px] kerning-[20px] -tracking-[0.56px] font-medium text-black-400'>
          Enter the OTP sent to
        </p>
        <div
          className='flex justify-center items-center gap-x-1 border-primary-500 border-dashed w-min border-b-[2px]'
          onClick={() => navigate('/login')}
        >
          <p className='text-primary-500 font-semibold font-DMSans whitespace-nowrap cursor-pointer'>
            +91 {phoneNumber}
          </p>
          <span className='material-symbols-rounded icon-filled-400 cursor-pointer text-primary-500 text-[16px]'>
            stylus
          </span>
        </div>
      </div>

      <Grid item xs={12} sx={{ mb: 2 }}>
        {' '}
        {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <form onSubmit={otpLogin}>
          <div>
            <InputOTP
              maxLength={6}
              pattern={REGEXP_ONLY_DIGITS}
              value={otpEntered}
              onChange={(value) => setOtpEntered(value)}
            >
              <InputOTPGroup>
                <InputOTPSlot
                  className={`${
                    errorMessage && 'border-error-600 text-error-600 font-medium'
                  } border-[#D3DBEC] text-[16px] kerning-[24px] py-3 px-4 !h-[48px]`}
                  index={0}
                />
                <InputOTPSlot
                  className={`${
                    errorMessage && 'border-error-600 text-error-600 font-medium'
                  } border-[#D3DBEC] text-[16px] kerning-[24px] py-3 px-4 !h-[48px]`}
                  index={1}
                />
                <InputOTPSlot
                  className={`${
                    errorMessage && 'border-error-600 text-error-600 font-medium'
                  } border-[#D3DBEC] text-[16px] kerning-[24px] py-3 px-4 !h-[48px]`}
                  index={2}
                />
                <InputOTPSlot
                  className={`${
                    errorMessage && 'border-error-600 text-error-600 font-medium'
                  } border-[#D3DBEC] text-[16px] kerning-[24px] py-3 px-4 !h-[48px]`}
                  index={3}
                />
                <InputOTPSlot
                  className={`${
                    errorMessage && 'border-error-600 text-error-600 font-medium'
                  } border-[#D3DBEC] text-[16px] kerning-[24px] py-3 px-4 !h-[48px]`}
                  index={4}
                />
                <InputOTPSlot
                  className={`${
                    errorMessage && 'border-error-600 text-error-600 font-medium'
                  } border-[#D3DBEC] text-[16px] kerning-[24px] py-3 px-4 !h-[48px]`}
                  index={5}
                />
              </InputOTPGroup>
            </InputOTP>

            <div className='text-center text-xs text-red-600 font-medium mt-2'>
              {errorMessage && <>{errorMessage}</>}
            </div>
            {/* <TextField
              required
              id='component-outlined'
              type='text'
              size='small'
              onChange={handleChange}
              fullWidth
              autoFocus
              value={otpEntered}
              inputProps={{
                maxLength: 6,
                inputMode: 'numeric',
              }}
            /> */}
          </div>
          <LoadingButton
            variant='contained'
            loading={loading}
            // loadingPosition='end'
            sx={{
              mt: '24px',
              py: '12px',
              px: '24px',
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: '500',
              fontFamily: 'DM Sans',
              textTransform: 'none',
              borderRadius: { xs: '12px', md: '8px' },
              boxShadow: 'none',
              '&:hover': { backgroundColor: '#3b4d80', boxShadow: 'none' },
              '&:active': { backgroundColor: '#3b4d80', boxShadow: 'none' },
            }}
            disabled={!(otpEntered?.length === 6)}
            fullWidth
            onClick={(event) => {
              setLoading(true)
              otpLogin(event)
            }}
          >
            Verify OTP
          </LoadingButton>
        </form>
      </Grid>
    </div>
  )
}

export default LoginOtp
