import addfunds from '../assets/Navbar/AddFunds.svg'
import topfunds from '../assets/Navbar/TopFunds.svg'
import ipo2 from '../assets/Navbar/IPO2.svg'
import sgb from '../assets/Navbar/SGB.svg'
import insurance2 from '../assets/Navbar/Insurance2.svg'
import investments from '../assets/Navbar/Investments.svg'

export const cards = [
  {
    id: 1,
    title: 'Add Funds',
    icon: addfunds,
    tag: 'NEW',
    link: '/dashboard/funds',
  },
  {
    id: 2,
    title: 'Top Funds',
    icon: topfunds,
    tag: '',
    link: '/dashboard/theme',
  },
  {
    id: 3,
    title: 'IPO',
    icon: ipo2,
    tag: '',
    link: '/dashboard/ipo',
  },
  {
    id: 4,
    title: 'SGB',
    icon: sgb,
    tag: '',
    link: '/dashboard/goldbonds',
  },
  {
    id: 5,
    title: 'Insurance',
    icon: insurance2,
    tag: '',
    link: '/dashboard/insurance',
  },
  {
    id: 6,
    title: 'Investments',
    icon: investments,
    tag: '',
    link: '/dashboard/portfolio',
  },
]
