/* eslint-disable */

import useLocalStorage from '../../hooks/useLocalStorage'
import { useModal } from '../../hooks/use-modal-store'
import { Button } from '../ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Input } from '../ui/input'
import { Label } from '../ui/label'
import { Separator } from '../ui/separator'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form'
import circlecheck from '../../assets/MyPlan/circlecheck.svg'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { post } from '../../http/restservice'
import env from '../../env/env'
import { useState } from 'react'

const formSchema = yup.object().shape({
  upiId: yup.string().required('Please enter upiId.'),
})

const SubscriptionModal = () => {
  const { isOpen, onClose, type, data } = useModal()
  const [userInfo] = useLocalStorage('userData')
  const isModalOpen = isOpen && type === 'openSubscription'
  const [vpaDetails, setVpaDetails] = useState(null)

  const form = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: { upiId: '' },
  })

  const handleFormReset = () => {
    form.setValue('upiId', '')
    setVpaDetails(null)
  }

  const isLoading = form.formState.isSubmitting

  const onSubmit = async () => {
    try {
      const values = form.getValues()

      const response = await post(
        `${env.API_URL}planservice/api/upiMandate/checkVPA?upiId=${values.upiId}`,
        values,
      )

      if (response.code === '200' && response.status === 'SUCCESS') {
        setVpaDetails(response.data)
      }
    } catch (error) {
      console.log('🚀 ~ onSubmit ~ error:', error)
    }
  }

  return (
    <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
      <DialogContent className='sm:max-w-[736px] font-DMSans p-[32px]'>
        <DialogHeader>
          <DialogTitle className='text-start font-semibold text-2xl text-[#2D2D2D]'>
            Review your plan
          </DialogTitle>
          <DialogDescription className='text-start text-black-400 font-medium'>
            Towards a better financial future{' '}
          </DialogDescription>
        </DialogHeader>
        <Separator />
        <div className='flex gap-x-12 justify-between'>
          <div>
            <div>
              <img scr='' alt='' />
              <h3>Fabits {data?.userPlan?.title}</h3>
              <h3 className='font-semibold text-[42px]'>
                ₹ {data?.userPlan?.amount}{' '}
                <span className='text-black-200 font-medium text-base'>/mo.</span>
              </h3>
            </div>
            <div className='border-b border-dashed my-6' />
            <div>{data && data.userPlan && <data.userPlan.features />}</div>
          </div>
          <div className='border rounded-[32px] p-6 min-w-[372px]'>
            <div className='flex justify-between font-semibold text-xl'>
              <h3>Mandate Amount</h3>
              <h3>
                ₹ 1,999
                <br />
                <span className='text-black-200 font-medium text-sm'>+18% GST</span>
              </h3>
            </div>
            <div className='bg-secondary-50 rounded-[12px] py-[10px] pl-3 pr-2 flex items-center gap-x-3'>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M10 15C10.2833 15 10.5208 14.9042 10.7125 14.7125C10.9042 14.5208 11 14.2833 11 14V10C11 9.71667 10.9042 9.47917 10.7125 9.2875C10.5208 9.09583 10.2833 9 10 9C9.71667 9 9.47917 9.09583 9.2875 9.2875C9.09583 9.47917 9 9.71667 9 10V14C9 14.2833 9.09583 14.5208 9.2875 14.7125C9.47917 14.9042 9.71667 15 10 15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z'
                  fill='#74A766'
                />
              </svg>

              <div>
                <p className='text-secondary-600 font-semibold'>Mandate amount covers upgrades</p>
                <p className='text-secondary-500 font-medium text-sm'>
                  You’ll only be charged ₹ {data?.userPlan?.amount}
                </p>
              </div>
            </div>
            <Separator className='my-6' />
            <div className='flex flex-col gap-y-2'>
              <p>Choose your preferred method</p>
              <div className='border rounded-[16px] bg-[#F4F7FE] min-h-40 px-3 py-5 flex flex-col gap-y-2'>
                <Form {...form}>
                  <form className='space-y-8'>
                    <FormField
                      control={form.control}
                      name='upiId'
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Enter UPI Address</FormLabel>
                          <FormControl>
                            <Input
                              disabled={isLoading}
                              placeholder='eg. dhruv@okhdfcbank'
                              endAdornment={`${vpaDetails ? 'Reset' : 'Verify'}`}
                              handleSubmit={
                                vpaDetails
                                  ? form.handleSubmit(handleFormReset)
                                  : form.handleSubmit(onSubmit)
                              }
                              {...field}
                            />
                          </FormControl>
                          {/* <FormDescription>This is your public display name.</FormDescription> */}
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </form>
                </Form>

                {vpaDetails && vpaDetails.is_vpa_valid === 'N' && (
                  <p className='px-1 text-sm text-error-500'>UPI ID is not valid</p>
                )}

                {vpaDetails && vpaDetails.is_vpa_valid === 'Y' && (
                  <div className='py-2 px-1'>
                    <div className='flex gap-x-2 items-center'>
                      <img src={circlecheck} alt='upi check' className='h-4 w-4' />
                      <p className='text-secondary-500 font-semibold text-sm'>
                        {vpaDetails?.payeeType?.name}
                      </p>
                    </div>
                    <p className='text-black-400 text-sm flex items-center px-6'>
                      XXXX
                      {vpaDetails?.payeeType?.merchantInfo?.code}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button
            type='submit'
            className='bg-white hover:bg-white text-primary-500 border mr-4'
            onClick={() => {
              onClose(), form.reset(), setVpaDetails(null)
            }}
          >
            Close
          </Button>
          <Button
            type='submit'
            disabled={!vpaDetails || data?.mandateStatus === 'ACTIVE'}
            onClick={() => {
              data?.createMandate(form.getValues(), vpaDetails?.payeeType.name)
            }}
          >
            Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default SubscriptionModal
