/* eslint-disable */
import useLocalStorage from '../../hooks/useLocalStorage'
import { useModal } from '../../hooks/use-modal-store'
import { Button } from '../ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'

import { post } from '../../http/restservice'
import env from '../../env/env'
import { ArrowDownUp } from 'lucide-react'
import sim_card_download from '../../assets/MyPlan/sim_card_download.svg'
import copy_all from '../../assets/MyPlan/copy_all.svg'
import useMediaQuery from '../../hooks/useMediaQuery'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '../ui/sheet'

const SellRequestForm = () => {
  const { isOpen, onClose, type, data } = useModal()
  const [userInfo] = useLocalStorage('userData')
  const isModalOpen = isOpen && type === 'openSellRequestForm'
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  const onSubmit = async (values) => {
    try {
      const response = await post(
        `${env.API_URL}planservice/api/upiMandate/checkVPA?upiId=${values.upiId}`,
        values,
      )

      if (response.response.code === 'OK' && response.response.status === 'SUCCESS') {
        setVpaDetails(response.response.data)
      }
    } catch (error) {
      console.log('🚀 ~ onSubmit ~ error:', error)
    }
  }

  return (
    <>
      {isDesktop ? (
        <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
          <DialogContent className='sm:max-w-[746px] font-DMSans p-6' showclose={true}>
            <DialogHeader>
              <DialogTitle className='text-start font-semibold text-2xl text-[#2D2D2D]'>
                Sell Fund
              </DialogTitle>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      ) : (
        <Sheet open={isModalOpen} onOpenChange={() => onClose()}>
          <SheetContent
            className={`font-DMSans p-6 rounded-t-[24px] bottom-0`}
            showClose={true}
            side='bottom'
          >
            <SheetHeader>
              <SheetTitle className='text-start font-semibold text-2xl text-[#2D2D2D]'>
                Sell Fund
              </SheetTitle>
            </SheetHeader>
          </SheetContent>
        </Sheet>
      )}
    </>
  )
}

export default SellRequestForm
