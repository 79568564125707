/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { Typography, useTheme, Box, Drawer, IconButton, Menu, MenuItem } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import clevertap from 'clevertap-web-sdk'
import smallLogo from '../assets/Icons/smallLogo.png'
import smallDarkLogo from '../assets/Icons/smallDarkLogo.png'
import expand_content from '../assets/Icons/expand_content.svg'
import collapse_content from '../assets/Icons/collapse_content.svg'
import { useThemeMode } from '../Provider/ThemeContextProvider'
import NavigationStore from '../store/NavigationStore'
import starIcon from '../assets/Navbar/star.svg'
import { userDetailsState } from '../Pages/LoginState'
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import { ChevronLeft, ChevronRight, EllipsisVertical } from 'lucide-react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
} from './ui/menubar'
import { isDematUser, removeToken } from '../utils/AuthUtil'
import useMediaQuery from '../hooks/useMediaQuery'
import avatar from '../assets/Navbar/Group 146.png'

const Nav = ({ openNav, handleOpenNav }) => {
  const navState = useRecoilValue(NavigationStore)
  const navigate = useNavigate()
  const location = useLocation()
  const [expandedState, setExpandedState] = useState(true)
  const theme = useTheme()
  const { darkMode } = useThemeMode()
  const refreshNavState = useRecoilRefresher_UNSTABLE(NavigationStore)
  const userDetails = useRecoilValue(userDetailsState)
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  const handleLogout = () => {
    removeToken()
    navigate('/login')
  }

  const handleDrawerToggle = () => {
    handleOpenNav()
  }

  const onNavClick = (redirectPath) => {
    navigate(redirectPath)
  }

  useEffect(() => {
    refreshNavState()
  }, [])

  const drawer = (
    <div className='flex flex-col gap-y-0'>
      {navState.map((item, index) => (
        <div
          key={index + item.linkName}
          className={`${
            item.highlightUrls.includes(location.pathname) &&
            'border !border-primary-500 rounded-lg bg-[#E7EDFC] hover:bg-[#e7edfc]'
          } flex items-center  ${
            expandedState ? 'gap-x-3 px-4 hover:bg-[#ECEEF4]' : 'px-2'
          } py-[10px] cursor-pointer border border-transparent rounded-lg text-black-500 transition-all`}
          onClick={() => {
            if (
              item.linkName == 'Home' ||
              item.linkName == 'Discover Top funds' ||
              item.linkName == 'Account Balance' ||
              item.linkName == 'IPO' ||
              item.linkName == 'Insurance'
            ) {
              clevertap.event.push('Clicked ' + item.linkName + ' Tab')
              clevertap.event.push('Clicked Navigation', {
                Action: item.linkName,
                Page_name: location.pathname,
              })

              fbq('track', 'Clicked ' + item.linkName + ' Tab')
            }
            handleOpenNav()
            onNavClick(item.linkPath)
          }}
        >
          <span
            className={`material-symbols-rounded relative ${
              item.highlightUrls.includes(location.pathname) &&
              'material-symbols-rounded icon-filled-400 text-primary-500'
            }`}
          >
            {item.iconName}
            {item.linkName === 'Gold Bonds' && (
              <img className='absolute top-0 right-0 h-full' src={starIcon} alt='Gold Bonds' />
            )}
          </span>

          <Typography
            component='span'
            key={index}
            sx={{
              cursor: 'pointer',
              fontSize: '16px',
              display: 'inline-block',
              color: item.highlightUrls.includes(location.pathname) ? '#41558D' : '#2D2D2D',

              verticalAlign: 'middle',
            }}
          >
            &nbsp;{expandedState ? item.linkName : ''}
          </Typography>
        </div>
      ))}
    </div>
  )

  return (
    <>
      <Box sx={{ transition: 'all 1s' }}>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleOpenNav}
          sx={{ display: { md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        {expandedState ? (
          <div className='flex justify-between items-center'>
            <img src='/logo.svg' alt='fabits' className='logo cursor-pointer' />

            {isDesktop && (
              <div
                className='hidden border border-[#D3DBEC] !bg-[#ffffff] hover:bg-white p-2 rounded-full lg:flex justify-center items-center cursor-pointer'
                id='web-inbox-placeholder'
              >
                <span className={`material-symbols-rounded text-black-400 w-6 h-6`}>
                  notifications
                </span>
              </div>
            )}
          </div>
        ) : (
          <div className='flex flex-col justify-center items-center'>
            <img
              src={darkMode ? smallDarkLogo : smallLogo}
              alt=''
              onClick={() => {
                setExpandedState(!expandedState)
              }}
              onFocus={() => {
                setExpandedState(!expandedState)
              }}
              className='collapsedLogo cursor-pointer -ml-5'
            />

            <div>
              <span
                id='web-inbox-placeholder'
                className={`material-symbols-rounded text-black-400 cursor-pointer mt-5`}
              >
                notifications
              </span>
            </div>
          </div>
        )}
      </Box>
      <Box
        component='nav'
        sx={{
          width: { md: '100%' },
          flexShrink: { sm: 0 },
          mt: 5,
          transition: 'all 1s',
        }}
        aria-label='mailbox folders'
      >
        <Drawer
          open={openNav}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            disableScrollLock: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              background: theme.palette.secondary.main,
              padding: '10px 15px',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
          }}
        >
          {/* <img
            src={expandedState ? expand_content : collapse_content}
            alt=''
            onClick={() => {
              setExpandedState(!expandedState)
            }}
            className='cursor-pointer border'
            style={{
              position: 'absolute',
              right: '-15px',
              top: '96px',
              background: '#fff',
              borderRadius: '50%',
              display: 'inline-block',
              padding: '2px 2px',
              transition: 'all 1s',
            }}
          /> */}

          <div
            className={`${
              expandedState && 'flex items-start gap-x-4 py-3 px-4 bg-[#E7EDFC] rounded-2xl'
            }  mb-6`}
          >
            <div className={`flex ${expandedState ? 'gap-x-2' : 'justify-center'}`}>
              <Avatar className={`${expandedState && 'h-12 w-12'}`}>
                <AvatarImage className='h-full w-full' src={avatar} alt='User Image' />
                <AvatarFallback>User</AvatarFallback>
              </Avatar>

              {expandedState && (
                <div className='flex flex-col max-w-[119px]'>
                  <p className='font-semibold text-base text-[#2D2D2D]'>{userDetails.firstName}</p>
                  <p className='text-[#5D5D5D] text-sm overflow-hidden break-words'>
                    {userDetails.phoneNumber}
                  </p>
                </div>
              )}
            </div>

            {expandedState && (
              <Menubar>
                <MenubarMenu className='bg-transparent'>
                  <MenubarTrigger className='bg-[transparent] h-5 w-5 rounded-full'>
                    <EllipsisVertical
                      fill='#808DB3'
                      className='cursor-pointer hover:text-primary-500'
                    />
                  </MenubarTrigger>
                  <MenubarContent className='rounded-[10px] flex flex-col gap-y-[2px]'>
                    {/* <Link to='/dashboard/profile'>
                        <MenubarItem className='flex justify-between cursor-pointer'>
                          <p>{userDetails.firstName + ' ' + userDetails.lastName}</p>
                          <ChevronRight className='h-5 w-5' />
                        </MenubarItem>
                      </Link> */}
                    {isDematUser() && (
                      <Link to='/dashboard/profile' className='no-underline'>
                        <MenubarItem className='py-2 pl-3 text-[14px] kerning-[20px] text-black-500 cursor-pointer font-DMSans font-medium'>
                          Profile
                        </MenubarItem>
                      </Link>
                    )}

                    {isDematUser() && (
                      <Link to='/dashboard/myplan' className='no-underline'>
                        <MenubarItem className='py-2 pl-3 text-sm text-black-500 cursor-pointer'>
                          My Plan
                        </MenubarItem>
                      </Link>
                    )}

                    <a
                      className='no-underline'
                      href='https://backoffice.fabits.app/shrdbms/userlogin.ss'
                      target='_blank'
                    >
                      <MenubarItem className='py-2 pl-3 text-[14px] kerning-[20px] text-black-500 cursor-pointer font-DMSans font-medium'>
                        Back Office
                      </MenubarItem>
                    </a>

                    {/* <a className='no-underline' href='https://fabits.com/pricing' target='_blank'>
                      <MenubarItem className='py-2 pl-3 text-[14px] kerning-[20px] text-black-500 cursor-pointer font-DMSans font-medium'>
                        Pricing
                      </MenubarItem>
                    </a> */}

                    <Link to='/dashboard/support' className='no-underline'>
                      <MenubarItem className='py-2 pl-3 text-[14px] kerning-[20px] text-black-500 cursor-pointer font-DMSans font-medium'>
                        Support Portal
                      </MenubarItem>
                    </Link>

                    <Link to='/dashboard/refer' className='no-underline'>
                      <MenubarItem className='py-2 pl-3 text-[14px] kerning-[20px] text-black-500 cursor-pointer font-DMSans font-medium'>
                        Refer & Rewards
                      </MenubarItem>
                    </Link>

                    <MenubarItem
                      className='py-2 pl-3 text-[14px] kerning-[20px] text-error-500 cursor-pointer font-DMSans font-medium'
                      onClick={handleLogout}
                    >
                      Logout
                    </MenubarItem>
                  </MenubarContent>
                </MenubarMenu>
              </Menubar>
            )}
          </div>

          {drawer}
        </Box>
      </Box>
    </>
  )
}

export default Nav
