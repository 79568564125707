import { selector } from 'recoil'
import { get } from '../http/restservice'
import { isDematUser } from '../utils/AuthUtil'

const AccountDetailsSelector = selector({
  key: 'AccountDetailsSelector',
  get: async () => {
    if (isDematUser()) {
      const response = await get('/planservice/api/portfolio/summary', null, {})
      if (response.isError) {
        return {}
      } else {
        return response ? response : {}
      }
    } else {
      return {}
    }
  },
})

const AccountDetailsNewSelector = selector({
  key: 'AccountDetailsNewSelector',
  get: async () => {
    if (isDematUser()) {
      const response = await get('/planservice/api/portfolio/summary/new', null, {})
      if (response.isError) {
        return {}
      } else {
        return response ? response : {}
      }
    } else {
      return {}
    }
  },
})

const UserDetails = selector({
  key: 'UserDetails',
  get: async () => {
    if (isDematUser()) {
      const response = await get('/customerservice/api/customer/bankDetails', null, {})

      if (response.isError) {
        return {}
      } else {
        return response ? getResponse(response) : {}
      }
    } else {
      return {}
    }
  },
})

const BankDetails = selector({
  key: 'BankDetails',
  get: async () => {
    if (isDematUser()) {
      const response = await get('/customerservice/api/customer/bankDetails', null, {})

      if (response.isError) {
        return {}
      } else {
        return response ? response : {}
      }
    } else {
      return {}
    }
  },
})

const GetJourneyMessageSelector = selector({
  key: 'GetJourneyMessageSelector',
  get: async () => {
    if (!isDematUser()) {
      const response = await get('/customerservice/api/customer/journey/message', null, {})
      if (response.isError) {
        return {}
      } else {
        return response ? response.message : {}
      }
    } else {
      return {}
    }
  },
})

const NotificationsSelector = selector({
  key: 'NotificationsSelector',
  get: async () => {
    const response = await get('/customerservice/api/notification', null, {})
    if (response.isError) {
      return []
    } else {
      return response ? response : []
    }
  },
})

const getResponse = (userBankDetails) => {
  const fundDetails = userBankDetails.fundsDetails
  let calculatedFunds = {
    openingBalance: 0,
    payOutAmt: 0,
    payinAmt: 0,
    availableCash: 0,
    availableMargin: 0,
    others: 0,
  }
  if (Object.keys(fundDetails).length) {
    const openingBalance =
      parseFloat(fundDetails.AdhocMargin) +
      parseFloat(fundDetails.NotionalCash) +
      parseFloat(fundDetails.AuxAdhocMargin) +
      parseFloat(fundDetails.AuxNotionalCash) +
      parseFloat(fundDetails.CollateralValue)
    const payinAmt = parseFloat(fundDetails.RmsPayInAmt)
    const payOutAmt = parseFloat(fundDetails.RmsPayOutAmt)
    const spanMargin = parseFloat(fundDetails.SpanMarginPrsnt)
    const exposure = parseFloat(fundDetails.ExposureMarginPrsnt)
    const availableCash = openingBalance - parseFloat(fundDetails.MarginUsed) + payinAmt + payOutAmt
    const marginVar = parseFloat(fundDetails.MarginVarPrsnt)
    const stockValuation = parseFloat(fundDetails.Collateral)
    const directCollateralValue =
      parseFloat(fundDetails.RmsCollateral) + parseFloat(fundDetails.AuxRmsCollateral)
    const totalCollateral = stockValuation + directCollateralValue
    const availableMargin = availableCash + parseFloat(totalCollateral)
    const others = parseFloat(fundDetails.MarginUsed) - spanMargin + exposure + marginVar
    calculatedFunds = {
      openingBalance,
      payOutAmt,
      payinAmt,
      availableCash,
      availableMargin,
      others,
    }
  }

  return { ...userBankDetails, calculatedFunds }
}

export {
  AccountDetailsSelector,
  AccountDetailsNewSelector,
  UserDetails,
  BankDetails,
  GetJourneyMessageSelector,
  NotificationsSelector,
}
