/* eslint-disable */
import UPI from '../../assets/SVGS/upi.svg'
import CircleUPI from '../../assets/SVGS/circle-upi.svg'
import CircleFabits from '../../assets/SVGS/circle-fabits.svg'
import CircleRight from '../../assets/SVGS/circle-right.svg'
import { Separator } from '../ui/separator'
import { Button } from '../ui/button'
import { useEffect, useState } from 'react'
import PaymentGif from '../../assets/gifs/https___lottiefiles.com_animations_flow-tWbpYsXrBz.gif'

const PaymentRequest = ({ closeConnection, mandateStatus, vpaDetails, mandateTimeLeft }) => {
  const [timeLeft, setTimeLeft] = useState(mandateTimeLeft || 300) // 5 minutes = 300 seconds

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)

      // Clear the timeout when component unmounts or when timeLeft changes
      return () => clearTimeout(timerId)
    } else {
      closeConnection()
      localStorage.removeItem('subscriptionMandateTimer')
    }
  }, [timeLeft])

  // Convert the remaining time to minutes and seconds
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  }

  return (
    <div className='ease-in-out h-[95vh] flex flex-col justify-center gap-y-12 pt-12 pb-16 px-4 lg:pb-0 lg:pt-0 lg:px-0'>
      {/* <div className='flex justify-center items-center mt-12'>
        <span className='animate-bounce text-xl border-[2px] border-secondary-600 rounded-full h-16 w-16 flex justify-center items-center'>
          {formatTime(timeLeft)}
        </span>
      </div> */}
      <div className='flex flex-col gap-y-12'>
        <div>
          <h1 className='text-center text-2xl text-black-500 font-semibold'>Payment Pending</h1>
          <p className='text-center text-sm text-black-400'>Approve payment request on UPI app</p>
        </div>
        <div className='relative flex justify-between items-center px-4 bg-primary-50 py-2 rounded-2xl h-[64px]'>
          <img src={'/logo.svg'} alt='UPI Icon' />
          <img
            src={PaymentGif}
            alt='Moving GIF'
            className='absolute left-[25.51%] move-left-right rotate-90 z-2'
          />
          <img src={UPI} alt='UPI Icon' />
        </div>

        <div>
          <p className='text-center text-sm text-black-400 pb-4'>
            Follow these steps to complete your payment
          </p>
          <div>
            <div className='flex gap-x-2'>
              <img src={CircleRight} alt='Right SVG' />
              <div>
                <p className='text-sm font-semibold text-black-500'>Request sent to your UPI app</p>
                <p className='text-sm text-black-500'>
                  Axis Bank A/c ending {vpaDetails?.payeeType?.merchantInfo?.code}
                </p>
              </div>
            </div>
            <Separator className='h-6 w-[1px] mx-4 my-2' />
            <div className='flex items-center gap-x-2'>
              <img src={CircleUPI} alt='Right SVG' />
              <p className='text-sm text-black-500 font-semibold'>Open your UPI app</p>
            </div>
            <Separator className='h-6 w-[1px] mx-4 my-2' />
            <div className='flex gap-x-2'>
              <img src={CircleFabits} alt='Right SVG' />
              <div>
                <p className='text-sm text-black-500 font-semibold'>Approve the payment request</p>
                <p className='text-sm text-black-500'>No money will be deducted right now</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className='text-black-400 text-center text-sm'>
        Request expires in <b>{formatTime(timeLeft)}</b>
      </p>
      {/* <Button className='bg-error-50 text-error-500 hover:bg-error-100' onClick={onClose}>
        Close
      </Button> */}
    </div>
  )
}

export default PaymentRequest
