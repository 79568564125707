/* eslint-disable */
import useLocalStorage from '../../hooks/useLocalStorage'
import { useModal } from '../../hooks/use-modal-store'
import { Button } from '../ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'

import { post } from '../../http/restservice'
import env from '../../env/env'
import { ArrowDownUp } from 'lucide-react'
import sim_card_download from '../../assets/MyPlan/sim_card_download.svg'
import copy_all from '../../assets/MyPlan/copy_all.svg'
import useMediaQuery from '../../hooks/useMediaQuery'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '../ui/sheet'

const MandateDetails = () => {
  const { isOpen, onClose, type, data } = useModal()
  const [userInfo] = useLocalStorage('userData')
  const isModalOpen = isOpen && type === 'openMandateDetails'
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  const onSubmit = async (values) => {
    try {
      const response = await post(
        `${env.API_URL}planservice/api/upiMandate/checkVPA?upiId=${values.upiId}`,
        values,
      )

      if (response.response.code === 'OK' && response.response.status === 'SUCCESS') {
        setVpaDetails(response.response.data)
      }
    } catch (error) {
      console.log('🚀 ~ onSubmit ~ error:', error)
    }
  }

  return (
    <>
      {isDesktop ? (
        <Dialog open={isModalOpen} onOpenChange={() => onClose()}>
          <DialogContent className='sm:max-w-[746px] font-DMSans p-6' showclose={true}>
            <DialogHeader>
              <DialogTitle className='text-start font-semibold text-2xl text-[#2D2D2D]'>
                Mandate Details
              </DialogTitle>
              <DialogDescription className='text-start text-black-400 font-medium'>
                Created on{' '}
                {new Date(data?.fetchUserPlan?.createdTimestamp).toLocaleDateString('en-GB')}
              </DialogDescription>
            </DialogHeader>

            <div className='flex justify-between gap-4'>
              <div className='border py-2 px-4 rounded-[12px] flex-1 flex flex-col justify-end'>
                <span>₹ {data?.fetchUserPlan?.amountChosen}</span>
                <p className='text-black-400 font-medium'>Mandate Amount</p>
              </div>
              <div className='border py-2 px-4 rounded-[12px] flex-1 flex flex-col justify-end'>
                <span>{data?.userBankDetails?.accountHolderName}</span>
                <p className='font-semibold text-[18px]'>{data?.userBankDetails?.accountNo}</p>
                <p className='text-black-400 font-medium'>Linked bank account</p>
              </div>
              <div className='border py-2 px-4 rounded-[12px] flex-1 flex flex-col justify-end'>
                <div className='flex justify-between items-center'>
                  <p>....</p>
                  <span className='cursor-pointer'>
                    <img className='h-5 w-5' src={copy_all} alt='copy' />
                  </span>
                </div>
                <p className='text-black-400 font-medium'>Mandate reference</p>
              </div>
            </div>

            <div className='px-2 flex gap-x-2'>
              <Button
                className='p-0'
                variant='revoke'
                onClick={() => {
                  onClose(), data?.handleRevoke()
                }}
              >
                Revoke mandate
              </Button>

              <Button
                variant='simple'
                className='text-primary-500 no-underline'
                onClick={() => {
                  onClose(), data?.handleUpdate()
                }}
              >
                Update mandate
              </Button>
            </div>

            <div className='mt-12'>
              <div className='flex justify-between items-center'>
                <h2 className='text-2xl font-semibold'>Payment History</h2>
                <p className='text-black-400 font-medium'>Payment History</p>
                <Button className='bg-white hover:bg-white border rounded-[40px] text-primary-500 flex gap-x-2 py-[6px] pl-5 pr-3'>
                  Ascending time
                  <ArrowDownUp className='h-4 w-4' />
                </Button>
              </div>

              <div className='mt-4 max-h-[227px] overflow-y-scroll hide-scrollbar flex flex-col gap-y-4'>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button className='bg-white hover:bg-white border rounded-[8px] text-primary-500 flex gap-x-2 py-[8px] pl-6 pr-4'>
                    Invoice
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button className='bg-white hover:bg-white border rounded-[8px] text-primary-500 flex gap-x-2 py-[8px] pl-6 pr-4'>
                    Invoice
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button className='bg-white hover:bg-white border rounded-[8px] text-primary-500 flex gap-x-2 py-[8px] pl-6 pr-4'>
                    Invoice
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button className='bg-white hover:bg-white border rounded-[8px] text-primary-500 flex gap-x-2 py-[8px] pl-6 pr-4'>
                    Invoice
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button className='bg-white hover:bg-white border rounded-[8px] text-primary-500 flex gap-x-2 py-[8px] pl-6 pr-4'>
                    Invoice
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button className='bg-white hover:bg-white border rounded-[8px] text-primary-500 flex gap-x-2 py-[8px] pl-6 pr-4'>
                    Invoice
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button className='bg-white hover:bg-white border rounded-[8px] text-primary-500 flex gap-x-2 py-[8px] pl-6 pr-4'>
                    Invoice
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button className='bg-white hover:bg-white border rounded-[8px] text-primary-500 flex gap-x-2 py-[8px] pl-6 pr-4'>
                    Invoice
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button className='bg-white hover:bg-white border rounded-[8px] text-primary-500 flex gap-x-2 py-[8px] pl-6 pr-4'>
                    Invoice
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button className='bg-white hover:bg-white border rounded-[8px] text-primary-500 flex gap-x-2 py-[8px] pl-6 pr-4'>
                    Invoice
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button className='bg-white hover:bg-white border rounded-[8px] text-primary-500 flex gap-x-2 py-[8px] pl-6 pr-4'>
                    Invoice
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        <Sheet open={isModalOpen} onOpenChange={() => onClose()}>
          <SheetContent
            className={`font-DMSans p-6 rounded-t-[24px] 
              ${data?.displayNavbar ? 'bottom-[80px]' : 'bottom-0'}
              `}
            showClose={true}
            side='bottom'
          >
            <SheetHeader>
              <SheetTitle className='text-start font-semibold text-2xl text-[#2D2D2D]'>
                Mandate Details
              </SheetTitle>
              <SheetDescription className='text-start text-black-400 font-medium'>
                Created on{' '}
                {new Date(data?.fetchUserPlan?.createdTimestamp).toLocaleDateString('en-GB')}
              </SheetDescription>
            </SheetHeader>

            <div className='flex justify-between gap-4 overflow-x-scroll hide-scrollbar w-full mt-4'>
              <div className='border py-2 px-4 rounded-[12px] flex-1 flex flex-col justify-end h-20 min-w-[180px]'>
                <span>₹ {data?.fetchUserPlan?.amountChosen}</span>
                <p className='text-black-400 font-medium text-sm'>Mandate Amount</p>
              </div>
              <div className='border py-2 px-4 rounded-[12px] flex-1 flex flex-col justify-end h-20 min-w-[250px]'>
                <span className='text-sm'>{data?.userBankDetails?.accountHolderName}</span>
                <p className='font-semibold text-[18px]'>{data?.userBankDetails?.accountNo}</p>
                <p className='text-black-400 font-medium text-sm'>Linked bank account</p>
              </div>
              {/* <div className='border py-2 px-4 rounded-[12px] flex-1 flex flex-col justify-end h-20 min-w-[217px]'>
                <div className='flex justify-between items-center'>
                  <p>....</p>
                  <span className='cursor-pointer'>
                    <img className='h-5 w-5' src={copy_all} alt='copy' />
                  </span>
                </div>
                <p className='text-black-400 font-medium text-sm'>Mandate reference</p>
              </div> */}
            </div>

            <div className='px-2 flex gap-x-2'>
              <Button
                className='p-0'
                variant='revoke'
                onClick={() => {
                  onClose(), data?.handleRevoke()
                }}
              >
                Revoke mandate
              </Button>

              <Button
                variant='simple'
                className='text-primary-500 no-underline'
                onClick={() => {
                  onClose(), data?.handleUpdate()
                }}
              >
                Update mandate
              </Button>
            </div>

            <div className='mt-12'>
              <div className='flex flex-col items-start'>
                <h2 className='text-2xl font-semibold'>Payment History</h2>
                <Button
                  className='bg-white hover:bg-white rounded-[40px] text-primary-500 flex gap-x-2 p-0'
                  variant='link'
                >
                  Sort by Time - Ascending time
                  <ArrowDownUp className='h-4 w-4' />
                </Button>
              </div>

              <div className='mt-4 max-h-[227px] overflow-y-scroll hide-scrollbar flex flex-col gap-y-4'>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button className='bg-white hover:bg-white text-primary-500 p-0'>
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button className='bg-white hover:bg-white text-primary-500 p-0'>
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button className='bg-white hover:bg-white text-primary-500 p-0'>
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button
                    className='bg-white hover:bg-white text-primary-500 flex gap-x-2 p-0'
                    variant='ghost'
                  >
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button
                    className='bg-white hover:bg-white text-primary-500 flex gap-x-2 p-0'
                    variant='ghost'
                  >
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button
                    className='bg-white hover:bg-white text-primary-500 flex gap-x-2 p-0'
                    variant='ghost'
                  >
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button
                    className='bg-white hover:bg-white text-primary-500 flex gap-x-2 p-0'
                    variant='ghost'
                  >
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button
                    className='bg-white hover:bg-white text-primary-500 flex gap-x-2 p-0'
                    variant='ghost'
                  >
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
                <div className='flex justify-between items-center'>
                  <span>Jun 2024</span>
                  <p className='font-semibold'>Fabits Growth Plan</p>
                  <h3 className='font-semibold'>₹ 299</h3>
                  <Button
                    className='bg-white hover:bg-white text-primary-500 flex gap-x-2 p-0'
                    variant='ghost'
                  >
                    <span>
                      <img className='h-5 w-5' src={sim_card_download} alt='copy' />
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </SheetContent>
        </Sheet>
      )}
    </>
  )
}

export default MandateDetails
