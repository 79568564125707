export const toCurrency = (value) => {
  if (!value) {
    return value
  }
  // const result = parseFloat(value).toLocaleString('en-IN', {
  //   style: 'currency',
  //   currency: 'INR',
  // })

  const result = parseFloat(value).toLocaleString('en-IN', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  return result
}

export const toCurrencyNoDecimal = (value) => {
  if (!value) {
    return value
  }
  const result = parseFloat(value).toLocaleString('en-IN', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return result
}

export const indian__currency__rounder = (value) => {
  if (!value) {
    return value
  } else if (value >= 10000000) {
    return (value / 10000000).toFixed(2) + ' Cr'
  } else if (value >= 100000) {
    return (value / 100000).toFixed(2) + ' L'
  } else if (value >= 1000) {
    return (value / 1000).toFixed(2) + ' K'
  } else {
    const result = parseFloat(value).toLocaleString('en-IN', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
    return result
  }
}
