import Loader from './Loader/Loader'

const LoadingSkeleton = () => {
  return (
    <>
      <Loader />
    </>
  )
}

export default LoadingSkeleton
