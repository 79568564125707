const SPECIAL = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
]
const DECA = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet']

export const stringifyNumber = (n) => {
  if (n < 20) return SPECIAL[n]
  if (n % 10 === 0) return DECA[Math.floor(n / 10) - 2] + 'ieth'
  return DECA[Math.floor(n / 10) - 2] + 'y-' + SPECIAL[n % 10]
}

export const formatDateToStr = (d) => {
  const date = d.getDate()
  const month = d.getMonth() + 1
  const year = d.getFullYear()
  let finalDate = `${date < 10 ? '0' + date : date}/${month < 10 ? '0' + month : month}/${year}`
  return finalDate
}

export const toCamelCase = (s) => {
  return s.substring(0, 1).toUpperCase() + s.substring(1, s.length).toLowerCase()
}

export const convertDateFormat = (date) => {
  let currentDate = new Date(date.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'))
  let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(currentDate)
  let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(currentDate)
  let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(currentDate)
  return `${day} ${month} ${year}`
}

export const convertOrderDateFormat = (data) => {
  let currentDate = new Date(data)
  return currentDate.toLocaleString('en-GB', { hour12: false }).replace(',', '')
}

export const isWeekDay = (date) => {
  return new Date(date).getDay() % 6 != 0
}

export const isWorkingHours = (date) => {
  const startTime = 9 * 60 + 30 // 9:30AM
  const endTime = 15 * 60 + 30 // 3:30PM
  const currentTime = new Date(date)
  const time = currentTime.getHours() * 60 + currentTime.getMinutes()
  return time >= startTime && time < endTime
}

export const getReopenTiming = (marketStatus) => {
  // Check if market status is 'MAINTENANCE_WINDOW'
  if (marketStatus === 'MAINTENANCE_WINDOW') {
    // Get the current IST time
    const now = new Date()

    const istOffset = 5.5 * 60 * 60 * 1000 // IST is UTC+5:30

    const istTime = new Date(now.getTime() + istOffset)
    const hours = istTime.getUTCHours() // IST hours
    const minutes = istTime.getUTCMinutes() // IST minutes

    // Check if the time is between 3:30 and 4:40 IST
    if ((hours === 15 && minutes >= 30) || (hours === 16 && minutes <= 40)) {
      return '4:40 PM'
    }
    // Check if the time is between 8:30 and 8:50 IST
    else if (hours === 8 && minutes >= 30 && minutes <= 50) {
      return '8:50'
    }
  }

  // If the conditions are not met, return a default value (or null)
  return null
}
