import { Backdrop, CircularProgress, useTheme } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { loaderState } from '../store/Feedback'

const Loader = () => {
  const loader = useRecoilValue(loaderState)
  const theme = useTheme()

  return (
    <>
      <Backdrop
        sx={{ color: theme.palette.secondary.text, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  )
}

export default Loader
