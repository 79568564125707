import styles from './Loader.module.css'

const Loader = () => {
  return (
    <div className='h-full w-full flex justify-center items-center'>
      <svg viewBox='25 25 50 50' className={styles.spinner}>
        <circle r='20' cy='50' cx='50' className={styles.spinnerCircle}></circle>
      </svg>
    </div>
  )
}

export default Loader
