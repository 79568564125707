import { atom, selector } from 'recoil'
// import Dark1 from '../assets/DarkIcons/1.svg'
// import Dark2 from '../assets/DarkIcons/2.svg'
// import Dark3 from '../assets/DarkIcons/3.svg'
// import Dark4 from '../assets/DarkIcons/4.svg'
// import Dark5 from '../assets/DarkIcons/5.svg'
// import Dark6 from '../assets/DarkIcons/6.svg'
// import Dark7 from '../assets/DarkIcons/7.svg'
// import Dark8 from '../assets/DarkIcons/8.svg'
// import Dark9 from '../assets/DarkIcons/9.svg'
// import Dark10 from '../assets/DarkIcons/10.svg'
import Dark28 from '../assets/DarkIcons/28.svg'
import Dark29 from '../assets/DarkIcons/29.svg'
import Dark30 from '../assets/DarkIcons/30.svg'
import Dark47 from '../assets/DarkIcons/47.svg'
// import Education from '../assets/DarkIcons/education.svg'
// import Emergency from '../assets/DarkIcons/emergency.svg'
// import Marriage from '../assets/DarkIcons/marriage.svg'
// import Purchase from '../assets/DarkIcons/purhase.svg'
// import Retirement from '../assets/DarkIcons/retirement.svg'
// import Vacation from '../assets/DarkIcons/vacation.svg'

// import Light1 from '../assets/LightIcons/1.svg'
// import Light2 from '../assets/LightIcons/2.svg'
// import Light3 from '../assets/LightIcons/3.svg'
// import Light4 from '../assets/LightIcons/4.svg'
// import Light5 from '../assets/LightIcons/5.svg'
import Light6 from '../assets/LightIcons/6.svg'
// import Light7 from '../assets/LightIcons/7.svg'
import Light8 from '../assets/LightIcons/8.svg'
// import Light9 from '../assets/LightIcons/9.svg'
// import Light10 from '../assets/LightIcons/10.svg'
import Light28 from '../assets/LightIcons/28.svg'
import Light29 from '../assets/LightIcons/29.svg'
import Light30 from '../assets/LightIcons/30.svg'
import Light47 from '../assets/LightIcons/47.svg'
import Light49 from '../assets/LightIcons/49.svg'
// import EmergencyLight from '../assets/LightIcons/emergency.svg'
// import EducationLight from '../assets/LightIcons/education.svg'
// import MarriageLight from '../assets/LightIcons/marriage.svg'
// import PurchaseLight from '../assets/LightIcons/purhase.svg'
// import RetirementLight from '../assets/LightIcons/retirement.svg'
// import VacationLight from '../assets/LightIcons/vacation.svg'

// import Property from '../assets/DarkIcons/property.svg'
// import HomePurchase from '../assets/DarkIcons/homepurchase.svg'
// import Passion from '../assets/DarkIcons/passionfund.svg'
// import Car from '../assets/DarkIcons/car.svg'
// import Jewellery from '../assets/DarkIcons/jewellery.svg'

// import PropertyLight from '../assets/LightIcons/property.svg'
// import HomePurchaseLight from '../assets/LightIcons/homepurchase.svg'

// import PassionLight from '../assets/LightIcons/passionfund.svg'
// import CarLight from '../assets/LightIcons/car.svg'
// import JewelleryLight from '../assets/LightIcons/jewellery.svg'

import Purchase from '../assets/images/Big Purchase.svg'
import Education from '../assets/images/Higher Education.svg'
import Vacation from '../assets/images/Retreat.svg'
import Retirement from '../assets/images/Parents_Extended Family Post-Retirement Support.svg'
import Emergency from '../assets/images/Gadget.svg'
import Marriage from '../assets/images/Jewellery.svg'

import Property from '../assets/images/Property.svg'
import HomePurchase from '../assets/images/House.svg'
import Passion from '../assets/images/Passion.svg'
import Car from '../assets/images/Vehicle.svg'
import Jewellery from '../assets/images/Jewellery.svg'
import Moderate from '../assets/images/Moderate-Risk Fund.svg'
import PurchaseFund from '../assets/images/Purchase Fund.svg'
import Signature from '../assets/images/Signature Portfolio.svg'
import Momentum from '../assets/images/Momentum Stocks Portfolio.svg'
import Ethical from '../assets/images/Ethical & Broad Index.svg'
import Safety from '../assets/images/Safety-first Fund.svg'
import Liquidity from '../assets/images/Liquidity & Emergency Fund.svg'
import Wedding from '../assets/images/Wedding Fund.svg'
import Bliss from '../assets/images/Bliss Fund.svg'
import EducationFund from '../assets/images/Education Fund.svg'

const IconsState = atom({
  key: 'IconsState',
  default: {
    dark1: Momentum,
    light1: Momentum,
    dark2: Signature,
    light2: Signature,
    dark3: PurchaseFund,
    light3: PurchaseFund,
    dark4: Bliss,
    light4: Bliss,
    dark5: Moderate,
    light5: Moderate,
    dark6: EducationFund,
    light6: Light6,
    dark7: Liquidity,
    light7: Liquidity,
    dark8: Wedding,
    light8: Light8,
    dark9: Safety,
    light9: Safety,
    dark10: Ethical,
    light10: Ethical,
    dark28: Dark28,
    light28: Light28,
    dark29: Dark29,
    light29: Light29,
    dark30: Dark30,
    light30: Light30,
    dark47: Dark47,
    light47: Light47,
    dark14: Education,
    dark15: Education,
    dark16: Education,
    dark17: Education,
    dark18: Education,
    light14: Education,
    light15: Education,
    light16: Education,
    light17: Education,
    light18: Education,
    dark31: Vacation,
    light31: Vacation,
    dark12: Retirement,
    light12: Retirement,
    dark13: Retirement,
    light13: Retirement,
    dark19: Purchase,
    dark20: Purchase,
    dark21: Purchase,
    dark22: Purchase,
    dark23: Purchase,
    light19: Purchase,
    light20: Purchase,
    light21: Purchase,
    light22: Purchase,
    light23: Purchase,
    dark24: Marriage,
    dark25: Marriage,
    dark26: Marriage,
    dark27: Marriage,
    light24: Marriage,
    light25: Marriage,
    light26: Marriage,
    light27: Marriage,
    dark32: Emergency,
    light32: Emergency,
    dark34: HomePurchase,
    light34: HomePurchase,
    dark40: Passion,
    light40: Passion,
    dark42: Car,
    light42: Car,
    dark43: Car,
    light49: Light49,
    light43: Car,
    dark38: Jewellery,
    light38: Jewellery,
    dark36: Property,
    light36: Property,
    darkHOME: HomePurchase,
    lightHOME: HomePurchase,
    darkHOLIDAY: Vacation,
    lightHOLIDAY: Vacation,
    darkPASSION: Passion,
    lightPASSION: Passion,
    darkVEHICLE: Car,
    lightVEHICLE: Car,
    darkMARRIAGE: Marriage,
    lightMARRIAGE: Marriage,
    darkPROPERTY: Property,
    lightPROPERTY: Property,
    darkPURCHASE: Purchase,
    lightPURCHASE: Purchase,
    darkEMERGENCY: Emergency,
    lightEMERGENCY: Emergency,
    darkJEWELLERY: Jewellery,
    lightJEWELLERY: Jewellery,
    darkRETIREMENT: Retirement,
    lightRETIREMENT: Retirement,
    darkCHILDEDUCATION: Education,
    lightCHILDEDUCATION: Education,
    darkPOSTRETIREMENTVACATION: Retirement,
    lightPOSTRETIREMENTVACATION: Retirement,
  },
})

const IconsSelector = selector({
  key: 'IconsSelector',
  get: ({ get }) => get(IconsState),
})

export default IconsSelector
