import * as React from 'react'
// import Autoplay from 'embla-carousel-autoplay'

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../ui/carousel'
import homeCard1 from '../../assets/Auth/Card.png'
import homeCard2 from '../../assets/Auth/Card 1.png'

export const AuthCarouselDesktop = () => {
  //   const plugin = React.useRef(Autoplay({ delay: 2000, stopOnInteraction: true }))

  const data = [
    {
      id: 1,
      image: homeCard1,
      title: 'Build wealth for financial milestones of your life',
      description:
        'From your next vacation to your retirement - build corpus for all your financial obligations',
    },
    {
      id: 2,
      image: homeCard2,
      title: 'Plan, invest, & track your money in one place',
      description:
        'No more shuffling between Excel sheets & your demat account; plan & invest in one place',
    },
  ]

  return (
    <div className='bg-primary-900 h-full w-full rounded-3xl flex justify-center items-start'>
      <Carousel
        //   plugins={[plugin.current]}
        className='relative flex flex-col justify-between items-center mx-16 mt-[72px]'
        //   onMouseEnter={plugin.current.stop}
        //   onMouseLeave={plugin.current.reset}
      >
        <CarouselContent className=''>
          {data.map((item, index) => (
            <CarouselItem key={index}>
              <div className='flex flex-col gap-y-12 py-6 px-[7rem] w-full overflow-y-hidden'>
                <div className='flex justify-center'>
                  <img
                    src={item.image}
                    className='w-full 2xl:max-w-[340px] lg:max-w-[296px] shadow-[0_12px_32px_0_rgba(17,25,44,0.08)]'
                  />
                </div>
                <div className='flex flex-col justify-center items-center gap-y-2 text-center'>
                  <h1 className='text-[32px] kerning-[42px] -tracking-[0.96px] text-white font-semibold font-DMSans'>
                    {item.title}
                  </h1>
                  <p className='text-primary-200 text-[16px] kerning-[24px]'>{item.description}</p>
                </div>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <div className='flex flex-row absolute bottom-[-10%] left-[40%] right-[50%]'>
          <CarouselPrevious className='bg-primary-50 hover:bg-primary-100 shadow-[0_4px_8px_0_rgba(255,255,255,0.16)]' />
          <CarouselNext className='bg-primary-50 hover:bg-primary-100 shadow-[0_4px_8px_0_rgba(255,255,255,0.16)]' />
        </div>
      </Carousel>
    </div>
  )
}

export const AuthCarouselMobile = () => {
  //   const plugin = React.useRef(Autoplay({ delay: 2000, stopOnInteraction: true }))

  const data = [
    {
      id: 1,
      image: homeCard1,
      title: 'Build wealth for financial milestones of your life',
      description:
        'From your next vacation to your retirement - build corpus for all your financial obligations',
    },
    {
      id: 2,
      image: homeCard2,
      title: 'Plan, invest, & track - all your money in one place',
      description:
        'No more shuffling between Excel sheets & your demat account; plan & invest in one place',
    },
  ]

  return (
    <div className='bg-primary-900 h-full w-full py-[72px]'>
      <Carousel
        //   plugins={[plugin.current]}
        className='relative'
        //   onMouseEnter={plugin.current.stop}
        //   onMouseLeave={plugin.current.reset}
      >
        <CarouselContent className='w-full'>
          {data.map((item, index) => (
            <CarouselItem key={index}>
              <div className='flex flex-col gap-y-12 px-6 mt-12 h-full overflow-y-hidden'>
                <div className='flex justify-center'>
                  <img
                    src={item.image}
                    className='w-full max-w-[420px] lg:max-w-[312px] 2xl:max-w-[400px] shadow-[0_12px_32px_0_rgba(17,25,44,0.08)]'
                  />
                </div>
                <div className='flex flex-col justify-center items-center gap-y-2 text-center mb-[120px]'>
                  <h1 className='text-[24px] kerning-[32px] -tracking-[0.96px] text-white font-semibold font-DMSans'>
                    {item.title}
                  </h1>
                  <p className='text-primary-200 text-[14px] kerning-[20px]'>{item.description}</p>
                </div>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <div className='absolute bottom-[-10%] left-[40%] right-[50%]'>
          <CarouselPrevious className='bg-primary-700 hover:bg-primary-700' />
          <CarouselNext className='bg-primary-700 hover:bg-primary-700' />
        </div>
      </Carousel>
    </div>
  )
}
