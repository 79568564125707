import { atom, selector } from 'recoil'
// import Emergency from '../assets/DarkIcons/emergency.svg'
// import Education from '../assets/DarkIcons/education.svg'
// import Marriage from '../assets/DarkIcons/marriage.svg'
// import Purchase from '../assets/DarkIcons/purhase.svg'
// import Retirement from '../assets/DarkIcons/retirement.svg'
// import Vacation from '../assets/DarkIcons/vacation.svg'

// import EmergencyLight from '../assets/LightIcons/emergency.svg'
// import EducationLight from '../assets/LightIcons/education.svg'
// import MarriageLight from '../assets/LightIcons/marriage.svg'
// import PurchaseLight from '../assets/LightIcons/purhase.svg'
// import RetirementLight from '../assets/LightIcons/retirement.svg'
// import VacationLight from '../assets/LightIcons/vacation.svg'

import BigPurchase from '../assets/images/Big Purchase.svg'
import Education from '../assets/images/Child_s Education.svg'
import Vacation from '../assets/images/Retreat.svg'
import Retirement from '../assets/images/Parents_Extended Family Post-Retirement Support.svg'
import Emergency from '../assets/images/Gadget.svg'
import Marriage from '../assets/images/Jewellery.svg'

const PlansState = atom({
  key: 'PlansState',
  default: [
    {
      planName: 'Retirement',
      description: 'Retiring in comfort means all your needs are taken care of.',
      redirectPath: '/dashboard/retirementplan',
      img: Retirement,
      lightImg: Retirement,
      type: 'RETIREMENT',
    },
    {
      planName: 'Education',
      description: 'Education is expensive, don’t compromise when it comes to learning.',
      redirectPath: '/dashboard/educationplan',
      img: Education,
      lightImg: Education,
      type: 'CHILDEDUCATION',
    },
    {
      planName: 'Marriage',
      description:
        'Weddings are expensive, no matter how small the event. Start investing towards the big day.',
      redirectPath: '/dashboard/weddingplan',
      img: Marriage,
      lightImg: Marriage,
      type: 'MARRIAGE',
    },
    {
      planName: 'Purchase',
      description: 'Putting away a purchase because it’s expensive? Invest towards it instead!',
      redirectPath: '/dashboard/purchaseplan',
      img: BigPurchase,
      lightImg: BigPurchase,
      type: 'PURCHASE',
    },
    {
      planName: 'Holidays',
      description: 'You deserve a holiday, and one that you don’t have to use up your savings for!',
      redirectPath: '/dashboard/vacationplan',
      img: Vacation,
      lightImg: Vacation,
      type: 'HOLIDAY',
    },
    {
      planName: 'Emergency',
      description:
        'Handling emergencies can’t be planned, but the financial need to navigate emergencies can be planned',
      redirectPath: '/dashboard/emergencyplan',
      img: Emergency,
      lightImg: Emergency,
      type: 'EMERGENCY',
    },
  ],
})

const PlansSelector = selector({
  key: 'PlansSelector',
  get: ({ get }) => get(PlansState),
})

export default PlansSelector
