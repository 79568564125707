import { atom, selector, selectorFamily } from 'recoil'
import { get as getData } from '../http/restservice'
// import { isDematUser } from '../utils/AuthUtil'

const InvestmentBoxes = selector({
  key: 'InvestmentBoxes',
  get: async () => {
    const response = await getData('/planservice/api/plan/investmentboxes')
    if (response.isError) {
      return []
    }
    return response
  },
})

const InvestmentBoxDetails = selectorFamily({
  key: 'InvestmentBoxeDetails',
  get: (basketId) => async () => {
    // if (isDematUser()) {
    const response = await getData('/planservice/api/plan/investmentboxes/' + basketId)
    if (response.isError) {
      return []
    }
    return response
    // } else {
    //   return []
    // }
  },
})

const dayPickerValues = atom({
  key: 'dayPicker',
  default: [3, 5, 10, 15, 22, 25],
})

const tabsList = atom({
  key: 'tabsList',
  default: [
    {
      label: (
        <p className='font-DMSans text-[14px] kerning-[20px] -tracking-[0.56px] md:text-[16px] md:kerning-[24px] md:-tracking-[0.64px] transition-all whitespace-nowrap'>
          One Time Investment
        </p>
      ),
      value: 'onetimeinvest',
    },
    {
      label: (
        <p className='font-DMSans text-[14px] kerning-[20px] -tracking-[0.56px] transition-all whitespace-nowrap'>
          Monthly SIP
        </p>
      ),
      value: 'monthlysip',
    },
  ],
})

export { InvestmentBoxes, dayPickerValues, tabsList, InvestmentBoxDetails }
