import { Alert, AlertTitle, Snackbar } from '@mui/material'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { alertModalState } from '../store/Feedback'

const Alerts = () => {
  const [modelState, setModelState] = useRecoilState(alertModalState)
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
    setModelState(null)
  }

  useEffect(() => {
    if (modelState && (modelState.message || '' !== modelState.message)) {
      setOpen(true)
    }
  }, [modelState])

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <div>
          {modelState && (
            <Alert onClose={handleClose} severity={modelState.severity} sx={{ width: '100%' }}>
              {modelState.title ? <AlertTitle>{modelState.title}</AlertTitle> : null}
              {modelState.message}
            </Alert>
          )}
        </div>
      </Snackbar>
    </>
  )
}

export default Alerts
