import React, { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Nav from '../Components/Nav'

const PostLoginContainer = () => {
  const location = useLocation()

  const [openNav, setOpenNav] = useState(false)

  const handleOpenNav = () => {
    setOpenNav(!openNav)
  }

  return (
    <>
      <div className='flex min-h-screen'>
        {!location.pathname.includes('/tax-filing') && (
          <div className='border-r hidden lg:block px-6 py-12 lg:fixed lg:top-0 lg:left-0 lg:h-full'>
            <Nav openNav={openNav} handleOpenNav={handleOpenNav} />
          </div>
        )}

        <div className='lg:p-12 w-full mb-16 lg:ml-[275px]'>
          {!location.pathname.includes('/tax-filing') && <Header handleOpenNav={handleOpenNav} />}
          <Outlet />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default PostLoginContainer
