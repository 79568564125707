import React from 'react'
import { Box, DialogTitle, Dialog, IconButton, DialogContent, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'

const CommonDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
}))

function CommonDialogTitle(props) {
  const { children, onClose, hideClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, px: 3, pt: 2, pb: 0, fontWeight: 500 }} {...other}>
      {children}
      {!hideClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose ? onClose : () => {}}
          sx={{
            display: 'none',
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const CommonPopup = ({
  name,
  title,
  children,
  handleClose,
  openModal,
  width = 500,
  alignBottom = false,
  hideClose = false,
  background,
  shadow,
  border,
}) => {
  const theme = useTheme()

  return (
    <>
      <CommonDialog
        aria-labelledby={`${name}-dialog-title`}
        open={openModal}
        onClose={handleClose}
        sx={{
          // '& .MuiIconButton-root': {
          //   display: !alignBottom && 'none',
          // },

          '& .MuiDialog-container': {
            ...(alignBottom ? { alignItems: 'flex-end' } : {}),
            backgroundColor: `${background || ''}`,
            '& .MuiPaper-root:not(.MuiAlert-root)': {
              border: border,
              boxShadow: shadow,
              width: '100%',

              ...(!alignBottom
                ? {
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.text,
                    borderRadius: '20px',
                  }
                : {
                    background: 'transparent',
                    boxShadow: 'none',
                  }),
              maxWidth: `${width}px`,
              ...(alignBottom
                ? { margin: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }
                : {}),
            },
          },
        }}
      >
        {alignBottom && (
          <Box sx={{ textAlign: 'center' }}>
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                background: 'rgba(45, 45, 45, 0.8)',
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        {title && (
          <CommonDialogTitle
            id={`${name}-attention-dialog-title`}
            onClose={handleClose}
            alignBottom={alignBottom}
            hideClose={hideClose}
            sx={{
              background: '#fff',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
            }}
          >
            {title}
          </CommonDialogTitle>
        )}
        <DialogContent
          sx={{
            ...(alignBottom
              ? {
                  background: theme.palette.secondary.main,
                  color: theme.palette.secondary.text,
                }
              : {}),
            ...(!title && alignBottom
              ? {
                  borderTopLeftRadius: '20px',
                  borderTopRightRadius: '20px',
                }
              : {}),
          }}
        >
          {children}
        </DialogContent>
      </CommonDialog>
    </>
  )
}

export default CommonPopup
