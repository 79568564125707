import { atom } from 'recoil'

const messageModalState = atom({
  key: 'messageModalState',
  default: null,
})

const loaderState = atom({
  key: 'loaderState',
  default: false,
})

const alertModalState = atom({
  key: 'alertModalState',
  default: false,
})

export { messageModalState, loaderState, alertModalState }
